import React, { useEffect, useState, useRef, useContext, useCallback } from "react";
import { PanelContext } from "../../contexts/PanelContext";
import SVGAsset from "../SVGAsset/SVGAsset";

import "./SidePanel.css";

export default function SidePanel() {
  const panelContext = useContext(PanelContext);
  const { open: initialOpen, data } = panelContext;
  const [open, setOpen] = useState(initialOpen);
  const { ref: originRef, children } = data;

  const thisRef = useRef(null); // Panel element
  const openRef = useRef(null); // Reference to 'open' for callback deps

  useEffect(() => {
    openRef.current = open;
  });

  function openPanel() {
    if (thisRef) {
      thisRef.current.classList.remove("slide-out");
      thisRef.current.classList.add("slide-in");
    }
    setOpen(true);
  }

  function closePanel() {
    if (thisRef) {
      thisRef.current.classList.add("slide-out");
      thisRef.current.classList.remove("slide-in");
    }
    setOpen(false);
  }

  const outerClickHandler = useCallback(
    (e) => {
      // Prevents firing when panel is not open
      if (!openRef.current) return;

      const originRefId = (originRef && originRef.id) || undefined;
      let parent = e.target;
      const isParentModal = e.path.some((e) => e.id === "modal-dialog");

      if (isParentModal) return;

      do {
        if (parent.id === originRefId || parent === thisRef.current) {
          break;
        }
      } while ((parent = parent.parentElement) !== null);

      const isTargetComponent = parent !== null;
      if (!isTargetComponent) {
        closePanel();
      }
    },
    [originRef, openRef]
  );

  useEffect(() => {
    document.body.addEventListener("click", outerClickHandler);

    const cleanup = () => {
      document.body.removeEventListener("click", outerClickHandler);
    };

    if (initialOpen) {
      openPanel();
    } else {
      closePanel();
    }

    return cleanup;
  }, [initialOpen, outerClickHandler]);

  const initialClass = open ? "slide-in" : "slide-out";

  return (
    <>
      <div className={`side-panel ${initialClass}`} ref={thisRef}>
        <section className="main">{children}</section>
        <section className="close">
          <SVGAsset className="close-x clickable" name="close" onClick={() => closePanel()} />
        </section>
      </div>
    </>
  );
}
