import React from "react";
import PropTypes from "prop-types";
import SVGAsset from "../SVGAsset/SVGAsset";

import "./StatusLabel.css";

const labelType = {
  Active: "Active",
  RequiresAction: "Requires_action",
  AwaitingAction: "Awaiting_action",
  Completed: "Completed",
  Expired: "Expired",
  AwaitingProcessing: "Awaiting_processing",
  You: "You",
  AwaitingFinalAmount: "Awaiting_final_amount",
};

statusLabel.labelType = labelType;

function statusLabel(props) {
  return (
    <div className={"label-area " + props.labelType} id={props.id}>
      {props.labelType === labelType.Completed && <SVGAsset className="label-area-child" name="checkcircle"></SVGAsset>}
      <label className="label-area-child">{props.labelText}</label>
    </div>
  );
}

statusLabel.propTypes = {
  id: PropTypes.string.isRequired,
  labelType: PropTypes.oneOf([
    "Active",
    "Requires_action",
    "Awaiting_action",
    "Completed",
    "Expired",
    "You",
    "Awaiting_processing",
    "Awaiting_final_amount",
  ]),
};

export default statusLabel;
