import { useState } from "react";

export const getToken = () => {
  const tokenString = sessionStorage.getItem("token");
  return JSON.parse(tokenString);
};

export default function useToken() {
  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    if (userToken === null) sessionStorage.removeItem("token");
    else sessionStorage.setItem("token", JSON.stringify(userToken));
    setToken(userToken);
  };

  return {
    token,
    setToken: saveToken,
  };
}
