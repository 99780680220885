import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import SVGAsset from "../../components/SVGAsset/SVGAsset";

import "./Dropdown2.css";

function getSelected(selected, options) {
  const fixed = options.find((option) => option.type === "fixed-value");
  return fixed || options.find((option) => option.value === selected);
}

// controlls which way the options menu opens
const directions = {
  Up: "Up",
  Down: "Down",
};

Dropdown2.direction = directions;

export default function Dropdown2(props) {
  const { options = [], onChange, selected } = props;

  const direction = (props.direction || Dropdown2.direction.Down).toLowerCase();

  const selectedInitial = getSelected(selected, options);
  const isFixed = selectedInitial && selectedInitial.type === "fixed-value";
  const [open, setOpen] = useState(false);
  const [wasOpen, setWasOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const ref = useRef(null);

  useEffect(() => {
    setSelectedValue(selectedInitial);
  }, [selectedInitial, options]);

  function optionHandler(item, e) {
    if (!isFixed) setSelectedValue(item);
    onChange(item, e);
  }

  function getContentByType(type, value) {
    switch (type) {
      case "separator":
        return <div className="separator"></div>;
      case "heading":
        return <div className="heading">{value}</div>;
      default:
        return "<Invalid dropdown type>";
    }
  }

  const classes = open ? "slide-in" : "slide-out";

  const blurHandler = (e) => {
    if (e.currentTarget.contains(e.relatedTarget)) setOpen(true);
    else setOpen(false);
  };

  return (
    <div className="dropdown2" {...props}>
      <label>{props.label}</label>
      <div
        className="dropdown-field unselectable"
        onClick={() => {
          if (!open && !wasOpen) {
            ref.current.focus();
            setOpen(true);
          } else setWasOpen(false);
        }}
        onMouseDown={() => {
          setWasOpen(open);
        }}
      >
        <div className="value">{selectedValue && selectedValue.content}</div>
        <SVGAsset name="chevron-down" className="arrow-down-svg" />

        <div
          className={`options ${classes} ${direction}`}
          ref={ref}
          tabIndex="-1"
          onBlur={(e) => {
            blurHandler(e);
          }}
        >
          <div className="blinds">
            {options.map((option, i) => {
              if (option.type === "fixed-value") return null;
              if (option.value && !option.type) {
                const disabled = option.disabled ? "disabled" : "";
                return (
                  <div
                    className={`option value ${disabled}`}
                    onClick={(e) => {
                      if (!option.disabled && !option.keepOpen) {
                        optionHandler(option, e);
                        setOpen(false);
                      }
                    }}
                    key={"dropdown2-option-" + i}
                  >
                    {option.content}
                  </div>
                );
              } else {
                const type = option.type ? option.type : "";
                const custom = !!option.content ? "custom" : "";
                return (
                  <div className={`option ${type} ${custom}`} key={"dropdown2-option-" + i}>
                    {option.content ? option.content : getContentByType(option.type, option.value)}
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

Dropdown2.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  direction: PropTypes.oneOf([...Object.keys(directions)]),
  label: PropTypes.string,
};
