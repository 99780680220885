import React, { useState, useEffect, useContext } from "react";
import { createUser } from "../../services/Login";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { ErrorDialogContext } from "../../contexts/ErrorDialogContext";
import { validateFieldsHelper } from "../../utils/ValidationHelper";
import { registerUserSchema } from "./RegisterUserValidationSchema";
import TextField from "../../components/TextField/TextField";
import BlueButton from "../../components/BlueButton/BlueButton";
import Grid from "../../components/Grid/Grid";
import SVGAsset from "../../components/SVGAsset/SVGAsset";
import Spinner from "../../components/Spinner/Spinner";

import "./RegisterNewUser.css";
import PhoneField from "../../components/PhoneInput/PhoneInput";

export default function RegisterNewUser(props) {
  const { setRedirection, setUserContext, userContext } = props;
  const errorDialogContext = useContext(ErrorDialogContext);
  const { showErrorDialog, setErrorDialogOptions } = errorDialogContext;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState(userContext.email || "");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    sessionStorage.removeItem("redirection");
  });

  async function asyncCreateUser(firstName, lastName, email, phone) {
    return await createUser(firstName, lastName, email, phone);
  }

  const createUserHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const inputChecker = validateFieldsHelper(registerUserSchema(t), {
        firstname: firstName,
        lastname: lastName,
        email: email,
        phone: phone,
      });
      setValidationErrors(inputChecker);
      if (Object.keys(inputChecker).length === 0) {
        const response = await asyncCreateUser(firstName, lastName, email, phone);
        const id = response.data.id;
        const token = response.data.emailVerificationToken;

        if (id > 0) {
          setUserContext({ token: token });
          setRedirection("NEW_USER_COMPLETED");
        }
      }
    } catch (error) {
      setErrorDialogOptions({ message: "Something went wrong", error });
      showErrorDialog(true);
    }
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>Reviver - {t("registerCompany.registerUser")}</title>
        <meta name="description" content="Reviver - Register user" />
      </Helmet>
      <Spinner loading={loading} />
      <Grid className="container-register-user-image-area">
        <Grid.Col span={6}>
          <SVGAsset className="logo" name="fished-logo" />
          <p className="img-phrase">{t("registerCompany.registerSlogan")}</p>
        </Grid.Col>
        <Grid.Col span={6} className="container-register-user registeruser">
          <div className="container-register-user-area">
            <div className="flex col">
              <SVGAsset name="fished-logo" className="mobile-logo" caption="Fished Logo" />
              <h1>{t("registerCompany.registerUserHeader")}</h1>
              <p className="main-phrase">{t("registerCompany.registerUserText")}</p>
            </div>

            <form onSubmit={createUserHandler}>
              <p className="gray-phrase">{t("registerCompany.registerUserText2")}</p>
              <Grid>
                <Grid.Row>
                  <Grid.Col span={6}>
                    <TextField
                      id="txt-regUser-firstName"
                      label={t("registerCompany.registerUserFirstname")}
                      onChange={(e) => setFirstName(e.target.value)}
                      errormessage={validationErrors.firstname}
                    />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col span={6}>
                    <TextField
                      id="txt-regUser-lastName"
                      label={t("registerCompany.registerUserLastName")}
                      onChange={(e) => setLastName(e.target.value)}
                      errormessage={validationErrors.lastname}
                    />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col span={6}>
                    <TextField
                      id="txt-regUser-email"
                      label={t("registerCompany.registerUserEmail")}
                      defaultValue={userContext.email || ""}
                      onChange={(e) => setEmail(e.target.value)}
                      errormessage={validationErrors.email}
                    />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col span={6}>
                    <PhoneField
                      id="txt-regUser-phone"
                      placeholder={t("common.phone")}
                      onChange={(e) => setPhone(e)}
                      errormessage={validationErrors.phone}
                    />
                  </Grid.Col>
                </Grid.Row>
              </Grid>
              <BlueButton
                id="btn-regUser-create"
                className="mtop30"
                text={t("registerCompany.registerUserSubmit")}
                type="submit"
              />
            </form>
            <p className="main-phrase">
              {t("registerCompany.registerUserRegistered")} <a href="/login">{t("common.logIn")}</a>
            </p>
          </div>
        </Grid.Col>
      </Grid>
    </>
  );
}
