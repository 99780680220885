import { axios, APIBASE_REVIVERUSERS } from "./ApiConfig";

export const getCompanyById = async (companyId) => axios.get(`${APIBASE_REVIVERUSERS}api/company/get/${companyId}`);

export const getAllCompanies = async () => axios.get(`${APIBASE_REVIVERUSERS}api/company/get/all`);

export const getCompanyTeam = async (companyId) => axios.get(`${APIBASE_REVIVERUSERS}api/company/${companyId}/team`);

export const getTransportCompanies = async () => axios.get(`${APIBASE_REVIVERUSERS}api/company/get/all/transport`);

export const getCompanyByIdPublic = async (companyId) =>
  axios.get(`${APIBASE_REVIVERUSERS}api/company/get/${companyId}/public`);

export const getCompanyCreditById = async (companyId) =>
  axios.get(`${APIBASE_REVIVERUSERS}api/company/${companyId}/credit`);

export const postSaveCompanyNetwork = async (companyId, companies) =>
  axios.post(`${APIBASE_REVIVERUSERS}api/company/${companyId}/saveCompanyNetwork`, companies);

export const getCompanyNetwork = async (companyId) =>
  axios.get(`${APIBASE_REVIVERUSERS}api/company/${companyId}/getCompanyNetwork`);

export const getCompanyNetworkFull = async (companyId) =>
  axios.get(`${APIBASE_REVIVERUSERS}api/company/get/${companyId}/allForWizard`);

export const getCompanyOrderTemplates = async (companyId) =>
  axios.get(`${APIBASE_REVIVERUSERS}api/company/${companyId}/orderTemplates`);

export const postSaveOrderTemplate = async (template) =>
  axios.post(`${APIBASE_REVIVERUSERS}api/company/saveOrderTemplate`, template);

export const deleteOrderTemplate = async (templateId) => {
  axios.delete(`${APIBASE_REVIVERUSERS}api/company/${templateId}/deleteOrderTemplate`);
};
