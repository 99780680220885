import React, { useEffect, useState } from "react";
import BlueButton from "../../components/BlueButton/BlueButton";
import Grid from "../../components/Grid/Grid";
import SVGAsset from "../../components/SVGAsset/SVGAsset";
import ModalDialog from "../../containers/ModalDialog/ModalDialog";
import { resendEmailVerification } from "../../services/Login";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import "./RegisterNewUser.css";

export default function RegisterNewUserCompleted(props) {
  const { userContext } = props;
  const { t } = useTranslation();
  const [dialogOptions, setDialogOptions] = useState({});

  useEffect(() => {
    sessionStorage.removeItem("redirection");
  });

  async function resendVerificationEmail() {
    try {
      await resendEmailVerification(userContext.token);
      setDialogOptions({
        open: true,
        message: t("common.emailSent"),
        onClose: () => setDialogOptions({ open: false }),
      });
    } catch (e) {
      setDialogOptions({
        open: true,
        message: t("common.genericErrorRetryLater"),
        onClose: () => setDialogOptions({ open: false }),
      });
    }
  }

  return (
    <>
      <Helmet>
        <title>Reviver - {t("common.verifyEmail")}</title>
        <meta name="description" content="Reviver - Verify email" />
      </Helmet>
      <ModalDialog open={dialogOptions.open || false} onClose={dialogOptions.onClose || null} className="dialog">
        <p>{dialogOptions.message}</p>
        <div className="flex center mtop10 mbottom10">
          <BlueButton id="btn-close-dialog" text={t("common.ok")} onClick={dialogOptions.onClose} />
        </div>
      </ModalDialog>
      <Grid className="container-register-user-image-area">
        <Grid.Row>
          <Grid.Col span={4}>
            <SVGAsset className="logo" name="fished-logo" />
            <p className="img-phrase">{t("registerCompany.registerSlogan")}</p>
          </Grid.Col>

          <Grid.Col className="container-register-user emailverify at7" span={6}>
            <div className="container-register-user-area center">
              <SVGAsset name="email-image" />
              <div className="flex col">
                <h1>{t("registerCompany.registerEmailSent")}</h1>
                <p className="gray-phrase">{t("registerCompany.registerEmailSentDescription")}</p>
                <BlueButton
                  id="btn-verifyemail-resend"
                  buttonType="Primary"
                  text={t("common.resendVerification")}
                  onClick={resendVerificationEmail}
                  buttontype={BlueButton.buttontype.Primary}
                  buttonsize={BlueButton.buttonsize.Large}
                />
              </div>
            </div>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </>
  );
}
