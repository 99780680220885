import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

import "./Dropdown.css";

const customStyles = {
  control: (base) => ({
    ...base,
    borderRadius: "5px",
    border: "2px solid var(--SFPlightBorderGray)",
    minHeight: "53px",
    ":hover": {
      borderColor: "#0000FF",
    },
    ":focus-within": {
      borderColor: "#0000FF",
      boxShadow: "none",
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "black",
  }),
};

const menuPlacement = {
  Bottom: "bottom",
  Top: "top",
};
dropdown.menuPlacement = menuPlacement;

function dropdown(props) {
  // overrideStyle lets you override style from outside component if necessary
  const { overrideStyle, label, options, direction, placeholder, errormessage, dropDownClassNames } = props;

  const filterColors = (inputValue) => {
    return props.options.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterColors(inputValue));
      }, 1000);
    });

  return (
    <div className={`dropdown-area ${dropDownClassNames}`}>
      <label>{label}</label>
      <AsyncSelect
        key={JSON.stringify(options)}
        styles={overrideStyle ? overrideStyle : customStyles}
        placeholder={placeholder ? placeholder : false}
        noOptionsMessage={() => "..."}
        cacheOptions
        defaultOptions
        loadOptions={promiseOptions}
        id={props.id ? props.id + "-dropdown" : null}
        menuPlacement={`${direction ? direction : dropdown.menuPlacement.Bottom}`}
        {...props}
      />
      <ErrorMessage errormessage={errormessage} />
    </div>
  );
}

dropdown.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default dropdown;
