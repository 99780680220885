import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

import "react-phone-input-2/lib/bootstrap.css";
import "./PhoneInput.css";

export default function PhoneField(props) {
  const { t } = useTranslation();

  const { id, placeholder, value, onChange, errormessage } = props;

  return (
    <div>
      <div className="phonefield">
        <label> {t("common.phone")}</label>
        <PhoneInput
          id={id}
          containerClass="phonefield"
          inputClass="input"
          placeholder={placeholder}
          prefix="+"
          countryCodeEditable={true}
          preferredCountries={["no", "us", "se", "dk", "uk"]}
          country={"no"}
          value={value}
          onChange={onChange}
        />
        <ErrorMessage errormessage={errormessage} />
      </div>
    </div>
  );
}

PhoneField.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  errormessage: PropTypes.string,
};
