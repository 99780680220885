import React, { useState, useEffect } from "react";

import "./Tabs.css";

export default function Tabs(props) {
  const [active, setActive] = useState(0);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (Array.isArray(props.children)) {
      const tabs = props.children.map((tab, index) => {
        if (tab.props.active) setActive(index);
        return tab.props;
      });
      setTabs(tabs);
    } else setTabs(props.children);
  }, [props.children]);

  function tabSelected(id, tabClick) {
    setActive(id);

    if (tabClick) tabClick();
  }

  const className = props.className || "";

  return (
    <div className={`tabs ${className}`}>
      {Array.isArray(tabs) && (
        <>
          <div className="menu">
            {tabs.map((tab, index) => {
              const isActive = index === active ? "active" : "";
              return (
                <div
                  key={`tabs-menu-item-${index}`}
                  className={`item clickable ${isActive}`}
                  onClick={(e) => tabSelected(index, tab.onClick, e)}
                >
                  {tab.title}
                </div>
              );
            })}
          </div>
          <div className="tab-content">{props.children[active]}</div>
        </>
      )}
      {!Array.isArray(tabs) && <div className="tab-content">{props.children}</div>}
    </div>
  );
}
