import { FC } from "react";
import Dropdown2 from "../../../Dropdown2/Dropdown2";
import styles from "./styles.module.css";

interface Props {
  onChange: (item: any) => void;
  userOptions: {
    type: string;
    content: JSX.Element;
  }[];
}

const SelectBox: FC<Props> = ({ userOptions, onChange }) => {
  return (
    <div className={styles.root}>
      {/* 
        // @ts-ignore */}
      <Dropdown2 id="company-select" options={userOptions} onChange={onChange} />
    </div>
  );
};

export default SelectBox;
