import Joi from "joi";

export function differentAddressValidationSchema(translation) {
  const t = translation;

  return Joi.object({
    address: Joi.string()
      .max(100)
      .required()
      .messages({
        "string.empty": t("validation.addressRequired"),
        "any.required": t("validation.addressRequired"),
        "string.max": t("validation.addressMaxLength"),
      }),
    country: Joi.alternatives(
      Joi.object({
        iso2Code: Joi.string(),
        iso3Code: Joi.string(),
        label: Joi.string(),
        value: Joi.number(),
      })
    )
      .required()
      .messages({
        "object.base": t("validation.countryRequired"),
        "any.required": t("validation.countryRequired"),
        "alternatives.match": t("validation.countryRequired"),
      }),
    postCode: Joi.number()
      .unsafe()
      .max(9999999999)
      .required()
      .messages({
        "number.base": t("validation.postCodeRequired"),
        "any.required": t("validation.postCodeRequired"),
        "number.max": t("validation.postCodeMaxLength"),
        "number.unsafe": t("validation.postCodeMaxLength"),
      }),
    place: Joi.string()
      .max(100)
      .required()
      .messages({
        "string.empty": t("validation.placeRequired"),
        "any.required": t("validation.placeRequired"),
        "string.max": t("validation.placeMaxLength"),
      }),
  }).unknown();
}
