import React, { useState } from "react";

import SVGAsset from "../SVGAsset/SVGAsset";

import "./CollapsibleFilter.css";

export default function Collapsible(props) {
  const { title } = props;
  const [isOpen, setIsOpen] = useState(false);

  function toggleCollapsible() {
    setIsOpen(!isOpen);
  }

  return (
    <div className="collapsible-container">
      <div className="flex gap10 align collapsible-header clickable" onClick={() => toggleCollapsible()}>
        <SVGAsset name="filter" />
        <h3>{title}</h3>
        <SVGAsset className="right pad10" name={`${isOpen ? "filter-minus" : "plus-circle-small"}`} />
      </div>
      {isOpen && <div className="collapsible-content">{props.children}</div>}
    </div>
  );
}
