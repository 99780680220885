import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import SVGAsset from "../../components/SVGAsset/SVGAsset";

import "./ContextPanel.css";

/**
 * @param {[{ label: "string", action: function }]} props
 */

const contextType = {
  Primary: "dotdotdot",
  Secondary: "dotdotdot-white",
};
ContextPanel.contextType = contextType;

export default function ContextPanel(props) {
  const { options } = props;
  const contextType = props.contextType || ContextPanel.contextType.Primary;

  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handler = (e) => {
      let parent = e.target;
      while ((parent = parent.parentElement) !== null) {
        if (parent === ref.current) {
          break;
        }
      }
      const isTargetComponent = parent !== null;

      if (!isTargetComponent) {
        setOpen(false);
      }
    };

    document.body.addEventListener("click", handler);

    const cleanup = () => {
      document.body.removeEventListener("click", handler);
    };

    return cleanup;
  }, [setOpen, ref]);

  function openContext() {
    setOpen(true);
  }

  return (
    <>
      <div className="context-panel" ref={ref}>
        <span onClick={() => openContext()}>
          <SVGAsset name={contextType} className="open-context-panel" />
        </span>
        {!!open && (
          <>
            <div className="options">
              {options.map((option, i) => {
                return (
                  <div
                    className="option"
                    onClick={() => {
                      option.action();
                      setOpen(false);
                    }}
                    key={"context-option-" + i}
                  >
                    {option.label}
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
}

ContextPanel.propTypes = {
  options: PropTypes.array.isRequired,
};
