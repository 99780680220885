import { axios, APIBASE_REVIVERAPPLICATION } from "./ApiConfig";

export const getTotalSales = async (from, to) =>
  axios.get(`${APIBASE_REVIVERAPPLICATION}api/graph/totalSales`, {
    params: {
      from,
      to,
    },
  });

export const getAverageCurrency = async (from, to) =>
  axios.get(`${APIBASE_REVIVERAPPLICATION}api/graph/averageCurrency`, {
    params: {
      from,
      to,
    },
  });
