import React from "react";
import CreatableSelect from "react-select/creatable";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { useTranslation } from "react-i18next";

import "./TextfieldMulti.css";

const customStyles = {
  control: (base) => ({
    ...base,
    borderRadius: "5px",
    border: "2px solid var(--SFPlightBorderGray)",

    minHeight: "53px",
    ":hover": {
      borderColor: "#0000FF",
    },
    ":focus-within": {
      borderColor: "#0000FF",
      boxShadow: "none",
    },
  }),
};

export default function TextfieldMulti(props) {
  const { t } = useTranslation();

  function handleKeyDown(event) {
    switch (event.key) {
      case "Enter":
        props.keyDownHandler();
        event.preventDefault();
        break;
      default:
    }
  }
  return (
    <div className="textfield-multi">
      <label>{props.label}</label>
      <CreatableSelect
        styles={customStyles}
        placeholder={t("commonComponents.typeEnterEmail")}
        components={{ DropdownIndicator: null }}
        menuIsOpen={false}
        isClearable
        isMulti
        onKeyDown={(e) => handleKeyDown(e)}
        {...props}
      />
      <ErrorMessage errormessage={props.errormessage} />
    </div>
  );
}
