import { axios, APIBASE_REVIVERFEEDBACK } from "./ApiConfig";

export const postCreateFeedback = async (taskId, transactionId, orderId, comment, rating, senderUserId) => {
  let taskConfig = {
    headers: {
      TaskId: taskId,
    },
  };

  await axios.post(
    `${APIBASE_REVIVERFEEDBACK}/api/feedback/createFromTransaction`,
    {
      comment,
      rating,
      transactionId,
      orderId,
      senderUserId,
    },
    taskConfig
  );
};

export const getFeedback = async (companyId) =>
  await axios.get(`${APIBASE_REVIVERFEEDBACK}/api/feedback/company/${companyId}/all`);

export const editFeedback = async (feedbackId) =>
  await axios.put(`${APIBASE_REVIVERFEEDBACK}/api/feedback/edit/${feedbackId}`);

export const declineFeedback = async (feedbackId) =>
  await axios.put(`${APIBASE_REVIVERFEEDBACK}/api/feedback/decline/${feedbackId}`);

export const archiveFeedback = async (feedbackId) =>
  await axios.put(`${APIBASE_REVIVERFEEDBACK}/api/feedback/archive/${feedbackId}`);

export const approveFeedback = async (feedbackId) =>
  await axios.put(`${APIBASE_REVIVERFEEDBACK}/api/feedback/approve/${feedbackId}`);

export const getAllFeedback = async () => await axios.get(`${APIBASE_REVIVERFEEDBACK}/api/feedback/all`);
