import React from "react";
import { useTranslation } from "react-i18next";

import Grid from "../../../components/Grid/Grid";
import moment from "moment-timezone";
import TaskTypeEnum from "../../../constants/enums/TaskTypeEnum";
import { calculateDeviation } from "../../../utils/Calculations";

import "./GeneralTaskInfo.css";

export default function GeneralTaskInfo(props) {
  const { t } = useTranslation();
  const { order, currentCompany, taskType, taskCounterParty, taskAmount, taskPrice } = props;

  return (
    <Grid className="font-family-polysans pad30 taskinfo-container">
      <Grid.Row>
        <Grid.Col span={4}>
          <label>{t("common.date")}</label>
        </Grid.Col>
        <Grid.Col span={8}>
          <span>{moment(order.created).format("DD.MM.YYYY")}</span>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col span={4}>
          <label>{t("tasks.counterParty")}</label>
        </Grid.Col>
        <Grid.Col span={8}>
          <span>{taskCounterParty}</span>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col span={4}>
          <label>{t("common.type")}</label>
        </Grid.Col>
        <Grid.Col span={8}>
          <span>{t("common.fish." + order.salesItem.salesItem)}</span>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col span={4}>
          <label>{t("common.amount")}</label>
        </Grid.Col>
        <Grid.Col span={8}>
          <span>
            {taskType === TaskTypeEnum.FINAL_AMOUNT_STR
              ? calculateDeviation(taskAmount, order.amountDeviation)
              : taskAmount + " " + order.unit.unit}
          </span>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col span={4}>
          <label>{t("common.price")}</label>
        </Grid.Col>
        <Grid.Col span={8}>
          <span>
            {taskPrice} {currentCompany.companyCurrency} per kg
          </span>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
}
