import React, { useState, useEffect } from "react";
import StepLabel from "../Wizard/StepLabel/StepLabel";

import "./TabsWiz.css";

function TabsWiz(props) {
  const [active, setActive] = useState(0);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    const tabs = props.children.map((tab, index) => {
      if (tab.props.isActive) setActive(index);
      let status = "";
      if (tab.props.isActive) {
        status = "active";
      } else if (tab.props.isDone) {
        status = "doneGray";
      } else if (tab.props.isActive === false) {
        status = "notActive";
      }
      return (
        <StepLabel
          label={tab.props.stepTitle}
          titleStatus={status}
          isVisible={tab.props.isVisible}
          hasBorder={tab.props.hasBorder}
        />
      );
    });
    setTabs(tabs);
  }, [props.children]);

  return (
    <div className={`tabswiz ${props.className}`}>
      <div className="menu">
        {tabs.map((tab, index) => {
          return (
            <div key={`tabswiz-menu-item-${index}`} className={`item ${tab.props.hasBorder ? "hasBorder" : ""}`}>
              {tab.props.isVisible && tab}
            </div>
          );
        })}
      </div>
      <div className="tabswiz-content">{props.children[active]}</div>
    </div>
  );
}

export default TabsWiz;
