import { axios, APIBASE_REVIVERAPPLICATION } from "./ApiConfig";

export const getOrdersForCompany = (companyId, includeInactive, pageNumber) =>
  axios.get(`${APIBASE_REVIVERAPPLICATION}api/orders/getOrdersForCompany?`, {
    params: {
      companyId,
      includeInactive,
      pageNumber,
    },
  });

export const getMyOrderList = (companyId, includeInactive, pageNumber) =>
  axios.get(`${APIBASE_REVIVERAPPLICATION}api/orders/getMyOrderList?`, {
    params: {
      companyId,
      includeInactive,
      pageNumber,
    },
  });

export const getOrder = (orderId) => axios.get(`${APIBASE_REVIVERAPPLICATION}api/orders/${orderId}`);

export const getOrderByTransactionId = (transactionId, companyId) =>
  axios.get(`${APIBASE_REVIVERAPPLICATION}api/orders/getOrderByTransactionAndCompanyId/${transactionId}/${companyId}`);

export const postFilteredOrders = async (filter) =>
  axios.post(`${APIBASE_REVIVERAPPLICATION}api/orders/filteredOrders`, filter);

export const getFilters = async (currency, unit) =>
  axios.get(`${APIBASE_REVIVERAPPLICATION}api/orders/filters?currency=${currency}&unit=${unit}`);

export const getCreateOrderFromOrder = async (orderId, companyId, userId, priceToPay) =>
  axios.get(`${APIBASE_REVIVERAPPLICATION}api/orders/createOrderFromOrder`, {
    params: {
      orderId,
      companyId,
      userId,
      priceToPay,
    },
  });

export const postCreateOrder = async (order) => axios.post(`${APIBASE_REVIVERAPPLICATION}api/orders/create`, order);

export const deleteOrder = async (orderId) => axios.delete(`${APIBASE_REVIVERAPPLICATION}api/orders/${orderId}/delete`);

export const getCreateOrderValues = async () => axios.get(`${APIBASE_REVIVERAPPLICATION}api/orders/create/values`);

export const postReleaseReservation = async (orderId) =>
  axios.post(`${APIBASE_REVIVERAPPLICATION}api/orders/releaseReservation/${orderId}`);

export const getValidConditions = async (salesItemId) =>
  axios.get(`${APIBASE_REVIVERAPPLICATION}api/orders/create/validConditions/${salesItemId}`);

export const getValidSizes = async (salesItemId, conditionId) =>
  axios.get(`${APIBASE_REVIVERAPPLICATION}api/orders/create/validConditions/${salesItemId}/validSizes/${conditionId}`);

export const createPayment = async (orderId) =>
  axios.post(`${APIBASE_REVIVERAPPLICATION}api/orders/checkout/${orderId}`);

export const completePayment = async (orderId) =>
  axios.post(`${APIBASE_REVIVERAPPLICATION}api/orders/checkout/${orderId}/complete`);

export const getOrderAmounts = async (orderId) =>
  axios.get(`${APIBASE_REVIVERAPPLICATION}api/orders/${orderId}/amounts`);

export const getStatistics = async () => axios.get(`${APIBASE_REVIVERAPPLICATION}api/admin/orderStatistics`);

export const postInvoice = async (orderId, taskId) => {
  let taskConfig = {
    headers: {
      TaskId: taskId,
    },
  };

  await axios.post(`${APIBASE_REVIVERAPPLICATION}api/orders/invoice/${orderId}`, "", taskConfig);
};

export const postHandleTransport = async (orderId, transportCompaniesIds, taskId) => {
  let taskConfig = {
    headers: {
      TaskId: taskId,
    },
  };

  await axios.post(
    `${APIBASE_REVIVERAPPLICATION}api/orders/handleTransport`,
    {
      orderId,
      transportCompaniesIds,
    },
    taskConfig
  );
};

export const postUpdateOrder = async (orderId, order) =>
  await axios.post(`${APIBASE_REVIVERAPPLICATION}api/orders/${orderId}/update`, order);

export const postPromoteOrder = async (orderId) =>
  await axios.post(`${APIBASE_REVIVERAPPLICATION}api/orders/promote/${orderId}`);

export const getStaleTransactions = async () =>
  await axios.get(`${APIBASE_REVIVERAPPLICATION}api/admin/staleTransactions`);

export const getBoxtypeValues = async () => axios.get(`${APIBASE_REVIVERAPPLICATION}api/orders/create/values/boxtypes`);

export const postCalculateTransportPrice = async (
  orderId,
  postcode,
  country,
  place,
  currentCompanyId,
  amount,
  numberOfBoxes,
  boxtypeId
) =>
  await axios.post(`${APIBASE_REVIVERAPPLICATION}api/orders/calculateTransportPrice`, {
    orderId,
    postcode,
    country,
    place,
    currentCompanyId,
    amount,
    numberOfBoxes,
    boxtypeId,
  });

export const postCalculateTransportPrices = async (
  orderId,
  address,
  postCode,
  place,
  countryCodeIso3,
  countryCodeIso2,
  isEu
) =>
  await axios.post(`${APIBASE_REVIVERAPPLICATION}api/orders/calculateTransportPrices`, {
    orderId,
    address: {
      address,
      postCode,
      place,
      countryCodeIso3,
      countryCodeIso2,
      isEu,
    },
  });

export const getSalesItemById = async (salesItemId) =>
  await axios.get(`${APIBASE_REVIVERAPPLICATION}api/orders/salesitem/${salesItemId}`);

export const postFinalAmount = async (orderId, amount, taskId) => {
  let taskConfig = {
    headers: {
      TaskId: taskId,
    },
  };

  await axios.post(`${APIBASE_REVIVERAPPLICATION}api/orders/${orderId}/setFinalAmount/${amount}`, "", taskConfig);
};
