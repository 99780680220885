import React from "react";
import SVGAsset from "../SVGAsset/SVGAsset";

import "./RadioButton.css";

export default function RadioButton(props) {
  const { selected, onClick, label, value, isDisabled } = props;
  const isSelected = value === selected;

  return (
    <div className={`radio-button ${isDisabled ? "disabled" : ""}`}>
      <div className={`flex gap20 radio-box clickable ${isSelected && "selected-radio-box"}`} onClick={() => onClick()}>
        <label>{label}</label>
        <div className={`radio-outer-circle ${isSelected && "selected-outer-circle"}`}>
          <div className={`radio-inner-circle ${!isSelected && "unselected-inner-circle"}`}></div>
          <SVGAsset className="radio-inner-check" name="white-checkmark"></SVGAsset>
        </div>
      </div>
    </div>
  );
}
