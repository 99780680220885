import { FC } from "react";
import styles from "./styles.module.css";

interface Props {}

const ActiveOrdersHeader: FC<Props> = (props) => {
  return <header className={styles.root} {...props} />;
};

export default ActiveOrdersHeader;
