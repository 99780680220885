import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { get } from "lodash";

import Grid from "../../components/Grid/Grid";
import moment from "moment-timezone";
import UserContext from "../../contexts/UserContext";
import Checkbox from "../../components/Checkbox/Checkbox";
import Spinner from "../../components/Spinner/Spinner";
import { calculateDeviation } from "../../utils/Calculations";

import "./OrderTabContent.css";

export default function OrderTabContent(props) {
  const order = props.order;
  const { t } = useTranslation();

  const userContext = useContext(UserContext);
  const { currentCompany } = userContext;

  return (
    <div>
      <Grid className="order-tab-section nogap">
        <Grid.Row>
          <Grid.Col span={12}>
            <p>{t("common.product")}</p>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={2}>
            <label id="type-label">{t("common.type")}</label>
          </Grid.Col>
          <Grid.Col span={10}>
            <span id="type-span">{t("common.fish." + order.salesItem.salesItem)}</span>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={2}>
            <label id="amount-label">{t("common.amount")}</label>
          </Grid.Col>
          <Grid.Col span={10}>
            <span id="amount-span">
              {order.amountDeviation === 0
                ? order.remainingAmount + " " + order.unit.unit
                : calculateDeviation(order.remainingAmount, order.amountDeviation)}
            </span>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={2}>
            <label id="size-label">{t("common.size")}</label>
          </Grid.Col>
          <Grid.Col span={10}>
            <span id="size-span">{order.size.size}</span>
          </Grid.Col>
        </Grid.Row>
        {order.gear && (
          <Grid.Row>
            <Grid.Col span={2}>
              <label id="tool-label">{t("common.tool")}</label>
            </Grid.Col>
            <Grid.Col span={10}>
              <span id="tool-span">{t("common.tools." + get(order, "gear.gear", ""))}</span>
            </Grid.Col>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Col span={2}>
            <label id="condition-label">{t("common.condition")}</label>
          </Grid.Col>
          <Grid.Col span={10}>
            <span id="condition-span">{t("common.conditions." + order.condition.condition)}</span>
          </Grid.Col>
        </Grid.Row>
        {order.treatment && (
          <Grid.Row>
            <Grid.Col span={2}>
              <label id="treatment-label">{t("common.treatment")}</label>
            </Grid.Col>
            <Grid.Col span={10}>
              <span id="treatment-span">{t("common.treatments." + get(order, "treatment.treatment", ""))}</span>
            </Grid.Col>
          </Grid.Row>
        )}
      </Grid>

      <Grid className="order-tab-section nogap">
        <Grid.Row>
          <Grid.Col span={12}>
            <p>{t("commonOrders.captureAndDelivery")}</p>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={4}>
            <label id="validFrom-label">{t("common.validFrom")}</label>
          </Grid.Col>
          <Grid.Col span={8}>
            <span id="validFrom-span">{moment(order.validFrom).format("DD.MM.YYYY")}</span>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={4}>
            <label id="validTo-label">{t("common.validTo")}</label>
          </Grid.Col>
          <Grid.Col span={8}>
            <span id="validTo-span">{moment(order.validTo).format("DD.MM.YYYY")}</span>
          </Grid.Col>
        </Grid.Row>
        {order.catchDate && (
          <Grid.Row>
            <Grid.Col span={4}>
              <label id="captured-label">{t("common.captured")}</label>
            </Grid.Col>
            <Grid.Col span={8}>
              <span id="captured-span">
                {order.catchDate !== null ? moment(order.catchDate).format("DD.MM.YYYY") : ""}
              </span>
            </Grid.Col>
          </Grid.Row>
        )}
        {order.deliveryStart && (
          <Grid.Row>
            <Grid.Col span={4}>
              <label id="delivery-label">{t("common.delivery")}</label>
            </Grid.Col>
            <Grid.Col span={8}>
              <span id="delivery-span">
                {order.deliveryStart !== null ? moment(order.deliveryStart).format("DD.MM.YYYY -") : ""}
                {order.deliveryEnd !== null ? moment(order.deliveryEnd).format(" DD.MM.YYYY") : ""}
              </span>
            </Grid.Col>
          </Grid.Row>
        )}
        {order.packingDate && (
          <Grid.Row>
            <Grid.Col span={4}>
              <label id="packingDate-label">{t("commonOrders.packingDate")}</label>
            </Grid.Col>
            <Grid.Col span={8}>
              <span id="packingDate-span">
                {order.packingDate !== null ? moment(order.packingDate).format("DD.MM.YYYY") : ""}
              </span>
            </Grid.Col>
          </Grid.Row>
        )}
        {order.expirationDate && (
          <Grid.Row>
            <Grid.Col span={4}>
              <label id="expirationDate-label">{t("commonOrders.expirationDate")}</label>
            </Grid.Col>
            <Grid.Col span={8}>
              <span id="expirationDate-span">
                {order.expirationDate !== null ? moment(order.expirationDate).format("DD.MM.YYYY") : ""}
              </span>
            </Grid.Col>
          </Grid.Row>
        )}
        {order.lastDeliveryDate && (
          <Grid.Row>
            <Grid.Col span={4}>
              <label id="lastDeliveryDate-label">{t("commonOrders.lastDeliveryDate")}</label>
            </Grid.Col>
            <Grid.Col span={8}>
              <span id="lastDeliveryDate-span">{moment(order.lastDeliveryDate).format("DD.MM.YYYY")}</span>
            </Grid.Col>
          </Grid.Row>
        )}
        {order.transportFrom && (
          <Grid.Row>
            <Grid.Col span={4}>
              <label id="fishCurrentLocation-label">{t("commonOrders.fishCurrentLocation")}</label>
            </Grid.Col>
            <Grid.Col span={8}>
              <span id="fishCurrentLocation-span">{order.transportFrom}</span>
            </Grid.Col>
          </Grid.Row>
        )}
        {order.description && (
          <Grid.Row>
            <Grid.Col span={4}>
              <label id="description-label">{t("common.description")}</label>
            </Grid.Col>
            <Grid.Col span={8}>
              <span id="description-span">{order.description}</span>
            </Grid.Col>
          </Grid.Row>
        )}
      </Grid>

      <Grid className="order-tab-section nogap">
        <Grid.Row>
          <Grid.Col span={12}>
            <p>{t("common.price")}</p>
          </Grid.Col>
        </Grid.Row>
        {props.isLoading && (
          <Grid.Row>
            <Grid.Col span={12} className="at3">
              <Spinner loading={props.isLoading} inline />
            </Grid.Col>
          </Grid.Row>
        )}
        {!props.isLoading && (
          <>
            <Grid.Row>
              <Grid.Col span={4}>
                <label id="priceInclFee-label">{t("commonOrders.priceInclFee")}</label>
              </Grid.Col>
              <Grid.Col span={8}>
                <span id="priceInclFee-span">
                  {currentCompany.companyCurrency} {order.priceWithFee[currentCompany.companyCurrency].toFixed(2)}/
                  {order.priceUnit.unit}
                </span>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col span={4}>
                <label>{t("commonOrders.inclTransportation")}</label>
              </Grid.Col>
              <Grid.Col span={8}>
                <Checkbox id="fixTransportCB" checked={!currentCompany.withoutTransport} disabled={true} />
              </Grid.Col>
            </Grid.Row>

            {!currentCompany.withoutTransport && (
              <>
                {order.orderDelivery && order.orderDelivery.transportNumberOfDays >= 0 && (
                  <Grid.Row>
                    <Grid.Col span={4}>
                      <label id="transportDays-label">{t("commonOrders.transportDays")}</label>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <span id="transportDays-span">
                        {order.orderDelivery.transportNumberOfDays}&nbsp;
                        {order.orderDelivery.transportNumberOfDays === 1
                          ? t("commonOrders.day")
                          : t("commonOrders.days")}
                      </span>
                    </Grid.Col>
                  </Grid.Row>
                )}
                {order.orderDelivery && order.orderDelivery.transportNumberOfDays >= 0 && (
                  <Grid.Row>
                    <Grid.Col span={4}>
                      <label id="firstPossibleDeliveryDate-label">{t("commonOrders.transportCompany")}</label>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <span id="firstPossibleDeliveryDate-span">{order.orderDelivery.transportCompany}</span>
                    </Grid.Col>
                  </Grid.Row>
                )}
                {order.orderDelivery && order.orderDelivery.transportNumberOfDays >= 0 && (
                  <Grid.Row>
                    <Grid.Col span={4}>
                      <label id="firstPossibleDeliveryDate-label">{t("commonOrders.firstPossibleDeliveryDate")}</label>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <span id="firstPossibleDeliveryDate-span">
                        {moment(order.orderDelivery.firstPossibleDeliveryDate).format("DD.MM.YYYY")}
                      </span>
                    </Grid.Col>
                  </Grid.Row>
                )}
              </>
            )}
          </>
        )}
      </Grid>
    </div>
  );
}
