import React, { useEffect, useState } from "react";
import { getOrderTypeIndicator } from "../../utils/Orders";
import { useTranslation } from "react-i18next";

import Grid from "../../components/Grid/Grid";
import SVGAsset from "../../components/SVGAsset/SVGAsset";
import ActiveOrderDetails from "./ActiveOrderDetails";
import CreateOrder from "../../components/_Reviver/molecules/CreateOrder";
import ActiveOrders from "../../components/_Reviver/molecules/ActiveOrders";
import ActiveOrdersHeader from "../../components/_Reviver/molecules/ActiveOrdersHeader";
import ActiveOrderCard from "../../components/_Reviver/molecules/ActiveOrderCard";
import Stack from "../../components/_Reviver/atoms/Stack";
import Text from "../../components/_Reviver/atoms/Text";
import CTA from "../../components/_Reviver/atoms/CTA";
import { useViewportSize } from "@mantine/hooks";

export default function CompanyActiveOrders(props) {
  const { t } = useTranslation();
  const { width } = useViewportSize();

  const { orders, currentCompany, deleteOrder, filterTableCB, refreshActiveOrdersCB, createNew } = props;
  const [selectedActiveOrderBox, setSelectedActiveOrderBox] = useState();
  const isMobile = width > 767;

  useEffect(() => {
    // If company changes, clear selected active order
    if (selectedActiveOrderBox && currentCompany && currentCompany.companyId !== selectedActiveOrderBox.company.id) {
      setSelectedActiveOrderBox(null);
    }
  }, [currentCompany, selectedActiveOrderBox]);

  function handleActiveOrder(order) {
    setSelectedActiveOrderBox(order);

    // Clear filter if order no longer selected
    if (!order) {
      filterTableCB(null);
      return;
    }

    const filter = buildFilter(order);
    filterTableCB(filter);
  }

  function buildFilter(order) {
    if (!order) return;

    // These are required in create order, no need to nullcheck
    let filter = {
      salesItems: [order.salesItem.id],
      sizes: [order.size.id],
      conditions: [order.condition.id],
    };

    // These are not required in create order, need to nullcheck
    if (order.treatment) filter = { ...filter, treatments: [order.treatment.id] };
    if (order.gear) filter = { ...filter, gears: [order.gear.id] };
    return filter;
  }

  return (
    <div>
      <Grid className="nogap">
        {!selectedActiveOrderBox && (
          <>
            <Grid.Row>
              <Grid.Col span={12}>
                <ActiveOrdersHeader>
                  <Stack>
                    <Text size={isMobile ? "md" : "xl"} display="title" tt="uppercase" strong={true}>
                      {t("commonOrders.activeRequests")}
                    </Text>
                    <Text size={isMobile ? "sm" : "md"} tt="uppercase">
                      {orders.length} {t("commonOrders.activeOrders")}
                    </Text>
                  </Stack>
                  <CTA size="lg" intent="primary" onClick={createNew}>
                    {t("commonOrders.newOrder")}
                  </CTA>
                </ActiveOrdersHeader>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col span={12}>
                <ActiveOrders>
                  <CreateOrder onClick={createNew} />

                  {orders.map((order, i) => {
                    return (
                      <ActiveOrderCard
                        order={order}
                        key={"active-order-box-" + i}
                        handleActiveOrder={handleActiveOrder}
                        deleteOrder={deleteOrder}
                        orderTypeIndicator={getOrderTypeIndicator(order.orderType.id)}
                      />
                    );
                  })}
                </ActiveOrders>
              </Grid.Col>
            </Grid.Row>
          </>
        )}
        {selectedActiveOrderBox && (
          <>
            <Grid.Row className="active-order-details-box">
              <Grid.Row>
                <Grid.Col span={12}>
                  <h2 className="flex align">
                    <SVGAsset
                      onClick={() => handleActiveOrder(null)}
                      className="padsides10 clickable"
                      name="previouspage-arrow"
                    />
                    {t("commonOrders.activeRequests").toUpperCase()}
                  </h2>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col span={12}>
                  <ActiveOrderDetails
                    order={selectedActiveOrderBox}
                    handleActiveOrder={handleActiveOrder}
                    refreshActiveOrdersCB={refreshActiveOrdersCB}
                  />
                </Grid.Col>
              </Grid.Row>
            </Grid.Row>
          </>
        )}
      </Grid>
    </div>
  );
}
