import React from "react";
import PropTypes from "prop-types";
import SVGAsset from "../../components/SVGAsset/SVGAsset";

import "./ModalDialog.css";

export default function ModalDialog(props) {
  const { open, onClose, isCloseVisible = true, headerText } = props;

  function closeDialog() {
    if (onClose) {
      onClose();
    }
  }

  return (
    <>
      {!!open && (
        <div className={`modal-container ${props.className || ""}`} id={"modal-dialog"}>
          <div className="modal-content">
            {isCloseVisible && (
              <div className={`${headerText ? "close-dialog" : "close-dialog-normal"}`}>
                <p>{headerText}</p>
                <SVGAsset
                  className="close-x clickable"
                  name={`${headerText ? "close-white" : "close"}`}
                  onClick={() => closeDialog()}
                ></SVGAsset>
              </div>
            )}
            <div className="modal-body">{props.children}</div>
          </div>
        </div>
      )}
    </>
  );
}

ModalDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};
