import React, { useState, useContext, useEffect } from "react";
import { ErrorDialogContext } from "../../contexts/ErrorDialogContext";
import ModalDialog from "../../containers/ModalDialog/ModalDialog";
import ModalDialogButtonsWrapper from "./ModalDialogButtonsWrapper";
import MessageBar from "../../components/MessageBar/MessageBar";
import BlueButton from "../../components/BlueButton/BlueButton";

export default function ErrorDialog() {
  const errorDialogContext = useContext(ErrorDialogContext);
  const { isErrorDialogOpen, showErrorDialog, errorDialogOptions } = errorDialogContext;
  const [open, setOpen] = useState(isErrorDialogOpen);

  useEffect(() => {
    setOpen(isErrorDialogOpen);
  }, [setOpen, isErrorDialogOpen]);

  function closeDialog() {
    showErrorDialog(false);
    if (errorDialogOptions.onClose) {
      errorDialogOptions.onClose();
    }
  }

  function getMessageBar(exception) {
    return <MessageBar type={MessageBar.type.Exception} message={exception.message} />;
  }

  return (
    <>
      <ModalDialog open={open || false} onClose={errorDialogOptions.onClose || null}>
        <div className="pad30">
          <p>{errorDialogOptions.message}</p>
          {!!errorDialogOptions.error && getMessageBar(errorDialogOptions.error)}
        </div>
        <ModalDialogButtonsWrapper>
          <BlueButton id="btn-close-dialog" text="OK" onClick={closeDialog} />
        </ModalDialogButtonsWrapper>
      </ModalDialog>
    </>
  );
}
