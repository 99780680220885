import React, { useEffect, useState, useMemo, useContext, useRef, useCallback } from "react";
import Moment from "react-moment";
import Grid from "../../components/Grid/Grid";
import { useTranslation } from "react-i18next";
import {
  postFilteredOrders,
  getOrdersForCompany,
  getOrder,
  getCreateOrderFromOrder,
  postCreateOrder,
  getFilters,
  postReleaseReservation,
  getOrderAmounts,
  deleteOrder,
  postCalculateTransportPrice,
  getBoxtypeValues,
} from "../../services/Orders";
import { getCompanyCreditById } from "../../services/Company";
import { getCountries } from "../../services/RegisterCompany";
import { postCanTransportFrom } from "../../services/Transport";
import { get } from "lodash";
import { Helmet } from "react-helmet-async";
import { CSSTransition } from "react-transition-group";
import { calcNumberOfPallets, calcMinAmountOfBoxes } from "../../utils/TransportUtil";
import {
  createStep1SchemaSales,
  createStep1SchemaBuy,
  createStep2Schema,
  createStep2AddressSchema,
  createStep3Schema,
} from "./CreateOrderModal/CreateOrderValidationSchemas";
import { validateFieldsHelper } from "../../utils/ValidationHelper";

import BlueButton from "../../components/BlueButton/BlueButton";
import SVGAsset from "../../components/SVGAsset/SVGAsset";
import CompanyActiveOrders from "./CompanyActiveOrders";
import UserContext from "../../contexts/UserContext";
import Filters from "../../components/Filters/Filters";
import Tabs from "../../components/Tabs/Tabs";
import Tab from "../../components/Tabs/Tab";
import OrderTabContent from "./OrderTabContent";
import ModalDialog from "../ModalDialog/ModalDialog";
import ModalDialogButtonsWrapper from "../ModalDialog/ModalDialogButtonsWrapper";
import TabWiz from "../../components/TabWizard/TabWiz";
import TabsWiz from "../../components/TabWizard/TabsWiz";
import OrderSellStep1 from "./CreateOrderModal/Steps/OrderSellStep1";
import OrderSellStep2 from "./CreateOrderModal/Steps/OrderSellStep2";
import OrderSellStep3 from "./CreateOrderModal/Steps/OrderSellStep3";
import OrderBuyStep1 from "./CreateOrderModal/Steps/OrderBuyStep1";
import OrderBuyStep2 from "./CreateOrderModal/Steps/OrderBuyStep2";
import InformationStep from "./CreateOrderModal/Steps/InformationStep";
import Spinner from "../../components/Spinner/Spinner";
import Dropdown2 from "../../components/Dropdown2/Dropdown2";
import Dropdown from "../../components/Dropdown/Dropdown";
import OrderTypeEnum from "../../constants/enums/OrderTypeEnum";
import CreateOrderModal from "./CreateOrderModal/CreateOrderModal";
import getCreateOrderObject from "../../structures/CreateOrderStructure";
import Pagination from "../../components/Pagination/Pagination";
import Textfield from "../../components/TextField/TextField";
import Cookie from "universal-cookie";
import CollapsibleFilter from "../../components/Filters/CollapsibleFilter";
import Drawer from "../../components/Drawer/Drawer";
import Checkbox from "../../components/Checkbox/Checkbox";
import MessageBar from "../../components/MessageBar/MessageBar";

import "./Trade.css";
import { calculateDeviation } from "../../utils/Calculations";
import { differentAddressValidationSchema } from "./DifferentAddressValidationSchema";

const UPDATE_INTERVAL = 20000;

export default function Trade(props) {
  const { t } = useTranslation();

  const { setUserContext } = props;

  const userContext = useContext(UserContext);
  const { currentCompany, user, companies } = userContext;

  //  Drawer
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [orderInDrawer, setOrderInDrawer] = useState(null);
  const [amountOptionsDrawer, setAmountOptionsDrawer] = useState([]);
  const [selectedAmountDrawer, setSelectedAmountDrawer] = useState(null);
  const [isAddressCBDrawerChecked, setIsAddressCBDrawerChecked] = useState(false);
  const [countryOptionsDrawer, setCountryOptionsDrawer] = useState([]);
  const [selectedCountryDrawer, setSelectedCountryDrawer] = useState(null);
  const [addressDrawer, setAddressDrawer] = useState("");
  const [postCodeDrawer, setPostCodeDrawer] = useState("");
  const [placeDrawer, setPlaceDrawer] = useState("");
  const [validateAddressMessage, setValidateAddressMessage] = useState("");
  const [newTransportPriceDrawer, setNewTransportPriceDrawer] = useState("");
  const addressDrawerNodeRef = useRef(null);
  const [boxtypeOptionsDrawer, setBoxtypeOptionsDrawer] = useState([]);
  const [selectedBoxtypeDrawer, setSelectedBoxtypeDrawer] = useState(null);
  const [nrOfBoxesDrawer, setNrOfBoxesDrawer] = useState(0);
  const [nrOfPalletsDrawer, setNrOfPalletsDrawer] = useState(0);
  const [ableToPurchase, setAbleToPurchase] = useState(true);
  const [orderTemplate, setOrderTemplate] = useState({});

  useEffect(() => {
    async function asyncGetCountries() {
      const countries = await getCountries();

      const _countryOptions = countries.data.map((country) => ({
        value: country.id,
        label: country.name,
        iso3Code: country.iso3,
        iso2Code: country.iso2,
        isEu: country.isEu,
      }));
      setCountryOptionsDrawer(_countryOptions);
    }

    async function asyncGetBoxTypes() {
      const availableBoxTypes = await getBoxtypeValues();
      const _boxtypeOptions = availableBoxTypes.data.boxtypes.map((boxtype) => ({
        value: boxtype.id,
        label: t("common.boxtypes." + boxtype.name),
        numberOfBoxesOnAPallet: boxtype.numberOfBoxesOnAPallet,
        maxWeight: boxtype.maxWeight,
      }));
      setBoxtypeOptionsDrawer(_boxtypeOptions);
    }

    asyncGetCountries();
    asyncGetBoxTypes();
  }, [t]);

  // Filter
  const [paginationInfo, setPaginationInfo] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [orders, setOrders] = useState([]);
  const [filter, setFilter] = useState({
    activeCompanyId: currentCompany && currentCompany.companyId,
    pageNumber: 1,
  });
  const [filterData, setFilterData] = useState();
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 1194px)");
    setIsMobileView(mediaQuery.matches);

    function handleMobileChange(e) {
      if (e.matches) setIsMobileView(true);
      else setIsMobileView(false);
    }

    mediaQuery.addEventListener("change", handleMobileChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMobileChange);
    };
  }, []);

  // Spinners
  const [loadingFilteredOrders, setLoadingFilteredOrders] = useState(false);
  const [isLoadingNewPriceDrawer, setIsLoadingNewPriceDrawer] = useState(false);
  const [isLoadingDrawer, setIsLoadingDrawer] = useState(false);

  // Company choice (after login)
  const cookies = useMemo(() => new Cookie(), []);
  const [isCompanyChoiceOpen, setIsCompanyChoiceOpen] = useState(false);
  const companyChoiceOptions = buildCompanyChoiceOptions();

  // Create order
  const [isCreateSalesOrderModalOpen, setIsCreateSalesOrderModalOpen] = useState(false);
  const [isCreatePurchaseOrderModalOpen, setIsCreatePurchaseOrderModalOpen] = useState(false);
  const [isChooseOrderTypeOpen, setIsChooseOrderTypeOpen] = useState(false);
  const [createOrderValues, setCreateOrderValues] = useState(getCreateOrderObject());
  const createOrderValuesRef = useRef();
  createOrderValuesRef.current = createOrderValues;
  const [purchaseOrSellFromDrawerModalConfig, setPurchaseOrSellFromDrawerModalConfig] = useState({
    isOpen: false,
    text: "",
    orderIdToCancel: 0,
  });
  const [orderCopy, setOrderCopy] = useState();
  const [isCreditPopupOpen, setIsCreditPopupOpen] = useState(false);
  const [isDifferentAddressInCreateOrderChecked, setIsDifferentAddressInCreateOrderChecked] = useState(false);
  const [differentAddressValidationMessage, setDifferentAddressValidationMessage] = useState({});
  const [createOrderValidationError, setCreateOrderValidationError] = useState({});
  const [createOrderAddressValidationError, setCreateOrderAddressValidationError] = useState({});

  // Popup tabs
  const [salesSteps, setSalesStep] = useState(() => {
    return [
      { key: "firstStep", isActive: true, isDone: false },
      { key: "secondStep", isActive: false, isDone: false },
      { key: "thirdStep", isActive: false, isDone: false },
    ];
  });
  const [currentSalesStep, setCurrentSalesStep] = useState(() => {
    return 0;
  });
  const [purchaseSteps, setPurchaseStep] = useState(() => {
    return [
      { key: "firstStep", isActive: true, isDone: false },
      { key: "secondStep", isActive: false, isDone: false },
    ];
  });
  const [currentPurchaseStep, setCurrentPurchaseStep] = useState(() => {
    return 0;
  });

  // Validation
  const [validationErrors, setValidationErrors] = useState([]);
  const [addressValidationErrors, setAddressValidationErrors] = useState([]);

  const memoGetOrdersForCompanyParams = useMemo(
    () => ({
      companyid: currentCompany && currentCompany.companyId,
      includeInactive: false,
    }),
    [currentCompany]
  );

  const memoGetFilterDataParams = useMemo(
    () => ({
      currency: "nok",
      unit: "kg",
    }),
    []
  );

  useEffect(() => {
    let ignore = false;
    let companyInterval;

    async function asyncGetOrdersForCompany() {
      const response = await getOrdersForCompany(...Object.values(memoGetOrdersForCompanyParams));
      if (!ignore) setOrders(response.data.orders);
    }

    async function asyncGetFilterData() {
      const response = await getFilters(...Object.values(memoGetFilterDataParams));
      if (!ignore) setFilterData(response.data);
    }

    if (!companyInterval) {
      companyInterval = setInterval(() => {
        asyncGetOrdersForCompany();
      }, UPDATE_INTERVAL);
    }

    asyncGetOrdersForCompany();
    asyncGetFilterData();

    return () => {
      ignore = true;
      clearInterval(companyInterval);
    };
  }, [memoGetFilterDataParams, memoGetOrdersForCompanyParams]);

  useEffect(() => {
    // Effect changes list based on filter.
    let filteredInterval;

    async function asyncPostFilteredOrders() {
      setLoadingFilteredOrders(true);
      const response = await postFilteredOrders(filter);
      setTableData(response.data.orders);
      setPaginationInfo({ numberOfPages: response.data.numberOfPages });
      setLoadingFilteredOrders(false);
    }

    if (!filteredInterval) {
      filteredInterval = setInterval(() => {
        asyncPostFilteredOrders();
      }, UPDATE_INTERVAL);
    }

    asyncPostFilteredOrders();

    return () => {
      clearInterval(filteredInterval);
    };
  }, [filter]);

  useEffect(() => {
    // If company changes, change active company in initialFilter. Also reset paginators
    setFilter((prevValue) => {
      return {
        ...prevValue,
        activeCompanyId: currentCompany && currentCompany.companyId,
        pageNumber: 1,
      };
    });
  }, [currentCompany]);

  useEffect(() => {
    const cookie = cookies.get("choosenCompany");
    if (!cookie) {
      if (companies.companies && companies.companies.length > 1) setIsCompanyChoiceOpen(true);
    }
  }, [companies, cookies]);

  function buildCompanyChoiceOptions() {
    let userOptions = [];
    userOptions = userOptions.concat(
      [
        {
          type: "heading",
          value: t("common.header.companies"),
        },
      ],
      companies.companies.map((company) => {
        return {
          value: company.companyId,
          content: (
            <div className="flex align gap10">
              <SVGAsset name="company-icon" className="circle" />
              <div>{company.companyName}</div>
            </div>
          ),
        };
      })
    );
    return userOptions;
  }

  const companyOptionsChangedHandler = (item) => {
    const companyId = item.value;
    const currentCompany = companies.companies.find((c) => c.companyId === companyId);

    setUserContext({
      ...userContext,
      currentCompany,
    });

    cookies.set("choosenCompany", true, { path: "/" });

    setIsCompanyChoiceOpen(false);
  };

  function saleOrPurchase(orderTypeId) {
    if (orderTypeId === OrderTypeEnum.SELL) {
      return {
        buttonType: "Tertiary",
        buttonText: t("commonOrders.purchase"), // user can click buy on sales orders
        svgAsset: "saleIndicator", // user should see it as a sales order,
        tabHeaderText: t("common.salesOrder"),
        tabSvgAsset: "salesIndicatorLarge",
        tabActionText: t("commonOrders.purchasenowheader"),
        tabActionSvgAsset: "purchaseIndicatorLarge",
        svgAssetWhite: "saleIndicator-white",
      };
    } else if (orderTypeId === OrderTypeEnum.AUCTION) {
      return {
        svgAssetWhite: "auction-white",
        tabHeaderText: t("commonOrders.auctionOrder"),
        tabSvgAsset: "auction-blue",
        buttonType: "Autobid",
        buttonText: t("commonOrders.setAutobid"),
        svgAsset: "auction-small-blue",
        tabActionSvgAsset: "auction-blue",
        tabActionText: "Autobud",
      };
    } else {
      return {
        buttonType: "Quaternary",
        buttonText: t("commonOrders.sell"), // user can click sell on a purchase order
        svgAsset: "purchaseIndicator", // user should see it as a purchase order
        tabHeaderText: t("common.purchaseOrder"),
        tabSvgAsset: "purchaseIndicatorLarge",
        tabActionText: "Sell to this order",
        tabActionSvgAsset: "salesIndicatorLarge",
        svgAssetWhite: "purchaseIndicator-white",
      };
    }
  }

  async function asyncGetCreateOrderFromOrder(orderId, companyId, userId, priceToPay) {
    const response = await getCreateOrderFromOrder(orderId, companyId, userId, priceToPay);
    return response.data;
  }

  async function asyncUpdateActiveOrders() {
    const responseOrders = await getOrdersForCompany(...Object.values(memoGetOrdersForCompanyParams));
    setOrders(responseOrders.data.orders);
  }

  async function asyncPostCreateOrder(order) {
    await postCreateOrder(order);
    //Update active orders after creating a new order
    await asyncUpdateActiveOrders();
  }

  async function asyncPostReleaseReservation(orderId) {
    await postReleaseReservation(orderId);
  }

  async function asyncDeleteOrder(orderId) {
    await deleteOrder(orderId);
    await asyncUpdateActiveOrders();
  }

  async function asyncCalculateTransportPrice(orderId, postCode, country, place, amount, numberOfBoxes, boxTypeId) {
    const newPrice = await postCalculateTransportPrice(
      orderId,
      postCode ? postCode : null,
      country ? country.iso3Code : null,
      place ? place : null,
      currentCompany.companyId,
      amount,
      numberOfBoxes,
      boxTypeId
    );

    return newPrice.data;
  }

  function resetCreateOrderModalValues() {
    //Reset values in modal for reusability
    setCreateOrderValues(getCreateOrderObject());
    setValidationErrors([]);
    if (currentSalesStep > 0) {
      setCurrentSalesStep(0);
      setSalesStep((prevStep) =>
        prevStep.map((x) => {
          prevStep[0].isActive = true;
          prevStep[0].isDone = false;
          prevStep[1].isActive = false;
          prevStep[1].isDone = false;
          prevStep[2].isActive = false;
          prevStep[2].isDone = false;
          return x;
        })
      );
    }
    if (currentPurchaseStep > 0) {
      setCurrentPurchaseStep(0);
      setPurchaseStep((prevStep) =>
        prevStep.map((x) => {
          prevStep[0].isActive = true;
          prevStep[0].isDone = false;
          prevStep[1].isActive = false;
          prevStep[1].isDone = false;
          return x;
        })
      );
    }
  }

  function openChooseOrderType() {
    resetCreateOrderModalValues();
    setIsChooseOrderTypeOpen(true);
  }

  function closeCreateOrderModal() {
    setIsCreateSalesOrderModalOpen(false);
    setIsCreatePurchaseOrderModalOpen(false);
    setIsDifferentAddressInCreateOrderChecked(false);
    setDifferentAddressValidationMessage({});
    setCreateOrderValidationError([]);
    setCreateOrderAddressValidationError([]);
    setOrderTemplate([]);
  }

  function openCreateOrderModal(type) {
    if (type === OrderTypeEnum.SELL) {
      setIsCreateSalesOrderModalOpen(true);
      onChangeCreateOrderCB({ orderType: OrderTypeEnum.SELL });
    } else if (type === OrderTypeEnum.BUY) {
      setIsCreatePurchaseOrderModalOpen(true);
      onChangeCreateOrderCB({ orderType: OrderTypeEnum.BUY });
    } else if (type === OrderTypeEnum.AUCTION) {
      setIsCreateSalesOrderModalOpen(true);
      onChangeCreateOrderCB({ orderType: OrderTypeEnum.AUCTION });
    }

    setIsChooseOrderTypeOpen(false);
  }

  function openCreateOrderModalEmptySearch() {
    openChooseOrderType();
  }

  const onChangeCreateOrderCB = useCallback((change) => {
    setCreateOrderValues({
      ...createOrderValuesRef.current,

      ...change,
    });
  }, []);

  const createOrderFromWizardHandler = async (orderType) => {
    if (orderType === OrderTypeEnum.BUY) {
      if (!checkMissingFields(currentPurchaseStep)) return;
      if (!(await validateDifferentAddressInCreateOrder())) return;
    } else {
      if (!checkMissingFields(currentSalesStep)) return;
    }

    let order = createOrderValues;
    order.companyId = currentCompany && currentCompany.companyId;
    order.userId = user && user.id;
    // The following is just to play nice with the backend (boxtype is not needed for ordertype purchase, but backend struggels unless it is set > 0)
    if (order.orderType === OrderTypeEnum.BUY) order.packaging.boxtype = 1;
    asyncPostCreateOrder(order);
    closeCreateOrderModal();
  };
  //#region create order validation
  function checkMissingFields(currentStep) {
    // 0 -> first step
    if (currentStep === 0) {
      if (createOrderValues.orderType === 2) {
        const validationResult = validateFieldsHelper(createStep1SchemaSales(t), {
          amount: parseFloat(createOrderValues.amount),
          minimumAmount: parseFloat(createOrderValues.minimumAmount) || 0,
          price: createOrderValues.price,
          minPrice: createOrderValues.minPrice || 0,
          salesItem: createOrderValues.salesItem,
          condition: createOrderValues.condition,
          size: createOrderValues.size,
        });
        setCreateOrderValidationError(validationResult);

        if (Object.keys(validationResult).length > 0) return false;
      } else if (createOrderValues.orderType === 1) {
        const validationResult = validateFieldsHelper(createStep1SchemaBuy(t), {
          amount: parseFloat(createOrderValues.amount),
          price: createOrderValues.price,
          maxPrice: createOrderValues.maxPrice || 0,
          salesItem: createOrderValues.salesItem,
          condition: createOrderValues.condition,
          size: createOrderValues.size,
        });
        setCreateOrderValidationError(validationResult);
        if (Object.keys(validationResult).length > 0) return false;
      }
    } else if (currentStep === 1) {
      const validationResult = validateFieldsHelper(createStep2Schema(t), createOrderValues);
      setCreateOrderValidationError(validationResult);

      if (Object.keys(validationResult).length > 0) return false;
    } else if (currentStep === 2) {
      const validationResult = validateFieldsHelper(createStep3Schema(t), createOrderValues.packaging);
      setCreateOrderValidationError(validationResult);

      if (Object.keys(validationResult).length) return false;
    }

    return true;
  }

  //#endregion

  const clickBackSalesStepHandler = () => {
    setSalesStep((prevStep) =>
      prevStep.map((x) => {
        prevStep[currentSalesStep].isActive = false;
        prevStep[currentSalesStep].isDone = false;
        prevStep[currentSalesStep - 1].isActive = true;
        return x;
      })
    );

    setCurrentSalesStep((prev) => prev - 1);
  };

  const clickNextSalesStepHandler = async () => {
    // prevent stepping into undefined step
    if (salesSteps.length - 1 <= currentSalesStep) {
      return;
    }

    //Validate steps
    if (!checkMissingFields(currentSalesStep)) return;
    if (!(await validateDifferentAddressInCreateOrder())) return;

    setSalesStep((prevStep) =>
      prevStep.map((x) => {
        prevStep[currentSalesStep].isActive = false;
        prevStep[currentSalesStep].isDone = true;
        prevStep[currentSalesStep + 1].isActive = true;
        return x;
      })
    );

    setCurrentSalesStep((prev) => prev + 1);
  };

  const clickBackPurchaseStepHandler = () => {
    setPurchaseStep((prevStep) =>
      prevStep.map((x) => {
        prevStep[currentPurchaseStep].isActive = false;
        prevStep[currentPurchaseStep].isDone = false;
        prevStep[currentPurchaseStep - 1].isActive = true;
        return x;
      })
    );

    setCurrentPurchaseStep((prev) => prev - 1);
  };

  const clickNextPurchaseStepHandler = () => {
    // prevent stepping into undefined step
    if (purchaseSteps.length - 1 <= currentPurchaseStep) {
      return;
    }

    //Validate steps
    if (!checkMissingFields(currentPurchaseStep)) return;

    setPurchaseStep((prevStep) =>
      prevStep.map((x) => {
        prevStep[currentPurchaseStep].isActive = false;
        prevStep[currentPurchaseStep].isDone = true;
        prevStep[currentPurchaseStep + 1].isActive = true;
        return x;
      })
    );

    setCurrentPurchaseStep((prev) => prev + 1);
  };

  async function validateDifferentAddressInCreateOrder() {
    if (isDifferentAddressInCreateOrderChecked && (currentSalesStep === 1 || currentPurchaseStep === 1)) {
      const inputChecker = validateFieldsHelper(createStep2AddressSchema(t), {
        address: createOrderValues.orderAddress.address,
        postCode: createOrderValues.orderAddress.postCode,
        place: createOrderValues.orderAddress.place,
        countryCodeIso3: createOrderValues.orderAddress.countryCodeIso3,
      });
      setCreateOrderAddressValidationError(inputChecker);
      let transportString = "";
      let messageBarType = "";
      if (Object.keys(inputChecker).length === 0) {
        const res = await postCanTransportFrom(createOrderValues.orderAddress.postCode);
        const canTransport = res.data;
        if (canTransport.canTransportCool === false && canTransport.canTransportFish === false) {
          transportString = t("commonOrders.fromTransValidateDontTransport");
          messageBarType = MessageBar.type.Negative;
        } else if (canTransport.canTransportCool === true && canTransport.canTransportFish === true) {
          transportString = t("commonOrders.fromTransValidateDoTransport");
          messageBarType = MessageBar.type.Positive;
        } else if (canTransport.canTransportCool === true) {
          transportString = t("commonOrders.fromTransValidateCool");
          messageBarType = MessageBar.type.Positive;
        } else if (canTransport.canTransportFish === true) {
          transportString = t("commonOrders.fromTransValidateFresh");
          messageBarType = MessageBar.type.Positive;
        }
        setDifferentAddressValidationMessage({
          message: transportString,
          messageType: messageBarType,
        });
        if (canTransport.canTransportCool === true || canTransport.canTransportFish === true) {
          return true;
        } else return false;
      } else {
        setDifferentAddressValidationMessage({ message: "", messageType: "" });
        return false;
      }
    }
    return true;
  }

  //Filter table to replicate orders 'filter values' when clicking on an active order
  const filterTableFromActiveOrdersCB = (newFilter) => {
    setFilter({ ...newFilter, activeCompanyId: currentCompany.companyId });
  };

  const changePageHandler = (pageNumber) => {
    setFilter((prevValue) => {
      return {
        ...prevValue,
        pageNumber: pageNumber,
      };
    });
  };

  // ---- Open drawer and initialize content START ----
  async function asyncGetOrderAmounts(order) {
    const response = await getOrderAmounts(order);
    const _amountOptions = response.data.allowedAmounts.map((amount) => ({
      value: amount,
      content: amount.toString(),
    }));
    return _amountOptions;
  }
  async function onChangeSelectedOrderAmountDrawer(changeAmount) {
    setSelectedAmountDrawer(changeAmount);

    const newPrice = await asyncCalculateTransportPrice(
      orderInDrawer.id,
      postCodeDrawer,
      selectedCountryDrawer,
      placeDrawer,
      changeAmount.value,
      null,
      null
    );
    changePriceInDrawer(newPrice);
  }

  async function asyncGetOrder(orderId) {
    const response = await getOrder(orderId);
    return response.data;
  }

  async function openDrawerHandler(orderFromList) {
    setIsDrawerOpen(true);
    setIsLoadingDrawer(true);

    let orderPromise = asyncGetOrder(orderFromList.id);
    let order = await orderPromise;
    setOrderInDrawer(order);

    if (orderFromList.orderType.id === OrderTypeEnum.SELL || orderFromList.orderType.id === OrderTypeEnum.AUCTION) {
      const options = await asyncGetOrderAmounts(orderFromList.id);
      setAmountOptionsDrawer(options);

      const selectedValue = options[options.length - 1];
      setSelectedAmountDrawer(selectedValue);
    }

    if (orderFromList.orderType.id === OrderTypeEnum.BUY) {
      // list price is based on 20kg box, so default to this in drawer TODO: boxtype enum
      const defaultBoxType = boxtypeOptionsDrawer.find((boxtype) => boxtype.value === 4);
      calcBoxtypeDefaultsDrawer(order.amount, defaultBoxType, false);
    }

    setIsLoadingDrawer(false);
  }

  function resetDifferentAddressFieldsDrawer() {
    setAddressDrawer("");
    setSelectedCountryDrawer(null);
    setPostCodeDrawer("");
    setPlaceDrawer("");
    setValidateAddressMessage("");
    setNewTransportPriceDrawer("");
  }
  function resetBoxtypesDrawer() {
    setNrOfPalletsDrawer(0);
    setNrOfBoxesDrawer(0);
  }

  function onClickDifferentAddressCBDrawerHandler(event) {
    setIsAddressCBDrawerChecked(event.checked);
    setAbleToPurchase(!event.checked);
    // Reset textfields when un-checking CB to reduce risk mistakes in order
    if (!event.checked) resetDifferentAddressFieldsDrawer();
    setAbleToPurchase(!event.checked);
  }

  function changePriceInDrawer(newPrice) {
    if (newPrice && newPrice.status === "PriceFound") {
      setOrderInDrawer({
        ...orderInDrawer,
        priceWithFee: newPrice.price,
        orderDelivery: newPrice.delivery,
      });
      setNewTransportPriceDrawer(newPrice.price[orderInDrawer.orderCurrency].toString());
      setValidateAddressMessage("");
    } else {
      setNewTransportPriceDrawer("");
      setValidateAddressMessage(t("commonOrders.notValidRoute"));
    }
  }

  async function validateDeliveryFromDrawer() {
    if (orderInDrawer.orderType.id === OrderTypeEnum.SELL) {
      await validateDelivery(null, null);
    } else if (orderInDrawer.orderType.id === OrderTypeEnum.BUY) {
      await validateDelivery(selectedBoxtypeDrawer.value, nrOfBoxesDrawer);
    }
  }

  async function validateDelivery(boxType, nrOfBoxes) {
    setIsLoadingNewPriceDrawer(true);
    setAbleToPurchase(false);

    const inputChecker = validateFieldsHelper(differentAddressValidationSchema(t), {
      address: addressDrawer,
      postCode: postCodeDrawer,
      place: placeDrawer,
      country: selectedCountryDrawer,
    });
    setAddressValidationErrors(inputChecker);
    if (Object.keys(inputChecker).length === 0) {
      const newPrice = await asyncCalculateTransportPrice(
        orderInDrawer.id,
        postCodeDrawer,
        selectedCountryDrawer,
        placeDrawer,
        orderInDrawer.amount,
        nrOfBoxes,
        boxType
      );
      changePriceInDrawer(newPrice);
      setAddressValidationErrors({});
      if (newPrice.status === "PriceFound") {
        setAbleToPurchase(true);
      }
    } else if (Object.keys(inputChecker).length > 0) {
      setAbleToPurchase(false);
    }
    setIsLoadingNewPriceDrawer(false);
  }

  async function calcBoxtypeDefaultsDrawer(orderAmount, boxType, calculateNewTransportPrice) {
    setSelectedBoxtypeDrawer(boxType);
    const minBoxNr = calcMinAmountOfBoxes(orderAmount, boxType.maxWeight);
    setNrOfBoxesDrawer(minBoxNr);
    const nrOfPallets = calcNumberOfPallets(minBoxNr, boxType.numberOfBoxesOnAPallet);
    setNrOfPalletsDrawer(nrOfPallets);

    if (calculateNewTransportPrice) await validateDelivery(boxType.value, minBoxNr);
  }

  async function onChangeSelectedBoxtypeDrawer(selectedBoxType) {
    calcBoxtypeDefaultsDrawer(orderInDrawer.amount, selectedBoxType, true);
  }

  async function onChangeNrOfBoxesDrawer(nrOfBoxes) {
    setNrOfBoxesDrawer(nrOfBoxes);
    const nrOfPallets = calcNumberOfPallets(nrOfBoxes, selectedBoxtypeDrawer.numberOfBoxesOnAPallet);
    setNrOfPalletsDrawer(nrOfPallets);
    await validateDelivery(selectedBoxtypeDrawer.value, nrOfBoxes);
  }

  function onCloseDrawer() {
    setIsDrawerOpen(false);
    setIsAddressCBDrawerChecked(false);

    // Reset different address to prevent mistakes in order.
    resetDifferentAddressFieldsDrawer();
    resetBoxtypesDrawer();
    setAddressValidationErrors({});
  }

  async function purchaseOrSellFromDrawerHandler(order) {
    // If different address is relevant, we need to validate if we still deliver to chosen address (user can have changed input)
    if (
      (orderInDrawer.orderType.id === OrderTypeEnum.SELL || orderInDrawer.orderType.id === OrderTypeEnum.BUY) &&
      !currentCompany.withoutTransport
    ) {
      const amount =
        orderInDrawer.orderType.id === OrderTypeEnum.BUY ? orderInDrawer.amount : selectedAmountDrawer.value;
      const boxType = orderInDrawer.orderType.id === OrderTypeEnum.BUY ? selectedBoxtypeDrawer.value : null;
      const nrOfBoxes = orderInDrawer.orderType.id === OrderTypeEnum.BUY ? nrOfBoxesDrawer : null;

      const addressValidation = await asyncCalculateTransportPrice(
        orderInDrawer.id,
        postCodeDrawer,
        selectedCountryDrawer,
        placeDrawer,
        amount,
        nrOfBoxes,
        boxType
      );
      if (addressValidation.status === "NoPriceFound") {
        setValidateAddressMessage(t("commonOrders.notValidRoute"));
        return;
      }
    }

    if (order.orderType.id === OrderTypeEnum.AUCTION) return console.log("handle autobid");

    const copy = await asyncGetCreateOrderFromOrder(
      order.id,
      currentCompany && currentCompany.companyId,
      user && user.id,
      order.priceWithFee[order.orderCurrency]
    );

    if (copy.orderType === OrderTypeEnum.BUY && !selectedAmountDrawer) return;

    copy.companyId = currentCompany && currentCompany.companyId;
    copy.userId = user && user.id;
    if (copy.orderType === OrderTypeEnum.BUY) {
      //not allowed to buy if price > remaining credit for company
      const asyncGetCompanyCredit = async () => {
        const credit = await getCompanyCreditById(currentCompany.companyId);
        return credit.data;
      };
      const credit = await asyncGetCompanyCredit();
      const totalPrice = copy.price * selectedAmountDrawer.value;
      //Show warning if credit is too low, else continue as usual
      if (totalPrice > credit.remaining) {
        setIsCreditPopupOpen(true);
      } else {
        if (isAddressCBDrawerChecked) {
          copy.orderAddress = {
            address: addressDrawer,
            postCode: postCodeDrawer,
            place: placeDrawer,
            countryCodeIso3: selectedCountryDrawer.iso3Code,
            countryCodeIso2: selectedCountryDrawer.iso2Code,
            isEu: selectedCountryDrawer.isEu,
          };
        }
        copy.amount = selectedAmountDrawer.value;
        copy.minimumAmount = selectedAmountDrawer.value;
        setPurchaseOrSellFromDrawerModalConfig({
          ...purchaseOrSellFromDrawerModalConfig,
          isOpen: true,
          header: t("trade.confirmationheaderbuy"),
          text: t("trade.confirmationtextbuy"),
          text2: t("trade.confirmationtext2buy"),
          text3: t("trade.confirmationtext3buy"),
          amount: `${copy.amount}kg`,
          salesitem: `${t("common.fish." + copy.salesItem.value)}`,
          // Price can be different if new address if filled in
          price: `${isAddressCBDrawerChecked ? newTransportPriceDrawer : copy.price}`,
          currency: `${copy.orderCurrency.value}`,
          orderIdToCancel: order.id,
        });
      }
    } else {
      copy.packaging.boxtype = selectedBoxtypeDrawer.value;
      copy.packaging.numberOfBoxes = nrOfBoxesDrawer;
      setPurchaseOrSellFromDrawerModalConfig({
        ...purchaseOrSellFromDrawerModalConfig,
        isOpen: true,
        header: t("trade.confirmationheadersell"),
        text: t("trade.confirmationtextsell"),
        text2: t("trade.confirmationtext2sell"),
        text3: t("trade.confirmationtext3sell"),
        amount: `${copy.amount}kg`,
        salesitem: `${t("common.fish." + copy.salesItem.value)}`,
        price: `${copy.price}`,
        currency: `${copy.orderCurrency.value}`,
        orderIdToCancel: order.id,
      });
    }
    setOrderCopy(copy);
  }

  function closeAndCancelDrawer() {
    setIsDrawerOpen(false);
    setAddressValidationErrors({});
  }
  function confirmPurchaseSaleFromDrawer() {
    const order = orderCopy;

    asyncPostCreateOrder(order);
    closePurchasSale(false);
    setIsDrawerOpen(false);
  }

  function closePurchasSale(releaseOrderReservation) {
    setPurchaseOrSellFromDrawerModalConfig({
      ...purchaseOrSellFromDrawerModalConfig,
      isOpen: false,
    });
    if (releaseOrderReservation) asyncPostReleaseReservation(purchaseOrSellFromDrawerModalConfig.orderIdToCancel);
  }
  // ---- Open drawer and initialize content END ----

  return (
    <>
      <Helmet>
        <title>Reviver - {t("trade.fishmarket")}</title>
        <meta name="description" content="Reviver - {t('trade.fishmarket')}" />
      </Helmet>

      {/* TODO: Can maybe be separated into its own component at some point */}
      <Drawer isOpen={isDrawerOpen} onClose={() => onCloseDrawer()}>
        <Spinner loading={isLoadingDrawer} />
        {orderInDrawer && (
          <div className="full flex col">
            <div className="flex gap10 align pad20">
              <SVGAsset name={saleOrPurchase(orderInDrawer.orderType.id).tabSvgAsset} />
              <h3 className="no-margin">{saleOrPurchase(orderInDrawer.orderType.id).tabHeaderText.toUpperCase()}</h3>
            </div>
            <Tabs className="verify-customer-tabs">
              <Tab>
                <OrderTabContent order={orderInDrawer} isLoading={isLoadingNewPriceDrawer} />
              </Tab>
            </Tabs>
            <div className="button-row-overflow">
              <div className="button-row gap20 pad20">
                {/* bottom row for sales orders */}
                {orderInDrawer.orderType.id === OrderTypeEnum.SELL && (
                  <>
                    <div className="flex gap10">
                      <SVGAsset name={saleOrPurchase(orderInDrawer.orderType.id).tabActionSvgAsset} />
                      <p className="font-family-polysans">{saleOrPurchase(orderInDrawer.orderType.id).tabActionText}</p>
                    </div>
                    <Grid className="flex align mbottom10">
                      <Grid.Col span={1}>
                        <label className="font-family-polysans">{t("common.amount")}</label>
                      </Grid.Col>
                      <Grid.Col span={3}>
                        {selectedAmountDrawer && (
                          <Dropdown2
                            id="active-orders-sidepanel-amount"
                            direction={Dropdown2.direction.Up}
                            options={amountOptionsDrawer}
                            selected={selectedAmountDrawer.value}
                            onChange={(e) => onChangeSelectedOrderAmountDrawer(e)}
                          />
                        )}
                      </Grid.Col>
                      <Grid.Col span={4}>
                        {orderInDrawer.amountDeviation > 0 && (
                          <p className="font-family-polysans deviation-warning">
                            {t("commonOrders.amountDeviationWarning") +
                              " " +
                              calculateDeviation(orderInDrawer.amount, orderInDrawer.amountDeviation)}
                          </p>
                        )}
                      </Grid.Col>
                    </Grid>
                  </>
                )}

                {/* bottom row for purchase order */}
                {orderInDrawer.orderType.id === OrderTypeEnum.BUY && (
                  <Grid>
                    <Grid.Row>
                      <Grid.Col span={6}>
                        <Dropdown
                          id="drawer-boxtype"
                          label={t("common.boxtype")}
                          menuPlacement={Dropdown.menuPlacement.Top}
                          options={boxtypeOptionsDrawer}
                          value={selectedBoxtypeDrawer}
                          onChange={(e) => onChangeSelectedBoxtypeDrawer(e)}
                        />
                      </Grid.Col>
                      <Grid.Col span={6}>
                        <Textfield
                          id="drawer-boxamount"
                          label={t("common.noofboxes")}
                          type="number"
                          value={nrOfBoxesDrawer}
                          onChange={(e) => onChangeNrOfBoxesDrawer(e.target.value)}
                        />
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col span={12}>
                        <p className="font-family-polysans">{t("commonOrders.numberOfPallets") + nrOfPalletsDrawer}</p>
                      </Grid.Col>
                    </Grid.Row>
                  </Grid>
                )}

                {/* bottom row for auction orders */}
                {orderInDrawer.orderType.id === OrderTypeEnum.AUCTION && (
                  <Grid>
                    <Grid.Row>
                      <div className="flex gap10">
                        <SVGAsset name={saleOrPurchase(orderInDrawer.orderType.id).tabActionSvgAsset} />
                        <p>{saleOrPurchase(orderInDrawer.orderType.id).tabActionText}</p>
                      </div>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col span={4}>
                        <div className="flex align gap10">
                          <label>{t("common.amount")}</label>
                          <Dropdown2
                            id="active-orders-sidepanel-amount"
                            options={amountOptionsDrawer}
                            selected={selectedAmountDrawer.value}
                            onChange={(e) => setSelectedAmountDrawer(e)}
                          />
                        </div>
                      </Grid.Col>
                      <Grid.Col span={8}>
                        <div className="flex align gap10">
                          <label>{t("commonOrders.maxPrice")}</label>
                          <Textfield style={{ width: "100px" }} id="active-orders-sidepanel-maxprice" />
                          <label>{orderInDrawer.orderCurrency} pr kg.</label>
                        </div>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col span={12}>
                        <strong>Your autobid</strong>
                        <div className="flex gap20">
                          <SVGAsset name="circle-questionmark" />
                          <p>{t("commonOrders.yourAutobid")}</p>
                        </div>
                      </Grid.Col>
                    </Grid.Row>
                  </Grid>
                )}

                <Grid.Row>
                  <Grid.Col span={12}>
                    <Checkbox
                      label={t("commonOrders.differentAddress")}
                      checked={isAddressCBDrawerChecked}
                      onChange={(e) => onClickDifferentAddressCBDrawerHandler(e)}
                    />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col span={12}>
                    <CSSTransition
                      in={isAddressCBDrawerChecked}
                      timeout={{
                        enter: 1000,
                        exit: 300,
                      }}
                      mountOnEnter
                      unmountOnExit
                      classNames="fishmarked-address-transition-drawer"
                      nodeRef={addressDrawerNodeRef}
                    >
                      <div ref={addressDrawerNodeRef}>
                        <Grid>
                          <Grid.Col span={6}>
                            <Textfield
                              id="address-drawer"
                              label={t("commonOrders.orderAddress")}
                              value={addressDrawer}
                              onChange={(e) => setAddressDrawer(e.target.value)}
                              errormessage={addressValidationErrors.address}
                            />
                          </Grid.Col>
                          <Grid.Col span={6}>
                            <Dropdown
                              id="country-drawer"
                              label={t("common.country")}
                              options={countryOptionsDrawer}
                              onChange={(e) => setSelectedCountryDrawer(e)}
                              value={selectedCountryDrawer}
                              menuPlacement="top"
                              errormessage={addressValidationErrors.country}
                            />
                          </Grid.Col>
                          <Grid.Col span={6}>
                            <Textfield
                              id="postCode-drawer"
                              type="number"
                              label={t("commonOrders.postCode")}
                              value={postCodeDrawer}
                              onChange={(e) => setPostCodeDrawer(e.target.value)}
                              errormessage={addressValidationErrors.postCode}
                            />
                          </Grid.Col>
                          <Grid.Col span={6}>
                            <Textfield
                              id="place-drawer"
                              label={t("commonOrders.place")}
                              value={placeDrawer}
                              onChange={(e) => setPlaceDrawer(e.target.value)}
                              errormessage={addressValidationErrors.place}
                            />
                          </Grid.Col>
                          <Grid.Col span={3}>
                            <BlueButton
                              id="checkAddress"
                              text={t("commonOrders.calculatePrice")}
                              onClick={() => validateDeliveryFromDrawer()}
                            />
                          </Grid.Col>
                          <Grid.Col span={9}>
                            <Spinner loading={isLoadingNewPriceDrawer} inline />
                            {!isLoadingNewPriceDrawer && (
                              <p className="font-family-polysans">
                                <strong>{validateAddressMessage}</strong>
                              </p>
                            )}
                          </Grid.Col>
                        </Grid>
                      </div>
                    </CSSTransition>
                  </Grid.Col>
                </Grid.Row>

                <div className="flex align right gap20 pad20">
                  <BlueButton
                    id="active-orders-sidepanel-cancel"
                    buttontype={BlueButton.buttontype.OnlyText}
                    onClick={() => closeAndCancelDrawer()}
                    text={t("common.cancel")}
                  />
                  <BlueButton
                    id="active-orders-sidepanel-accept"
                    // disable button if different address is checked without address fields filled in
                    disabled={!ableToPurchase}
                    buttontype={saleOrPurchase(orderInDrawer.orderType.id).buttonType}
                    onClick={() => purchaseOrSellFromDrawerHandler(orderInDrawer)}
                    text={saleOrPurchase(orderInDrawer.orderType.id).buttonText}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Drawer>

      {/* -------Confirm purchase or sale from drawer Modal------- */}
      <ModalDialog
        open={purchaseOrSellFromDrawerModalConfig.isOpen}
        headerText={purchaseOrSellFromDrawerModalConfig.header}
        onClose={() => closePurchasSale(true)}
      >
        <p className="pad30">
          {purchaseOrSellFromDrawerModalConfig.text} <strong>{purchaseOrSellFromDrawerModalConfig.amount}</strong>{" "}
          {purchaseOrSellFromDrawerModalConfig.salesitem} {purchaseOrSellFromDrawerModalConfig.text2}{" "}
          <strong>
            {parseFloat(purchaseOrSellFromDrawerModalConfig.price).toFixed(2)}{" "}
            {purchaseOrSellFromDrawerModalConfig.currency}/kg
          </strong>{" "}
          <br /> {purchaseOrSellFromDrawerModalConfig.text3}
        </p>

        <ModalDialogButtonsWrapper>
          <BlueButton
            id="create-order-cancel"
            buttontype={BlueButton.buttontype.OnlyText}
            onClick={() => closePurchasSale(true)}
            text={t("common.cancel")}
          />
          <BlueButton
            id="create-order-confirm"
            onClick={() => confirmPurchaseSaleFromDrawer()}
            text={t("common.accept")}
          />
        </ModalDialogButtonsWrapper>
      </ModalDialog>

      <div className="fishmarked-container">
        {/* -------Select what order type to create Modal------- */}
        <ModalDialog open={isChooseOrderTypeOpen} onClose={() => setIsChooseOrderTypeOpen(false)}>
          <div className="select-order-type">
            <p>
              <strong>{t("commonOrders.purchaseOrSell").toUpperCase()}</strong>
            </p>
            <div className="select-order-type-body">
              <div
                id="create-sales-order"
                className="clickable create-order-type-button"
                onClick={() => openCreateOrderModal(OrderTypeEnum.SELL)}
              >
                <SVGAsset className="mbottom10" name="salesIndicatorLarge" />
                <p>{t("common.sell").toUpperCase()}</p>
              </div>
              <div
                id="create-purchase-order"
                className="clickable create-order-type-button"
                onClick={() => openCreateOrderModal(OrderTypeEnum.BUY)}
              >
                <SVGAsset className="mbottom10" name="purchaseIndicatorLarge" />
                <p>{t("commonOrders.purchase").toUpperCase()}</p>
              </div>
              {/* Fjernet frem til auksjon blir implementert */}
              <div
                id="create-auction-order"
                className="clickable create-order-type-button"
                onClick={() => openCreateOrderModal(OrderTypeEnum.AUCTION)}
              >
                <SVGAsset className="mbottom10" name="auction-blue" />
                <p>{t("commonOrders.auction").toUpperCase()}</p>
              </div>
            </div>
          </div>
        </ModalDialog>

        {/* -------Select company on login Modal------- */}
        <ModalDialog open={isCompanyChoiceOpen} isCloseVisible={false}>
          <div className="pad30">
            <p className="font-family-polysans">{t("common.chooseCompany")}</p>
            <Dropdown2 id="user-options" options={companyChoiceOptions} onChange={companyOptionsChangedHandler} />
          </div>
        </ModalDialog>

        {/* -------Credit warning Modal------- */}
        <ModalDialog
          open={isCreditPopupOpen}
          headerText={t("trade.creditTooLow")}
          onClose={() => setIsCreditPopupOpen(false)}
        >
          <p className="pad30">{t("trade.currentCreditTooLow")}</p>
          <ModalDialogButtonsWrapper>
            <BlueButton id="credit-ok" onClick={() => setIsCreditPopupOpen(false)} text={"Ok"} />
          </ModalDialogButtonsWrapper>
        </ModalDialog>

        {/* CREATE SALES ORDER */}
        <CreateOrderModal
          open={isCreateSalesOrderModalOpen}
          onClose={closeCreateOrderModal}
          svg={saleOrPurchase(createOrderValues.orderType).svgAssetWhite}
          headerText={saleOrPurchase(createOrderValues.orderType).tabHeaderText.toUpperCase()}
        >
          <div>
            <div className="flex">
              <TabsWiz>
                <TabWiz
                  isActive={salesSteps[0].isActive}
                  isDone={salesSteps[0].isDone}
                  stepTitle={t("common.type").toUpperCase()}
                >
                  <OrderSellStep1
                    onChange={onChangeCreateOrderCB}
                    values={createOrderValues}
                    orderTemplate={orderTemplate}
                    setOrderTemplate={setOrderTemplate}
                    validationErrors={validationErrors}
                    fieldValidationErrors={createOrderValidationError}
                    setFieldValidationErrors={setCreateOrderValidationError}
                  />
                </TabWiz>
                <TabWiz
                  isActive={salesSteps[1].isActive}
                  isDone={salesSteps[1].isDone}
                  stepTitle={t("commonOrders.catchAndDelivery").toUpperCase()}
                  hasBorder={true}
                >
                  <OrderSellStep2
                    onChange={onChangeCreateOrderCB}
                    values={createOrderValues}
                    fieldValidationErrors={createOrderValidationError}
                    setFieldValidationErrors={setCreateOrderValidationError}
                    fieldValidationAddressErrors={createOrderAddressValidationError}
                    setFieldValidationAddressErrors={setCreateOrderAddressValidationError}
                    validateDifferentAddress={validateDifferentAddressInCreateOrder}
                    isDifferentAddressChecked={isDifferentAddressInCreateOrderChecked}
                    setIsDifferentAddressChecked={setIsDifferentAddressInCreateOrderChecked}
                    differentAddressValidationMessage={differentAddressValidationMessage}
                    setDifferentAddressValidationMessage={setDifferentAddressValidationMessage}
                  />
                </TabWiz>
                <TabWiz
                  isActive={salesSteps[2].isActive}
                  isDone={salesSteps[2].isDone}
                  stepTitle={t("common.packaging").toUpperCase()}
                  hasBorder={true}
                >
                  <OrderSellStep3
                    onChange={onChangeCreateOrderCB}
                    values={createOrderValues}
                    fieldValidationErrors={createOrderValidationError}
                    setFieldValidationErrors={setCreateOrderValidationError}
                  />
                </TabWiz>
              </TabsWiz>
              <InformationStep orderValues={createOrderValues} />
            </div>
            <div className="flex gap10 right create-order-step-button-row">
              <BlueButton
                id="cancel-create-sales-order"
                text={t("common.cancel")}
                buttontype={BlueButton.buttontype.OnlyText}
                onClick={() => closeCreateOrderModal()}
              />
              {currentSalesStep > 0 && (
                <BlueButton id="back-create-sales-order" text={t("common.back")} onClick={clickBackSalesStepHandler} />
              )}
              <BlueButton
                id="next-create-sales-order"
                text={currentSalesStep !== 2 ? t("common.next") : t("commonOrders.createOrder")}
                onClick={
                  currentSalesStep !== 2
                    ? () => clickNextSalesStepHandler()
                    : () => createOrderFromWizardHandler(createOrderValues.orderType)
                }
              />
            </div>
          </div>
        </CreateOrderModal>

        {/* CREATE PURCHASE ORDER */}
        <CreateOrderModal
          open={isCreatePurchaseOrderModalOpen}
          onClose={() => closeCreateOrderModal()}
          svg={saleOrPurchase(createOrderValues.orderType).svgAssetWhite}
          headerText={saleOrPurchase(createOrderValues.orderType).tabHeaderText.toUpperCase()}
        >
          <div>
            <div className="flex">
              <TabsWiz>
                <TabWiz
                  isActive={purchaseSteps[0].isActive}
                  isDone={purchaseSteps[0].isDone}
                  stepTitle={t("common.type").toUpperCase()}
                >
                  <OrderBuyStep1
                    onChange={onChangeCreateOrderCB}
                    values={createOrderValues}
                    orderTemplate={orderTemplate}
                    setOrderTemplate={setOrderTemplate}
                    validationErrors={validationErrors}
                    fieldValidationErrors={createOrderValidationError}
                    setFieldValidationErrors={setCreateOrderValidationError}
                  />
                </TabWiz>
                <TabWiz
                  isActive={purchaseSteps[1].isActive}
                  isDone={purchaseSteps[1].isDone}
                  stepTitle={t("commonOrders.catchAndDelivery").toUpperCase()}
                  hasBorder={true}
                >
                  <OrderBuyStep2
                    onChange={onChangeCreateOrderCB}
                    values={createOrderValues}
                    fieldValidationErrors={createOrderValidationError}
                    setFieldValidationErrors={setCreateOrderValidationError}
                    fieldValidationAddressErrors={createOrderAddressValidationError}
                    setFieldValidationAddressErrors={setCreateOrderAddressValidationError}
                    validateDifferentAddress={validateDifferentAddressInCreateOrder}
                    isDifferentAddressChecked={isDifferentAddressInCreateOrderChecked}
                    setIsDifferentAddressChecked={setIsDifferentAddressInCreateOrderChecked}
                    differentAddressValidationMessage={differentAddressValidationMessage}
                    setDifferentAddressValidationMessage={setDifferentAddressValidationMessage}
                  />
                </TabWiz>
              </TabsWiz>
              <InformationStep orderValues={createOrderValues} />
            </div>
            <div className="flex gap10 right create-order-step-button-row">
              <BlueButton
                id="cancel-create-purhcase-order"
                text={t("common.cancel")}
                buttontype={BlueButton.buttontype.OnlyText}
                onClick={() => closeCreateOrderModal()}
              />
              {currentPurchaseStep > 0 && (
                <BlueButton
                  id="back-create-purhcase-order"
                  text={t("common.back")}
                  onClick={clickBackPurchaseStepHandler}
                />
              )}
              <BlueButton
                id="next-create-purchase-order"
                text={currentPurchaseStep !== 1 ? t("common.next") : t("commonOrders.createOrder")}
                onClick={
                  currentPurchaseStep !== 1
                    ? () => clickNextPurchaseStepHandler()
                    : () => createOrderFromWizardHandler(createOrderValues.orderType)
                }
              />
            </div>
          </div>
        </CreateOrderModal>

        <Grid className="nogap">
          <Grid.Row className="active-orders-container">
            <Grid.Col span={12}>
              <CompanyActiveOrders
                orders={orders}
                createNew={() => openChooseOrderType()}
                currentCompany={currentCompany}
                deleteOrder={asyncDeleteOrder}
                filterTableCB={filterTableFromActiveOrdersCB}
                refreshActiveOrdersCB={asyncUpdateActiveOrders}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row className="filter-result">
            <Grid.Col span={2}>
              {isMobileView && (
                <CollapsibleFilter title={"FILTERE"}>
                  <Filters filters={filterData} filter={filter} setFilter={setFilter} />
                </CollapsibleFilter>
              )}
              {!isMobileView && (
                <>
                  <h3>{t("trade.filters")}</h3>
                  <Filters filters={filterData} filter={filter} setFilter={setFilter} />
                </>
              )}
            </Grid.Col>
            <Grid.Col span={10} className="result-table">
              <div>
                <div className="flex search-counter">
                  <h3>
                    <span>
                      {tableData.length} {t("trade.hits")}
                    </span>
                  </h3>
                  <Spinner loading={loadingFilteredOrders} inline />
                </div>

                {tableData.length === 0 && Object.keys(filter).length > 1 && (
                  <div className="empty-search-content">
                    <h2>{t("trade.searchNoHits")}</h2>
                    <SVGAsset name="arrow-up-down" />
                    <p>{t("trade.newOrderQuestion")}</p>
                    <BlueButton
                      id={`btn-fishmarked-empty-search`}
                      text={t("commonOrders.newOrder")}
                      onClick={() => openCreateOrderModalEmptySearch()}
                      buttonsize={BlueButton.buttonsize.Small}
                    />
                  </div>
                )}

                {tableData.length > 0 && (
                  <table className="compact">
                    <thead>
                      <tr>
                        <th scope="col" className="arrow">
                          {" "}
                        </th>
                        <th scope="col">{t("common.date")}</th>
                        <th scope="col" colSpan={2}>
                          {t("common.company")}
                        </th>
                        <th scope="col">{t("common.type")}</th>
                        <th scope="col">{t("common.condition")}</th>
                        <th scope="col" colSpan={2}>
                          {t("common.amount")}
                        </th>
                        <th scope="col">{t("common.treatment")}</th>
                        <th scope="col">{t("common.size")}</th>
                        <th scope="col" colSpan={2}>
                          {t("commonOrders.priceInclFee")}
                        </th>
                        <th scope="col">{t("commonOrders.inclTransportation")}</th>
                        <th scope="col">&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((order, index) => {
                        const promoted = order.promoted ? "promoted" : "";
                        const price = order.priceWithFee[currentCompany.companyCurrency];
                        return (
                          <tr
                            id={`order-row-${index}`}
                            key={`order-row-${index}`}
                            className={`clickable ${promoted}`}
                            onClick={() => openDrawerHandler(order)}
                          >
                            <td data-label=" " className="arrow">
                              <SVGAsset name={saleOrPurchase(order.orderType.id).svgAsset} />
                            </td>
                            <td data-label={t("common.date")}>
                              <Moment format="DD.MM.YYYY">{order.created}</Moment>
                            </td>
                            <td data-label={t("common.company")} colSpan={2}>
                              {order.sellerName}
                            </td>
                            <td data-label={t("common.type")}>{t("common.fish." + order.salesItem.salesItem)}</td>
                            <td data-label={t("common.condition")}>
                              {t("common.conditions." + order.conditionDto.condition)}
                            </td>
                            <td data-label={t("common.amount")} colSpan={2}>
                              {order.amountDeviation > 0
                                ? order.remainingAmount + " " + order.unit.unit + " +/- " + order.amountDeviation + "%"
                                : order.remainingAmount + " " + order.unit.unit}
                            </td>
                            <td data-label={t("common.treatment")}>
                              {t("common.treatments." + get(order, "treatment.treatment", ""))}
                            </td>
                            <td data-label={t("common.size")}>{order.size.size}</td>
                            <td className="price-fixed" data-label={t("commonOrders.priceInclFee")} colSpan={2}>
                              {price.toFixed(2)} {currentCompany.companyCurrency}/{order.priceUnit.unit}
                              <span className="price-actual">
                                {price} {currentCompany.companyCurrency}/{order.priceUnit.unit}
                              </span>
                            </td>
                            <td data-label={t("commonOrders.inclTransportation")}>
                              <Checkbox
                                id="fixTransportCB"
                                checked={!currentCompany.withoutTransport}
                                disabled={true}
                              />
                            </td>
                            <td>
                              <BlueButton
                                id={`btn-fishmarked-${index}`}
                                buttontype={saleOrPurchase(order.orderType.id).buttonType}
                                text={saleOrPurchase(order.orderType.id).buttonText}
                                onClick={() => openDrawerHandler(order)}
                                buttonsize={BlueButton.buttonsize.Small}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={12} className="flex right result-piginator">
              <Pagination
                numberOfPages={paginationInfo.numberOfPages}
                neighbours={2}
                onClick={changePageHandler}
                reset={currentCompany}
              />
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </div>
    </>
  );
}
