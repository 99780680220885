import React from "react";
import PropTypes from "prop-types";

import "./MessageBar.css";

const type = {
  Positive: "Positive",
  Warning: "Warning",
  Negative: "Negative",
  Exception: "Exception",
};

MessageBar.type = type;

function getIcon(_type) {
  if (_type === type.Positive) {
    return "👍";
  } else if (_type === type.Warning) {
    return "⚠️";
  } else if (_type === type.Negative) {
    return "⚡";
  }
  return null;
}

export default function MessageBar(props) {
  const type = (props.type || MessageBar.type.Positive).toLowerCase();
  const classNames = props.className || "";
  const icon = props.noIcon ? null : getIcon(props.type);
  return (
    <div className={`message-bar ${type} ${classNames} font-family-polysans`}>
      {icon && (
        <span className="icon-circle">
          <span className="icon">{icon}</span>
        </span>
      )}
      {props.message}
    </div>
  );
}

MessageBar.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf([...Object.keys(type)]),
};
