// schema comes from schemas made from Joi, our validation package
// these schemas are created in the containers/components that use validation

// values are the values that will be matched up against the schema

export function validateFieldsHelper(schema, values) {
  if (!schema || !values) return;

  const validationResult = schema.validate(values, { abortEarly: false });
  const errorData = {};

  if (validationResult.error) {
    validationResult.error.details.forEach((error) => {
      const propName = error.path[0];
      const message = error.message;
      errorData[propName] = message;
    });
  }

  return errorData;
}

// can use the 'name' attribute on html element to set/get this value
// the propname and the matching schema property needs to match (same name)

export function validateFieldHelper(schema, propName, propValue, errorState) {
  if (!propName || !schema || !errorState) return;

  const subSchema = schema.extract(propName);
  const validationResult = subSchema.validate(propValue);

  let errorData = { ...errorState };
  if (validationResult.error) errorData[propName] = validationResult.error.details[0].message;
  else delete errorData[propName];

  return errorData;
}
