import React, { useRef, useEffect } from "react";

import { CSSTransition } from "react-transition-group";

import SVGAsset from "../SVGAsset/SVGAsset";

import "./Drawer.css";

export default function Drawer(props) {
  const { isOpen, onClose } = props;
  const thisRef = useRef(null); // Panel element

  //  Event handler for closing drawer when clicking outside it
  useEffect(() => {
    function clickOutsideHandler(event) {
      //  Do not close the drawer if a modal is open
      if (thisRef.current && !thisRef.current.contains(event.target)) {
        const isParentModal = event.path.some((e) => e.id === "modal-dialog");
        if (isParentModal) return;

        onClose();
      }
    }
    document.addEventListener("mousedown", clickOutsideHandler);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", clickOutsideHandler);
    };
  }, [onClose]);

  return (
    <>
      <CSSTransition
        in={isOpen}
        timeout={500}
        mountOnEnter
        unmountOnExit
        classNames="drawer-transition"
        nodeRef={thisRef}
      >
        <div className={`drawer`} ref={thisRef}>
          <div className="main">{props.children}</div>

          <section className="close" id="close-drawer">
            <SVGAsset className="close-x clickable" name="close" onClick={() => onClose()} />
          </section>
        </div>
      </CSSTransition>
    </>
  );
}
