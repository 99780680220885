import Joi from "joi";

export function updateOrderSchemaSalesOrder(translation) {
  const t = translation;

  return Joi.object({
    price: Joi.number()
      .unsafe()
      .positive()
      .required()
      .messages({
        "number.base": t("validation.priceRequired"),
        "number.empty": t("validation.priceRequired"),
        "number.positive": t("validation.priceRequired"),
        "number.unsafe": t("validation.priceRequired"),
      }),
    minPrice: Joi.number()
      .unsafe()
      .when("price", {
        is: Joi.number().required(),
        then: Joi.number()
          .unsafe()
          .positive()
          .allow(null, 0)
          .max(Joi.ref("price"))
          .messages({
            "number.positive": t("validation.minPricePositive"),
            "number.max": t("validation.minPriceMax"),
          }),
      }),
    amount: Joi.number()
      .unsafe()
      .options({ convert: false })
      .precision(2)
      .max(1000000000)
      .positive()
      .required()
      .messages({
        "number.base": t("validation.amountRequired"),
        "number.empty": t("validation.amountRequired"),
        "number.positive": t("validation.amountMustBePositive"),
        "number.max": t("validation.maxAmount"),
        "number.precision": t("validation.amountPrecision"),
      }),
    minimumAmount: Joi.number().when("amount", {
      is: Joi.number().required(),
      then: Joi.number()
        .unsafe()
        .max(Joi.ref("amount"))
        .options({ convert: false })
        .precision(2)
        .allow(null, 0)
        .positive()
        .messages({
          "number.precision": t("validation.minimumAmountPrecision"),
          "number.positive": t("validation.minimumAmountMustBePositive"),
          "number.max": t("validation.amountGreaterThanMinimumAmount"),
        }),
    }),
    numberOfBoxes: Joi.number()
      .greater(0)
      .max(100000000)
      .integer()
      .required()
      .messages({
        "number.base": t("validation.numberOfBoxesRequired"),
        "number.greater": t("validation.numberOfBoxesRequired"),
        "number.integer": t("validation.numberOfBoxesInteger"),
        "number.max": t("validation.maxNumberOfBoxes"),
      }),
    //Date.now added to check that date is greater than or equal to today
    validFrom: Joi.date()
      .iso()
      .greater(Date.now() - 24 * 60 * 60 * 1000)
      .required()
      .messages({
        "date.base": t("validation.dateRequired"),
        "date.greater": t("validation.greaterThanNowValidFromDate"),
      }),
    validTo: Joi.date()
      .iso()
      .required()
      .greater(Joi.ref("validFrom"))
      .messages({
        "date.base": t("validation.dateRequired"),
        "date.greater": t("validation.greaterValidToDate"),
      }),
  }).unknown();
}

export function updateOrderSchemaBuyOrder(translation) {
  const t = translation;

  return Joi.object({
    price: Joi.number()
      .unsafe()
      .positive()
      .required()
      .messages({
        "number.base": t("validation.priceRequired"),
        "number.positive": t("validation.priceRequired"),
        "number.empty": t("validation.priceRequired"),
        "number.unsafe": t("validation.priceRequired"),
      }),
    maxPrice: Joi.number()
      .unsafe()
      .when("price", {
        is: Joi.number().required(),
        then: Joi.number()
          .unsafe()
          .positive()
          .allow(null, 0)
          .greater(Joi.ref("price"))
          .messages({
            "number.base": t("validation.maxPriceMinimum"),
            "number.positive": t("validation.maxPricePositive"),
            "number.greater": t("validation.maxPriceMinimum"),
            "number.ref": t("validation.maxPriceMinimum"),
          }),
      }),
    amount: Joi.number()
      .unsafe()
      .options({ convert: false })
      .precision(2)
      .max(1000000000)
      .positive()
      .required()
      .messages({
        "number.base": t("validation.amountRequired"),
        "number.empty": t("validation.amountRequired"),
        "number.positive": t("validation.amountMustBePositive"),
        "number.max": t("validation.maxAmount"),
        "number.precision": t("validation.amountPrecision"),
        "number.min": t("validation.amountGreaterThanMinimumAmount"),
      }),
    //Date.now added to check that date is greater than or equal to today
    validFrom: Joi.date()
      .iso()
      .greater(Date.now() - 24 * 60 * 60 * 1000)
      .required()
      .messages({
        "date.base": t("validation.dateRequired"),
        "date.greater": t("validation.greaterThanNowValidFromDate"),
      }),
    validTo: Joi.date()
      .iso()
      .required()
      .greater(Joi.ref("validFrom"))
      .messages({
        "date.base": t("validation.dateRequired"),
        "date.greater": t("validation.greaterValidToDate"),
      }),
  }).unknown();
}
