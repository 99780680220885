import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { getCompanyNetwork, postSaveCompanyNetwork, getAllCompanies } from "../../../services/Company";

import UserContext from "../../../contexts/UserContext";
import DropdownMulti from "../../../components/DropdownMulti/DropdownMulti";
import Button from "../../../components/BlueButton/BlueButton";
import Grid from "../../../components/Grid/Grid";

export default function NetworkSettings() {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const { currentCompany } = userContext;
  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState(null);

  useEffect(() => {
    async function loadOptions() {
      async function asyncGetAllCompanies() {
        const allCompanies = await getAllCompanies();
        return allCompanies.data;
      }

      const allCompanies = await asyncGetAllCompanies();

      //  Company options
      console.log(allCompanies);
      const _companyOptions = allCompanies.companies.map((company) => ({
        value: company.id,
        label: company.companyName,
        vatNo: company.vatNumber,
      }));
      setCompanyOptions(_companyOptions);
    }

    loadOptions();
  }, []);

  useEffect(() => {
    // Prefill selected companies if company has an existing network
    async function preFillSelectedCompanies() {
      async function asyncGetCompanyNetwork() {
        const companies = await getCompanyNetwork(currentCompany.companyId);
        return companies.data;
      }

      const existingNetwork = await asyncGetCompanyNetwork();

      const _selectedCompanies = existingNetwork.map((company) => ({
        value: company.companyId,
        label: company.companyName,
        vatNo: company.vatno,
      }));

      setSelectedCompanies(_selectedCompanies);
    }

    preFillSelectedCompanies();
  }, [currentCompany.companyId]);

  async function saveHandler() {
    // Map dropdown propNames to api propNames
    const _selectedCompanies = selectedCompanies.map((company) => ({
      companyId: company.value,
      companyName: company.label,
      vatno: company.vatNo,
    }));

    await postSaveCompanyNetwork(currentCompany.companyId, _selectedCompanies);
  }

  return (
    <Grid className="mtop20">
      <Grid.Row>
        <Grid.Col span={12}>
          <DropdownMulti
            id="companies"
            label={t("settings.networks")}
            value={selectedCompanies}
            options={companyOptions}
            onChange={(e) => setSelectedCompanies(e)}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col span={3} className="vbottom">
          <Button id="save" text={t("common.save")} onClick={() => saveHandler()} />
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
}
