import React from "react";
import { useTranslation } from "react-i18next";
import { get } from "lodash";

import "./ApplicationTabContent.css";

export default function TeamTabContent(props) {
  const { t } = useTranslation();
  const company = props.company;
  const teamMembers = get(company, "partialCompany.teamMembers", []);

  return (
    <div className="app-tab-container">
      <div className="app-tab-section">
        <table className="secondary">
          <thead>
            <tr>
              <th scope="col" colSpan="2">
                {t("common.user")}
              </th>
              <th scope="col">{t("common.role")}</th>
            </tr>
          </thead>

          <tbody>
            {teamMembers.map((member, index, array) => (
              <tr key={`table-${index}`}>
                <td data-label={t("common.user")}>
                  {member.firstname} {member.lastname}
                </td>
                <td className="break-word">{member.email}</td>
                <td data-label={t("common.role")}>{member.roleType}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
