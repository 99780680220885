import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

import "./TextField.css";

function textField(props, ref) {
  const textFieldClassName = props.textFieldClassName || "";
  return (
    <div className={`textfield ${textFieldClassName}`} id={props.id}>
      <label>{props.label}</label>
      <input type={props.type ? props.type : "text"} {...props} ref={ref} id={props.id ? props.id + "-input" : null} />
      <ErrorMessage errormessage={props.errormessage} />
    </div>
  );
}

const forwardedTextField = forwardRef(textField);

textField.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  errormessage: PropTypes.string,
  focused: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default forwardedTextField;
