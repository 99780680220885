const TaskTypeEnum = {
  FEEDBACK: 1,
  FINAL_AMOUNT: 2,
  AUCTION_END_PAYMENT: 3,
  AUCTION_END_TRANSPORT: 4,

  FEEDBACK_STR: "FEEDBACK",
  FINAL_AMOUNT_STR: "FINAL_AMOUNT",
  AUCTION_END_PAYMENT_STR: "AUCTION_END_PAYMENT",
  AUCTION_END_TRANSPORT_STR: "AUCTION_END_TRANSPORT",
};

Object.freeze(TaskTypeEnum);

export default TaskTypeEnum;
