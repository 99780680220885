import React, { useEffect, useState } from "react";

import { getStaleTransactions } from "../../services/Orders";
import { useTranslation } from "react-i18next";

import Moment from "react-moment";
import Spinner from "../../components/Spinner/Spinner";

import "./StaleTransactions.css";

export default function StaleTransactions() {
  const { t } = useTranslation();

  const [staleTransactions, setStaleTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function asyncInitiateStaleTransactions() {
      setIsLoading(true);
      const staleTrans = await getStaleTransactions();
      setStaleTransactions(staleTrans.data);
      console.log(staleTrans.data);
      setIsLoading(false);
    }

    asyncInitiateStaleTransactions();
  }, []);

  return (
    <>
      <Spinner loading={isLoading} inline={true} />
      <div className="stale-transactions-container flex">
        <table className="compact break-word">
          <thead>
            <tr>
              <th scope="col">{t("staleTransactions.dateTime")}</th>
              <th scope="col">Id</th>
              <th scope="col">{t("dashboard.status")}</th>
              <th scope="col">{t("staleTransactions.salesItem")}</th>
              <th scope="col">{t("common.amount")}</th>
              <th scope="col">{t("common.price")}</th>
              <th scope="col">{t("commonOrders.buyer")}</th>
              <th scope="col">{t("commonOrders.seller")}</th>
            </tr>
          </thead>
          <tbody>
            {staleTransactions.map((trans, index) => (
              <tr id={`trans-row-${index}`} key={`table-${index}`}>
                <td data-label={t("staleTransactions.dateTime")}>
                  <Moment format="DD.MM.YYYY hh:mm">{trans.transactionDate}</Moment>
                </td>
                <td data-label="Id">{trans.id}</td>
                <td data-label={t("dashboard.status")}>
                  {t("common.status." + trans.status.toUpperCase()).toUpperCase()}
                </td>
                <td data-label={t("staleTransactions.salesItem")}>
                  {t("common.fish." + trans.buyOrder.salesItem.salesItem)}
                </td>
                <td data-label={t("common.amount")}>
                  {trans.amount} {trans.buyOrder.unit.unit}
                </td>
                <td data-label={t("common.price")}>
                  {trans.buyOrder.priceWithFee[trans.buyOrder.orderCurrency]} {trans.buyOrder.orderCurrency}
                </td>
                <td data-label={t("commonOrders.buyer")}>{trans.buyerCompany.companyName}</td>
                <td data-label={t("commonOrders.seller")}>{trans.sellerCompany.companyName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
