import React, { Suspense, useEffect } from "react";
import moment from "moment-timezone";
import { Route, Switch, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { IS_DEV } from "./services/ApiConfig";
import { get } from "lodash";
import { setupRefreshTokenInterceptor } from "./services/Interceptors";
import { Provider as PanelContextProvider } from "./contexts/PanelContext";
import { getUserById, getCompaniesByUserId, logOut as doLogOut, getLogoutUrl } from "./services/Login";

import Dashboard from "./containers/Dashboard/Dashboard";
import Login from "./containers/Login/Login";
import useToken from "./components/CustomHooks/useToken";
import DebugTools from "./containers/DebugTools/DebugTools";
import RegisterCompanyWizard from "./containers/RegisterCompanyWizard/RegisterCompanyWizard";
import LoginCheckEmail from "./containers/Login/LoginCheckEmail";
import ExternalAuthSession from "./containers/Login/ExternalAuthSession";
import useRedirection from "./components/CustomHooks/useRedirection";
import RegisterNewUser from "./containers/RegisterUser/RegisterNewUser";
import RegisterNewUserCompleted from "./containers/RegisterUser/RegisterNewUserCompleted";
import CreatePassword from "./containers/RegisterUser/CreatePassword";
import VerifyCustomer from "./containers/VerifyCustomer/VerifyCustomer";
import UserContext from "./contexts/UserContext";
import SidePanel from "./components/SidePanel/SidePanel";
import Trade from "./containers/AllActiveOrders/Trade";
import Statistics from "./containers/Statistics/Statistics";
import Settings from "./containers/Settings/Settings";
import Checkout from "./containers/Checkout/Checkout";
import useUserContext, { getUserContextIds } from "./components/CustomHooks/useUserContext";
import applyIntercom from "./utils/Intercom";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import StaleTransactions from "./containers/StaleTransactions/StaleTransactions";
import PrivacyPolicy from "./containers/Privacy/PrivacyPolicy";
import AdminFeedback from "./containers/AdminFeedback/AdminFeedback";
// import Frontpage from './containers/Frontpage/Frontpage'
import Tasks from "./containers/Tasks/Tasks";

import { AdminHeader, HeadersContainer, MainHeader } from "./components/_Reviver/organisms/Header";

import "./App.css";
import "./Grid.css";
import "./ResponsiveLayout.css";

function changeLocale(locale, i18n, timeZone) {
  moment.updateLocale(locale);
  moment.tz.setDefault(timeZone);
  i18n.changeLanguage(locale);
  sessionStorage.setItem("lng", locale);
  console.log("Changed locale", locale);
  console.log("Changed timeZone", timeZone);
}

function Spinner() {
  return <div className="spinner">Loading...</div>;
}

function App() {
  const { token, setToken } = useToken();
  const { i18n } = useTranslation();
  const { redirection, setRedirection } = useRedirection();
  const { userContext, setUserContext } = useUserContext();
  const ids = getUserContextIds() || {};
  const location = useLocation();
  const activePage = location.pathname.split("/")[1];

  const clearSession = () => {
    setToken(null);
    setRedirection(null);
    setUserContext(null);
  };
  setupRefreshTokenInterceptor(clearSession);

  const logOut = async () => {
    const port = IS_DEV ? window.location.host.split(":")[1] || "80" : null;

    if (!token.includes("Basic")) {
      try {
        const response = await getLogoutUrl(port);
        const auth0Logout = response.data;
        const url = auth0Logout.url;
        await doLogOut();
        clearSession();
        window.location = url;
      } catch (error) {
        console.log("Feil: Kunne ikke logge ut", error);
      }
    }
    clearSession();
  };

  function setLocaleOnLogin(user, timeZone) {
    if (user.language && user.language.languageCode) {
      changeLocale(user.language.languageCode, i18n, timeZone);
    }
  }

  useEffect(() => {
    async function fetchUser() {
      try {
        let response = await getUserById(ids.userId);
        const user = response.data;
        let companies, currentCompany;
        if (ids.companyId) {
          response = await getCompaniesByUserId(ids.userId, ids.companyId);
          companies = response.data;
          currentCompany =
            companies.companies.find((company) => company.companyId === ids.companyId) || companies.companies[0];
        }
        setUserContext({
          ...userContext,
          user,
          companies,
          currentCompany,
        });
        setLocaleOnLogin(user, currentCompany && currentCompany.companyTimeZone);
      } catch (e) {
        console.log("Could not fetch logged in user: ", e);
        logOut();
      }
    }

    if (token && ids.userId && !userContext) {
      fetchUser();
    }

    if (token && userContext) {
      const { user } = userContext;
      applyIntercom(user);
    } else {
      applyIntercom(null);
    }
  });

  // console.log("Token: ", token);
  // console.log("Redirection: ", redirection);
  // console.log("User context: ", userContext);
  // console.log("activePage: ", activePage);

  if (!token && !redirection && activePage === "verifyEmail") {
    return <CreatePassword setRedirection={setRedirection} setUserContext={setUserContext} />;
  } else if (!token && !redirection) {
    return (
      <Switch>
        <Route path="/" exact>
          <LoginCheckEmail setRedirection={setRedirection} setUserContext={setUserContext} />
        </Route>
        {/* <Route path="/frontPage123">
          <Frontpage />
        </Route> */}
        <Route path="/login">
          <LoginCheckEmail setRedirection={setRedirection} setUserContext={setUserContext} />
        </Route>
        <Route path="/privacy">
          <PrivacyPolicy />
        </Route>
      </Switch>
    );
  } else if (!token && redirection === "CHECK_EMAIL") {
    return <LoginCheckEmail setRedirection={setRedirection} setUserContext={setUserContext} />;
  } else if (!token && redirection === "INTERNAL") {
    return (
      <Login
        setToken={setToken}
        setUserContext={setUserContext}
        userContext={userContext}
        setLocaleOnLogin={setLocaleOnLogin}
      />
    );
  } else if (!token && redirection === "EXTERNAL") {
    return (
      <ExternalAuthSession
        setToken={setToken}
        setRedirection={setRedirection}
        setUserContext={setUserContext}
        userContext={userContext}
        setLocaleOnLogin={setLocaleOnLogin}
      />
    );
  } else if (!token && redirection === "NEW_USER") {
    return (
      <RegisterNewUser
        setToken={setToken}
        setRedirection={setRedirection}
        setUserContext={setUserContext}
        userContext={userContext}
      />
    );
  } else if (!token && redirection === "NEW_USER_COMPLETED") {
    return <RegisterNewUserCompleted setRedirection={setRedirection} userContext={userContext} />;
  }

  if (!userContext) {
    return null;
  }

  const companyMembershipType = get(userContext, "currentCompany.companyMembershipType");
  const isCurrentCompanyAdmin = companyMembershipType === "ADMIN";

  return (
    <>
      <Suspense fallback={Spinner()}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Reviver</title>
          <link rel="canonical" href="https://reviver.no" />
          {isCurrentCompanyAdmin && <body className="admin"></body>}
        </Helmet>
        <DebugTools off={true} />
        <UserContext.Provider value={userContext}>
          <HeadersContainer>
            {isCurrentCompanyAdmin && (
              <>
                <AdminHeader />
              </>
            )}
            {token && <MainHeader changeLocale={changeLocale} setUserContext={setUserContext} logOut={logOut} />}
          </HeadersContainer>
          <div className="panel-provider-relativity">
            <PanelContextProvider open={false}>
              <main>
                <section>
                  <Switch>
                    <Route exact path="/" render={() => <Redirect to="/trade" />} />
                    <Route path="/dashboard">
                      <Dashboard />
                    </Route>
                    <Route path="/registerCompany">
                      <RegisterCompanyWizard />
                    </Route>
                    <Route path="/createPassword">
                      <CreatePassword />
                    </Route>
                    <Route path="/trade">
                      <Trade setUserContext={setUserContext} />
                    </Route>
                    <Route path="/settings">
                      <Settings setUserContext={setUserContext} changeLocale={changeLocale} />
                    </Route>
                    <Route path="/checkout">
                      <Checkout />
                    </Route>
                    <Route path="/task">
                      <Tasks />
                    </Route>
                    <ProtectedRoute isAdmin={isCurrentCompanyAdmin} path="/verifyCustomer">
                      <VerifyCustomer />
                    </ProtectedRoute>
                    <ProtectedRoute isAdmin={isCurrentCompanyAdmin} path="/statistics">
                      <Statistics />
                    </ProtectedRoute>
                    <ProtectedRoute isAdmin={isCurrentCompanyAdmin} path="/staleTransactions">
                      <StaleTransactions />
                    </ProtectedRoute>
                    <ProtectedRoute isAdmin={isCurrentCompanyAdmin} path="/adminFeedback">
                      <AdminFeedback />
                    </ProtectedRoute>
                  </Switch>
                </section>
                <SidePanel />
              </main>
            </PanelContextProvider>
          </div>
          <footer style={{ display: "none" }}>
            Fished&nbsp;
            <span role="img" aria-label="Copyright">
              ©️
            </span>
            &nbsp;2021
          </footer>
        </UserContext.Provider>
      </Suspense>
    </>
  );
}

export default App;
