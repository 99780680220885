import { axios, APIBASE_REVIVERUSERS } from "./ApiConfig";

export const createUser = async (firstname, lastname, email, role, companyId) =>
  axios.post(`${APIBASE_REVIVERUSERS}api/user/create/advanced`, {
    firstname: firstname,
    lastname: lastname,
    email: email,
    role: role,
    companyId: companyId,
  });

export const deleteTeamMember = async (companyId, userId) =>
  axios.delete(`${APIBASE_REVIVERUSERS}api/company/${companyId}/team/delete/${userId}`);

export const postChangeRole = async (userId, role, companyId) =>
  axios.post(`${APIBASE_REVIVERUSERS}api/user/role`, {
    userId: userId,
    userRoleDto: {
      roleType: role,
      companyId: companyId,
    },
  });

export const updateUser = async (
  userId,
  firstname,
  lastname,
  email,
  phone,
  languageId,
  receiveCopyOfTradingNotifications
) =>
  axios.put(`${APIBASE_REVIVERUSERS}api/user/update`, {
    userId: userId,
    firstname: firstname,
    lastname: lastname,
    email: email,
    phone: phone,
    languageId: languageId,
    receiveCopyOfTradingNotifications: receiveCopyOfTradingNotifications,
  });

export const updateCompany = async (company) => axios.post(`${APIBASE_REVIVERUSERS}api/company/update`, company);

export const postNewAddress = async (companyId, address, postCode, place, countryId, addressType) =>
  axios.post(`${APIBASE_REVIVERUSERS}api/company/${companyId}/address`, {
    address,
    postCode,
    place,
    countryId,
    addressType,
  });

export const updateAddress = async (companyId, id, address, postCode, place, country, addressType) =>
  axios.put(`${APIBASE_REVIVERUSERS}api/company/${companyId}/address/update`, {
    id,
    address,
    postCode,
    place,
    country,
    addressType,
  });

export const deleteAddress = async (companyId, addressId) =>
  axios.delete(`${APIBASE_REVIVERUSERS}api/company/${companyId}/address/${addressId}/delete`);

export const postNewBankAccount = async (companyId, name, accountNo, iban) =>
  axios.post(`${APIBASE_REVIVERUSERS}api/company/${companyId}/account`, {
    name,
    accountNo,
    iban,
  });

export const updateBankAccount = async (companyId, id, name, accountNo, iban) =>
  axios.put(`${APIBASE_REVIVERUSERS}api/company/${companyId}/account/update`, {
    id,
    name,
    accountNo,
    iban,
  });

export const deleteBankAccount = async (companyId, accountId) =>
  axios.delete(`${APIBASE_REVIVERUSERS}api/company/${companyId}/account/${accountId}/delete`);
