import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { getCreateOrderValues, getValidConditions, getValidSizes, getBoxtypeValues } from "../../../services/Orders";
import {
  postSaveOrderTemplate,
  getCompanyOrderTemplates,
  deleteOrderTemplate,
  getCompanyNetworkFull,
} from "../../../services/Company";
import { orderTemplateSchema } from "../SettingsValidationSchemas";
import { validateFieldsHelper } from "../../../utils/ValidationHelper";

import createOrderTemplateStructure from "../../../structures/CreateOrderTemplateStructure";
import UserContext from "../../../contexts/UserContext";
import Grid from "../../../components/Grid/Grid";
import Dropdown from "../../../components/Dropdown/Dropdown";
import Textfield from "../../../components/TextField/TextField";
import Button from "../../../components/BlueButton/BlueButton";
import DropdownMulti from "../../../components/DropdownMulti/DropdownMulti";
import Fished_logo_inverted from "../../../assets/img/Fished_logo_inverted.png";

import "./OrderTemplates.css";

export default function OrderTemplates() {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const { currentCompany } = userContext;

  const [orderTemplate, setOrderTemplate] = useState(createOrderTemplateStructure());
  //dropdowns
  const [templateOptions, setTemplateOptions] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [salesItemsOptions, setSalesItemsOptions] = useState([]);
  const [selectedSalesItem, setSelectedSalesItem] = useState(null);
  const [treatmentOptions, setTreatmentOptions] = useState([]);
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  const [toolOptions, setToolOptions] = useState([]);
  const [selectedTool, setSelectedTool] = useState(null);
  const [conditionOptions, setConditionsOptions] = useState([]);
  const [selectedCondition, setSelectedCondition] = useState(null);
  const [sizeOptions, setSizeOptions] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);
  const [boxTypeOptions, setBoxTypeOptions] = useState([]);
  const [selectedBoxType, setSelectedBoxType] = useState(null);
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState(null);
  //validation
  const [validationErrors, setValidationErrors] = useState([]);

  useEffect(() => {
    async function asyncLoadTemplates() {
      const result = await getCompanyOrderTemplates(currentCompany.companyId);

      const _companyTemplates = result.data.orderTemplates.map((template) => ({
        value: template.templateId,
        label: template.templateName,
        template: template,
      }));
      setTemplateOptions(_companyTemplates);
    }

    asyncLoadTemplates();
  }, [currentCompany]);

  useEffect(() => {
    async function loadOptions() {
      async function asyncGetDropdownValues() {
        const values = await getCreateOrderValues();
        return values.data;
      }
      async function asyncGetBoxTypeValues() {
        const values = await getBoxtypeValues();
        return values.data;
      }
      const dropDownValues = await asyncGetDropdownValues();
      const boxTypeValues = await asyncGetBoxTypeValues();

      //  Sales-items options
      const _salesItemOptions = dropDownValues.salesItems.map((salesItem) => ({
        value: salesItem.id,
        label: t("common.fish." + salesItem.value),
      }));
      setSalesItemsOptions(_salesItemOptions);

      //  Treatment options
      const _treatmentOptions = dropDownValues.treatments.map((treatment) => ({
        value: treatment.id,
        label: t("common.treatments." + treatment.value),
      }));
      setTreatmentOptions(_treatmentOptions);

      //  Tool options
      const _toolOptions = dropDownValues.gears.map((gear) => ({
        value: gear.id,
        label: t("common.tools." + gear.value),
      }));
      setToolOptions(_toolOptions);

      //  BoxType options
      const _boxTypeOptions = boxTypeValues.boxtypes.map((boxType) => ({
        value: boxType.id,
        label: t("common.boxtypes." + boxType.name),
      }));
      setBoxTypeOptions(_boxTypeOptions);
    }

    // Companies
    async function asyncGetAllCompanies() {
      const availableCompanies = await getCompanyNetworkFull(currentCompany.companyId);

      const _availableCompanies = availableCompanies.data.companies.map((company) => ({
        value: company.id,
        label: company.companyName,
      }));
      setCompaniesOptions(_availableCompanies);
    }

    loadOptions();
    asyncGetAllCompanies();
  }, [t, currentCompany.companyId]);

  async function asyncLoadValidConditions(salesItemId) {
    async function asyncGetValidConditions() {
      const conditions = await getValidConditions(salesItemId);
      return conditions.data;
    }

    const conditions = await asyncGetValidConditions();
    const _conditionOptions = conditions.map((condition) => ({
      value: condition.id,
      label: t("common.conditions." + condition.condition),
    }));

    setConditionsOptions(_conditionOptions);

    return _conditionOptions;
  }

  async function asyncLoadValidSizes(salesItemId, conditionId) {
    async function asyncGetValidSizes() {
      const sizes = await getValidSizes(salesItemId, conditionId);
      return sizes.data;
    }

    const sizes = await asyncGetValidSizes();
    const _sizeOptions = sizes.map((size) => ({
      value: size.id,
      label: size.size,
    }));

    setSizeOptions(_sizeOptions);

    return _sizeOptions;
  }

  function resetDropdowns() {
    setSelectedTemplate(null);
    setSelectedSalesItem(null);
    setSelectedCondition(null);
    setSelectedSize(null);
    setSelectedTool(null);
    setSelectedTreatment(null);
    setSelectedBoxType(null);
    setSelectedCompanies(null);
  }

  async function onChangeSelectedTemplate(e) {
    setSelectedTemplate(e);
    setOrderTemplate(e.template);

    async function setSalesItemConditionSize(selectedTemplate) {
      const _selectedSalesItem = salesItemsOptions.find(
        (salesItem) => salesItem.value === selectedTemplate.salesItemId
      );
      setSelectedSalesItem(_selectedSalesItem);

      const _conditionOptions = await asyncLoadValidConditions(_selectedSalesItem.value);
      const _selectedCondition = _conditionOptions.find(
        (condition) => condition.value === selectedTemplate.conditionId
      );
      setSelectedCondition(_selectedCondition);

      const _sizeOptions = await asyncLoadValidSizes(_selectedSalesItem.value, _selectedCondition.value);
      const _selectedSize = _sizeOptions.find((size) => size.value === selectedTemplate.sizeId);
      setSelectedSize(_selectedSize);
    }

    await setSalesItemConditionSize(e.template);
    const _selectedTool = toolOptions.find((tool) => tool.value === e.template.gearId);
    setSelectedTool(_selectedTool);
    const _selectedTreatment = treatmentOptions.find((treatment) => treatment.value === e.template.treatmentId);
    setSelectedTreatment(_selectedTreatment);
    const _selectedBoxType = boxTypeOptions.find((boxtype) => boxtype.value === e.template.boxTypeId);
    setSelectedBoxType(_selectedBoxType);
    const _selectedCompanies = companiesOptions.filter((company) =>
      e.template.orderTemplateNetwork.some((template) => template.companyId === company.value)
    );
    setSelectedCompanies(_selectedCompanies);
  }

  function newTemplateHandler() {
    setOrderTemplate(createOrderTemplateStructure());
    resetDropdowns();
  }

  async function saveHandler() {
    const validationResult = validateFieldsHelper(orderTemplateSchema(t), orderTemplate);
    setValidationErrors(validationResult);

    if (Object.keys(validationResult).length) return;

    orderTemplate.companyId = currentCompany.companyId;
    await postSaveOrderTemplate(orderTemplate);
  }

  async function deleteHandler() {
    await deleteOrderTemplate(selectedTemplate.template.templateId);

    setOrderTemplate(createOrderTemplateStructure());
    resetDropdowns();
  }

  function onChangeOrderTemplate(change) {
    setOrderTemplate({
      ...orderTemplate,
      ...change,
    });
  }

  function resetConditionAndSize() {
    setSelectedCondition(null);
    onChangeOrderTemplate({ condition: 0 });
    resetSize();
  }
  function resetSize() {
    setSelectedSize(null);
    onChangeOrderTemplate({ size: 0 });
  }

  async function onChangeSalesItem(e) {
    setSelectedSalesItem(e);
    resetConditionAndSize();
    await asyncLoadValidConditions(e.value);
    onChangeOrderTemplate({ salesItemId: e.value });
  }
  async function onChangeCondition(e) {
    setSelectedCondition(e);
    resetSize();
    await asyncLoadValidSizes(selectedSalesItem.value, e.value);
    onChangeOrderTemplate({ conditionId: e.value });
  }
  function onChangeSize(e) {
    setSelectedSize(e);
    onChangeOrderTemplate({ sizeId: e.value });
  }
  function onChangeTool(e) {
    setSelectedTool(e);
    onChangeOrderTemplate({ gearId: e.value });
  }
  function onChangeTreatment(e) {
    setSelectedTreatment(e);
    onChangeOrderTemplate({ treatmentId: e.value });
  }
  function onChangeBoxType(e) {
    setSelectedBoxType(e);
    onChangeOrderTemplate({ boxTypeId: e.value });
  }
  function onChangeTargetCompanies(e) {
    setSelectedCompanies(e);
    const companies = e.map((company) => {
      return { companyId: company.value };
    });
    onChangeOrderTemplate({ orderTemplateNetwork: companies });
  }

  return (
    <div className="ordertemplate-container">
      <Grid className="top-card mbottom30">
        <Grid.Row>
          <Grid.Col span={6}>
            <Dropdown
              id="template"
              label="Template"
              options={templateOptions}
              value={selectedTemplate}
              onChange={(e) => onChangeSelectedTemplate(e)}
            />
          </Grid.Col>
          <Grid.Col span={6} className="vbottom">
            <Button
              id="new-template"
              text={t("settings.newTemplate")}
              buttontype={Button.buttontype.DarkBlue}
              svgname="pluss-square"
              onClick={() => newTemplateHandler()}
            />
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col span={4}>
            <Textfield
              id="template-name"
              placeholder="Template name"
              label={t("settings.templateName")}
              value={orderTemplate.templateName}
              onChange={(e) => onChangeOrderTemplate({ templateName: e.target.value })}
              errormessage={validationErrors.templateName}
            />
          </Grid.Col>
          <Grid.Col span={8} className="vbottom">
            <div className="flex gap10">
              <Button
                id="save"
                text={t("settings.saveTemplate")}
                buttontype={Button.buttontype.DarkBlue}
                onClick={() => saveHandler()}
              />
              <Button
                id="delete"
                text={t("settings.deleteTemplate")}
                buttontype={Button.buttontype.Quaternary}
                disabled={!selectedTemplate}
                onClick={() => deleteHandler()}
              />
            </div>
          </Grid.Col>
        </Grid.Row>
      </Grid>

      <Grid>
        <Grid.Row>
          <Grid.Col span={4}>
            <Dropdown
              id="sales-item"
              label={t("common.type")}
              options={salesItemsOptions}
              value={selectedSalesItem}
              onChange={(e) => onChangeSalesItem(e)}
              errormessage={validationErrors.salesItemId}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Dropdown
              id="condition"
              label={t("commonOrders.conditions")}
              options={conditionOptions}
              value={selectedCondition}
              onChange={(e) => onChangeCondition(e)}
              errormessage={validationErrors.conditionId}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Dropdown
              id="size"
              label={t("commonOrders.sizes")}
              options={sizeOptions}
              value={selectedSize}
              onChange={(e) => onChangeSize(e)}
              errormessage={validationErrors.sizeId}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={4}>
            <Dropdown
              id="tool"
              label={t("commonOrders.gears")}
              options={toolOptions}
              value={selectedTool}
              onChange={(e) => onChangeTool(e)}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Dropdown
              id="treatment"
              label={t("commonOrders.treatments")}
              options={treatmentOptions}
              value={selectedTreatment}
              onChange={(e) => onChangeTreatment(e)}
            />
          </Grid.Col>
          <Grid.Col span={4} id="fishlogo">
            <img id="fishlogo" style={{ position: "absolute" }} src={Fished_logo_inverted} alt="" />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={4}>
            <Dropdown
              id="box-type"
              label={t("common.boxtype")}
              options={boxTypeOptions}
              value={selectedBoxType}
              onChange={(e) => onChangeBoxType(e)}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <DropdownMulti
              id="network"
              label={t("common.companyToTradeWith")}
              closeMenuOnSelect={false}
              menuPlacement="top"
              options={companiesOptions}
              value={selectedCompanies}
              onChange={(e) => onChangeTargetCompanies(e)}
            />
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </div>
  );
}
