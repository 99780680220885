import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { getOrdersForCompany } from "../../../services/Orders";
import { getAllCompanies } from "../../../services/Company";
import { postUserMadeAdvertisement } from "../../../services/Messaging";
import { validateFieldsHelper } from "../../../utils/ValidationHelper";

import Joi from "joi";
import UserContext from "../../../contexts/UserContext";
import Grid from "../../../components/Grid/Grid";
import CheckBox from "../../../components/Checkbox/Checkbox";
import DropdownMulti from "../../../components/DropdownMulti/DropdownMulti";
import Button from "../../../components/BlueButton/BlueButton";
import SVGAsset from "../../../components/SVGAsset/SVGAsset";
import OrderTypeEnum from "../../../constants/enums/OrderTypeEnum";
import Spinner from "../../../components/Spinner/Spinner";
import TextfieldMulti from "../../../components/TextfieldMulti/TextfieldMulti";
import MessageBar from "../../../components/MessageBar/MessageBar";

export default function UserAdvertising() {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const { currentCompany } = userContext;

  const [isLoadingOrder, setIsLoadingOrders] = useState(false);
  const [companyOrders, setCompanyOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [currentEmailText, setCurrentEmailText] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [advertisementStatusMessage, setAdvertisementStatusMessage] = useState("");
  const [advertisementStatusType, setAdvertisementStatusType] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSelectedOrders([]);
    async function asyncGetOrders() {
      setIsLoadingOrders(true);
      const orders = await getOrdersForCompany(currentCompany.companyId, false, 1);
      setCompanyOrders(orders.data);
      setIsLoadingOrders(false);
    }

    asyncGetOrders();
  }, [currentCompany.companyId]);

  useEffect(() => {
    async function asyncGetCompanies() {
      const availableCompanies = await getAllCompanies();
      const _companies = availableCompanies.data.companies.map((company) => ({
        value: company.id,
        label: company.companyName,
        tradingEmail: company.tradingemail,
        languageId: company.language.id,
      }));
      setCompaniesOptions(_companies);
    }

    asyncGetCompanies();
  }, []);

  function onClickCompanyOrder(selectedOrder) {
    if (!selectedOrders.includes(selectedOrder)) {
      setSelectedOrders([...selectedOrders, selectedOrder]);
    } else {
      setSelectedOrders([...selectedOrders.filter((order) => order !== selectedOrder)]);
    }
  }

  const validationSchema = Joi.object({
    currentEmailText: Joi.string()
      .email({
        tlds: {
          allow: false,
        },
      })
      .messages({
        "*": t("validation.invalidEmail"),
      }),
  });

  const resetAdvertisementStatus = () => {
    setTimeout(() => {
      setAdvertisementStatusMessage("");
      setAdvertisementStatusType("");
    }, 5000);
  };

  async function sendAdvertisementHandler() {
    setIsLoading(true);
    const companyRecipients = selectedCompanies.map((company) => {
      return {
        email: company.tradingEmail,
        languageId: company.languageId,
        companyId: company.value,
      };
    });

    const emailRecipients = selectedEmails.map((email) => {
      return { email: email.label };
    });
    const finalRecipients = companyRecipients.concat(emailRecipients);
    const orderIds = selectedOrders.map((company) => company.id);

    const sendAdvertisement = await postUserMadeAdvertisement(finalRecipients, orderIds, currentCompany.companyName);

    if (sendAdvertisement.status === 200) {
      setAdvertisementStatusMessage("Success");
      setAdvertisementStatusType(MessageBar.type.Positive);
      resetAdvertisementStatus();
    } else {
      setAdvertisementStatusMessage("Failed");
      setAdvertisementStatusType(MessageBar.type.Negative);

      resetAdvertisementStatus();
    }

    setIsLoading(false);
  }

  function handleSelectEmail() {
    if (!currentEmailText) return;
    if (selectedEmails && selectedEmails.some((email) => email.label === currentEmailText)) return;

    const validationErrors = validateFieldsHelper(validationSchema, {
      currentEmailText,
    });
    setValidationErrors(validationErrors);
    if (Object.keys(validationErrors).length) return;

    const createOption = (label) => ({
      label,
      value: label,
    });

    setSelectedEmails([...selectedEmails, createOption(currentEmailText)]);

    setCurrentEmailText("");
  }

  function findOrderType(orderTypeId) {
    if (orderTypeId === OrderTypeEnum.SELL) {
      return "saleIndicator";
    } else if (orderTypeId === OrderTypeEnum.AUCTION) {
      return "auctionIndicator";
    } else if (orderTypeId === OrderTypeEnum.BUY) {
      return "purchaseIndicator";
    }
  }

  return (
    <Grid>
      <Grid.Row className="mtop30">
        <Grid.Col span={12}>
          <label className="font-family-polysans">{t("settings.advertisingStep1")}</label>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col span={12}>
          <Spinner loading={isLoadingOrder} inline />
          {companyOrders.orders && companyOrders.orders.length > 0 && (
            <table className="mbottom30 lightblue">
              <thead>
                <tr>
                  <th className="icon"></th>
                  <th className="icon"></th>
                  <th>{t("common.type")}</th>
                  <th>{t("common.amount")}</th>
                  <th>{t("common.treatment")}</th>
                  <th>{t("common.size")}</th>
                </tr>
              </thead>
              <tbody>
                {companyOrders.orders.map((order, index) => {
                  return (
                    <tr
                      id={`order-row-${index}`}
                      key={`order-row-${index}`}
                      className="clickable"
                      onClick={() => onClickCompanyOrder(order)}
                    >
                      <td>
                        <CheckBox checked={selectedOrders.includes(order)} />
                      </td>
                      <td>
                        <SVGAsset name={findOrderType(order.orderType.id)} />
                      </td>
                      <td>{t("common.fish." + order.salesItem.salesItem)}</td>
                      <td>{order.remainingAmount}</td>
                      <td>{t("common.conditions." + order.condition.condition)}</td>
                      <td>{order.size.size}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </Grid.Col>
      </Grid.Row>

      <Grid.Row className="mtop10 mbottom10">
        <Grid.Col span={12}>
          <label className="font-family-polysans">{t("settings.advertisingStep2")}</label>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col span={4}>
          <DropdownMulti
            dropDownClassNames="mbottom30"
            id="advertisement-multi"
            label={t("settings.recipients")}
            closeMenuOnSelect={false}
            options={companiesOptions}
            onChange={(e) => setSelectedCompanies(e)}
          />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row className="mtop10 mbottom10">
        <Grid.Col span={12}>
          <label className="font-family-polysans">{t("settings.advertisingStep3")}</label>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col span={4}>
          <TextfieldMulti
            label={t("commonComponents.emailAddresses")}
            keyDownHandler={handleSelectEmail}
            onInputChange={(e) => setCurrentEmailText(e)}
            onChange={(e) => setSelectedEmails(e)}
            inputValue={currentEmailText}
            value={selectedEmails}
            errormessage={validationErrors.currentEmailText}
          />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col span={12}>
          <div className="flex align">
            <Button
              id="sendAdvertisement"
              className="right"
              text={t("common.send")}
              disabled={
                isLoading ||
                selectedOrders.length === 0 ||
                (selectedCompanies.length === 0 && selectedEmails.length === 0)
              }
              onClick={() => sendAdvertisementHandler()}
            />
            <Spinner loading={isLoading} inline />
          </div>
          <div className="mtop10">
            {advertisementStatusMessage ? (
              <MessageBar
                className="break-word"
                type={advertisementStatusType}
                noIcon
                message={advertisementStatusMessage}
              />
            ) : null}
          </div>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
}
