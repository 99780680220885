import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { postInvoice } from "../../../services/Orders";
import { ErrorDialogContext } from "../../../contexts/ErrorDialogContext";
import RadioButton from "../../../components/RadioButton/RadioButton";
import Button from "../../../components/BlueButton/BlueButton";
import SVGAsset from "../../../components/SVGAsset/SVGAsset";
import Grid from "../../../components/Grid/Grid";
import Spinner from "../../../components/Spinner/Spinner";
import PropTypes from "prop-types";

import "./PaymentTask.css";

export default function PaymentTask(props) {
  const { t } = useTranslation();

  const errorDialogContext = useContext(ErrorDialogContext);
  const { showErrorDialog, setErrorDialogOptions } = errorDialogContext;

  const history = useHistory();
  const { orderId, taskId, onCloseCB } = props;

  const [selected, setSelected] = useState();
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [buttonText, setButtonText] = useState(t("dashboard.selectOne"));

  const onClickHandler = async () => {
    setLoadingPayment(true);
    // TODO: if we decide to remove card option -> remove the following unused code.
    if (selected === "card") {
      history.push(`checkout/?orderId=${orderId}`);
      onCloseCB();
    } else {
      try {
        await postInvoice(orderId, taskId);
        onCloseCB();
      } catch (error) {
        setErrorDialogOptions({
          message: "Saving invoice choice failed",
          error,
        });
        showErrorDialog(true);
      }
    }
    setLoadingPayment(false);
  };

  const onChangeRB = (selected) => {
    setSelected(selected);

    if (selected === "card") setButtonText(t("dashboard.selectpayment"));
    else if (selected === "invoice") setButtonText(t("dashboard.confirmPayment"));
  };

  return (
    <Grid className="payment-task">
      <Spinner loading={loadingPayment} inline={true} align="middle" />
      <Grid.Row>
        <Grid.Col span={12} className="flex center gap10 radio-header">
          <SVGAsset name="credit-card" />
          <p>{t("dashboard.choosepayment")}</p>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row className="center">
        <Grid.Col span={6}>
          <RadioButton
            value="invoice"
            label={t("dashboard.invoice")}
            selected={selected}
            onClick={() => onChangeRB("invoice")}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <RadioButton
            value="card"
            label={t("dashboard.card")}
            isDisabled={true}
            selected={selected}
            onClick={() => onChangeRB("card")}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col span={12} className="center pad10">
          <Button id="payment-submit" text={buttonText} disabled={!selected} onClick={() => onClickHandler()} />
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
}

PaymentTask.propTypes = {
  orderId: PropTypes.number,
  onCloseCB: PropTypes.func,
};
