//import PropTypes from 'prop-types'
import "./NarrowPage.css";

function NarrowPage(props) {
  return (
    <div {...props} className={`narrow ${props.className || ""}`}>
      {props.children}
    </div>
  );
}

// TODO: impl proptypes
export default NarrowPage;
