import { FC } from "react";

import Hamburger from "../Icons/Hamburger";
import Close from "../Icons/Close";
import { UnstyledButton } from "@mantine/core";
import styles from "./styles.module.css";

interface Props {
  onClick: () => void;
  showCloseButton: boolean;
  variant: "primary" | "secondary";
}

const HamburgerNav: FC<Props> = ({ onClick, showCloseButton, variant }) => {
  return (
    <UnstyledButton className={`${styles.root} ${styles[variant]}`} onClick={onClick}>
      {showCloseButton ? <Close /> : <Hamburger />}
    </UnstyledButton>
  );
};

export default HamburgerNav;
