import React, { useEffect, useContext, useState } from "react";
import { getOpenTasks } from "../../services/Tasks";
import { getOrder, getOrderByTransactionId } from "../../services/Orders";
import { useTranslation } from "react-i18next";

import UserContext from "../../contexts/UserContext";
import Moment from "react-moment";
import Drawer from "../../components/Drawer/Drawer";
import PaymentTask from "./TaskDrawer/PaymentTask";
import TaskTypeEnum from "../../constants/enums/TaskTypeEnum";
import Spinner from "../../components/Spinner/Spinner";
import GeneralTaskInfo from "./TaskDrawer/GeneralTaskInfo";
import FinalAmountTask from "./TaskDrawer/FinalAmountTask";
import TransportTask from "./TaskDrawer/TransportTask";
import FeedbackTask from "./TaskDrawer/FeedbackTask";
import moment from "moment-timezone";

import "./Tasks.css";

export default function Tasks() {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const currentDate = moment().format();
  const { currentCompany, user } = userContext;
  const [openTasks, setOpenTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerLoading, setIsDrawerLoading] = useState(false);
  const [taskInDrawer, setTaskInDrawer] = useState({});
  const [orderInDrawer, setOrderInDrawer] = useState({});

  const sortTasksAfterDate = openTasks.sort(function (a, b) {
    return new Date(a.dueDate) - new Date(b.dueDate);
  });

  useEffect(() => {
    async function loadOpenTasks() {
      setIsLoading(true);
      const result = await getOpenTasks(currentCompany.companyId);
      setOpenTasks(result.data);
    }
    loadOpenTasks();
    setIsLoading(false);
  }, [currentCompany.companyId]);

  async function openDrawer(task) {
    setIsDrawerLoading(true);
    setTaskInDrawer(task);
    setIsDrawerOpen(true);
    // TODO: Implement error handling
    if (
      task.type === TaskTypeEnum.AUCTION_END_PAYMENT_STR ||
      task.type === TaskTypeEnum.FINAL_AMOUNT_STR ||
      task.type === TaskTypeEnum.AUCTION_END_TRANSPORT_STR
    ) {
      const result = await getOrder(task.connected);
      const order = result.data;
      setOrderInDrawer(order);
    } else if (task.type === TaskTypeEnum.FEEDBACK_STR) {
      const result = await getOrderByTransactionId(task.connected, currentCompany.companyId);
      const order = result.data;
      setOrderInDrawer(order);
    }
    setIsDrawerLoading(false);
  }

  async function completeTaskCB() {
    setIsLoading(true);

    const result = await getOpenTasks(currentCompany.companyId);
    setOpenTasks(result.data);
    setIsDrawerOpen(false);
    setIsLoading(false);
  }

  return (
    <div className="task-container">
      <Spinner loading={isLoading} />
      <div>
        <table className="compact">
          <thead>
            <tr>
              <th scope="col">{t("common.dueDate")}</th>
              <th scope="col">{t("tasks.type")}</th>
              <th scope="col">{t("tasks.status")}</th>
              <th scope="col">{t("tasks.counterParty")}</th>
              <th scope="col">{t("common.type")}</th>
              <th scope="col">{t("common.amount")}</th>
              <th scope="col">{t("common.price")}</th>
            </tr>
          </thead>
          <tbody>
            {sortTasksAfterDate.map((task, index) => {
              return (
                <tr
                  id={`opentask-row-${index}`}
                  key={`opentask-row-${index}`}
                  className={`clickable ${moment(moment(task.dueDate)).isAfter(currentDate) ? "" : "overDue"}`}
                  onClick={() => openDrawer(task)}
                >
                  <td data-label={t("common.dueDate")}>
                    <Moment format="Do MMMM YYYY, HH:mm">{task.dueDate}</Moment>
                  </td>
                  <td data-label={t("tasks.type")}>{t("common.status." + task.type)}</td>
                  <td data-label={t("tasks.status")}>{task.status}</td>
                  <td data-label={t("tasks.counterParty")}>{task.counterParty}</td>
                  <td data-label={t("common.type")}>{task.salesItem ? t("common.fish." + task.salesItem) : " "}</td>
                  {/* Coule be presented the same way as GeneralTaskInfo where amount deviation is taken into account */}
                  <td data-label={t("common.amount")}>{task.amount ? task.amount + " kg" : " "}</td>
                  <td data-label={t("common.price")}>
                    {task.price ? task.price + " " + currentCompany.companyCurrency + " per kg" : " "}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Drawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <div className="full flex col">
          <Spinner loading={isDrawerLoading} />
          {!isDrawerLoading && (
            <>
              {taskInDrawer.type === TaskTypeEnum.AUCTION_END_PAYMENT_STR && (
                <PaymentTask
                  orderId={taskInDrawer.connected}
                  taskId={taskInDrawer.id}
                  onCloseCB={() => completeTaskCB()}
                />
              )}
              {taskInDrawer.type === TaskTypeEnum.AUCTION_END_TRANSPORT_STR && (
                <TransportTask
                  orderId={taskInDrawer.connected}
                  taskId={taskInDrawer.id}
                  currentCompany={currentCompany}
                  onCloseCB={() => completeTaskCB()}
                />
              )}
              {taskInDrawer.type === TaskTypeEnum.FINAL_AMOUNT_STR && (
                <FinalAmountTask
                  amount={orderInDrawer.amount}
                  amountDeviation={orderInDrawer.amountDeviation}
                  orderId={taskInDrawer.connected}
                  taskId={taskInDrawer.id}
                  onCloseCB={() => completeTaskCB()}
                />
              )}
              {taskInDrawer.type === TaskTypeEnum.FEEDBACK_STR && (
                <div className="mtop20">
                  <FeedbackTask
                    taskId={taskInDrawer.id}
                    transactionId={taskInDrawer.connected}
                    orderId={orderInDrawer.id}
                    senderUserId={user.id}
                    onCloseCB={() => completeTaskCB()}
                  />
                </div>
              )}
              <GeneralTaskInfo
                order={orderInDrawer}
                currentCompany={currentCompany}
                taskType={taskInDrawer.type}
                taskCounterParty={taskInDrawer.counterParty}
                taskPrice={taskInDrawer.price}
                taskAmount={taskInDrawer.amount}
              />
            </>
          )}
        </div>
      </Drawer>
    </div>
  );
}
