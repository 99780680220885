import React, { useContext, useState, useEffect } from "react";
import Grid from "../../../components/Grid/Grid";
import TextField from "../../../components/TextField/TextField";
import CheckBox from "../../../components/Checkbox/Checkbox";
import BlueButton from "../../../components/BlueButton/BlueButton";
import UserContext from "../../../contexts/UserContext";
import Dropdown from "../../../components/Dropdown/Dropdown";
import Spinner from "../../../components/Spinner/Spinner";
import ModalDialog from "../../../containers/ModalDialog/ModalDialog";
import ModalDialogButtonsWrapper from "../../ModalDialog/ModalDialogButtonsWrapper";
import ContextPanel from "../../../components/ContextPanel/ContextPanel";
import MessageBar from "../../../components/MessageBar/MessageBar";
import MembershipTypeEnum from "../../../constants/enums/MembershipTypeEnum";

import { ErrorDialogContext } from "../../../contexts/ErrorDialogContext";
import { get, capitalize } from "lodash";
import { getCompanyById, getTransportCompanies } from "../../../services/Company";
import {
  getLanguages,
  getCompanyTypes,
  getCountries,
  getTimeZones,
  getAddressTypes,
  getMembershipTypes,
} from "../../../services/RegisterCompany";
import { getCreateOrderValues } from "../../../services/Orders";
import {
  updateCompany,
  postNewAddress,
  deleteAddress,
  updateAddress,
  postNewBankAccount,
  deleteBankAccount,
  updateBankAccount,
} from "../../../services/Settings";
import { useTranslation } from "react-i18next";
import { getCompaniesByUserId } from "../../../services/Login";
import { validateFieldsHelper } from "../../../utils/ValidationHelper";
import {
  companySettingsSchema,
  transportSettingsSchema,
  addressSettingsSchema,
  bankAccountSettingsSchema,
} from "./CompanySettingsValidationSchema";

import "./CompanySettings.css";
import PhoneField from "../../../components/PhoneInput/PhoneInput";

export default function CompanySettings(props) {
  const { t, i18n } = useTranslation();

  const { setUserContext, changeLocale } = props;

  const errorDialogContext = useContext(ErrorDialogContext);
  const { showErrorDialog, setErrorDialogOptions } = errorDialogContext;
  const userContext = useContext(UserContext);
  const { currentCompany, user } = userContext;
  const [loadingSave, setLoadingSave] = useState(false);

  const [company, setCompany] = useState();
  const [languageOptions, setLanguageOptions] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [companyTypesOptions, setCompanyTypesOptions] = useState([]);
  const [selectedCompanyType, setSelectedCompanyType] = useState("");
  const [transportOptions, setTransportOptions] = useState([]);
  const [selectedTransportCompany, setSelectedTransportCompany] = useState("");
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [missingFields, setMissingFields] = useState([]);
  const [membershipTypeOptions, setMembershipTypeOptions] = useState([]);
  const [selectedMembershipType, setSelectedMembershipType] = useState("");

  const [validationErrors, setValidationErrors] = useState([]);
  const [validationErrorsTransport, setValidationErrorsTransport] = useState([]);
  const [validationErrorsAddress, setValidationErrorsAddress] = useState([]);
  const [validationErrorsBankAccount, setValidationErrorsBankAccount] = useState([]);

  // EDIT/ADD COMPANY ADDRESS
  const [isAddEditAddressOpen, setIsAddEditAddressOpen] = useState(false);
  const [isNewAddress, setIsNewAddress] = useState(false);
  const [addressToEdit, setAddressToEdit] = useState(0);
  const [address, setAddress] = useState("");
  const [postCode, setPostCode] = useState("");
  const [place, setPlace] = useState("");
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [addressTypeOptions, setAddressTypeOptions] = useState([]);
  const [selectedAddressType, setSelectedAddressType] = useState("");
  const [messageBarMessage, setMessageBarMessage] = useState("");
  const [messageBarType, setMessageBarType] = useState();

  // EDIT/ADD BANK ACCOUNTS
  const [isAddEditBankAccountOpen, setIsAddEditBankAccountOpen] = useState(false);
  const [isNewBankAccount, setIsNewBankAccount] = useState(false);
  const [bankAccountToEdit, setBankAccountToEdit] = useState(0);
  const [bankAccountName, setBankAccountName] = useState("");
  const [bankAccountNr, setBankAccountNr] = useState("");
  const [bankAccountIban, setBankAccountIban] = useState("");

  const resetDeleteAddressWarning = () => {
    setTimeout(() => {
      setMessageBarMessage(undefined);
      setMessageBarType(undefined);
    }, 6000);
  };

  useEffect(() => {
    let loaded = false;

    async function asyncGetCompany() {
      const response = await getCompanyById(currentCompany.companyId);
      setCompany(response.data);
    }

    async function loadLanguages() {
      const response = await getLanguages();
      let languageOptions = response.data;

      // Language dropdown
      const _languageOptions = languageOptions.languages.map((language) => ({
        value: language.id,
        label: language.name,
        languageCode: language.languageCode,
      }));
      setLanguageOptions(_languageOptions);
    }

    // Company types
    async function asyncGetCompanyTypes() {
      const companyTypesData = await getCompanyTypes();

      const _companyTypesOptions = companyTypesData.data.companyTypes
        .filter((type) => type !== "TRANSPORT")
        .map((type) => ({
          value: type,
          label: t("registerCompany.companyTypes." + type),
        }));
      setCompanyTypesOptions(_companyTypesOptions);
    }

    // Transport company
    async function asyncGetTransportCompanies() {
      const transport = await getTransportCompanies();

      const _transportOptions = transport.data.companies.map((company) => ({
        value: company.id,
        label: company.companyName,
      }));
      setTransportOptions(_transportOptions);
    }

    // Country
    async function asyncGetCountries() {
      const countries = await getCountries();

      const _countryOptions = countries.data.map((country) => ({
        value: country.id,
        label: country.name,
        iso3Code: country.iso3,
        iso2Code: country.iso2,
      }));
      setCountryOptions(_countryOptions);
    }

    // Timezones
    async function asyncGetTimeZones() {
      const zones = await getTimeZones();

      const _zones = zones.data.timeZone.map((zone) => ({
        value: zone,
        label: zone,
      }));
      setTimeZoneOptions(_zones);
    }

    // Address type
    async function asyncGetAddressTypes() {
      const types = await getAddressTypes();

      const _types = types.data.addressTypes.map((type) => ({
        value: type,
        label: t("registerCompany.addressTypes." + type),
      }));
      setAddressTypeOptions(_types);
    }

    // Currency
    async function asyncGetCurrency() {
      const currencies = await getCreateOrderValues();
      const _currencies = currencies.data.currencies.map((currency) => ({
        value: currency.id,
        label: currency.value.toUpperCase(),
      }));
      setCurrencyOptions(_currencies);
    }

    // Membership
    async function asyncGetMembershipTypes() {
      const memberships = await getMembershipTypes();
      const _memberships = memberships.data.map((membership) => ({
        value: membership,
        label: capitalize(membership),
      }));
      setMembershipTypeOptions(_memberships);
    }

    if (!loaded) {
      loadLanguages();
      asyncGetCompany();
      asyncGetCompanyTypes();
      asyncGetTransportCompanies();
      asyncGetCountries();
      asyncGetTimeZones();
      asyncGetAddressTypes();
      asyncGetCurrency();
      asyncGetMembershipTypes();
    }

    return () => {
      loaded = true;
    };
  }, [currentCompany.companyId, t]);

  useEffect(() => {
    // prefill language
    const _selectedLanguage = languageOptions.find((option) => option.value === get(company, "language.id"));
    setSelectedLanguage(_selectedLanguage);

    // prefill companyType
    const _selectedCompanyType = companyTypesOptions.find((option) => option.value === get(company, "companyType"));
    setSelectedCompanyType(_selectedCompanyType);

    // prefill transport company
    const _transportCompany = transportOptions.find(
      (option) => option.value === get(company, "preferredTransportCompanyId")
    );
    setSelectedTransportCompany(_transportCompany);

    // prefill timeZone
    const _zone = timeZoneOptions.find((option) => option.value === get(company, "timeZone"));
    setSelectedTimeZone(_zone);

    // prefill currency
    const _currency = currencyOptions.find((option) => option.label === get(company, "defaultCurrency"));
    setSelectedCurrency(_currency);

    // prefill membership
    const _membership = membershipTypeOptions.find((option) => option.value === get(company, "membership"));
    setSelectedMembershipType(_membership);
  }, [
    languageOptions,
    company,
    companyTypesOptions,
    transportOptions,
    timeZoneOptions,
    currencyOptions,
    membershipTypeOptions,
  ]);

  function onChange(change) {
    setCompany({
      ...company,
      ...change,
    });
  }

  function onChangeLanguage(e) {
    setSelectedLanguage(e);
    const language = e;
    onChange({
      language: {
        id: language.value,
        name: language.label,
        languageCode: language.languageCode,
      },
    });
  }

  function onChangeCompanyType(e) {
    setSelectedCompanyType(e);
    const companyType = e;
    onChange({
      companyType: companyType.value,
    });
  }

  function onChangeTransportCompany(e) {
    setSelectedTransportCompany(e);
    const transportCompany = e;
    if (transportCompany === null) {
      onChange({
        preferredTransportCompanyId: null,
      });
    } else {
      onChange({
        preferredTransportCompanyId: transportCompany.value,
      });
    }
  }

  function onChangeTimeZone(e) {
    setSelectedTimeZone(e);
    const timeZone = e.value;
    onChange({
      timeZone: timeZone,
    });
  }

  function onChangeCurrency(e) {
    setSelectedCurrency(e);
    const currency = e;
    onChange({
      defaultCurrency: currency.label,
    });
  }

  function onChangeMembershipType(e) {
    setSelectedMembershipType(e);
    const membership = e.value;
    onChange({
      membership: membership,
    });
  }

  const saveHandler = async () => {
    try {
      setLoadingSave(true);
      const inputChecker = validateFieldsHelper(companySettingsSchema(t), company);
      setValidationErrors(inputChecker);

      let inputCheckerTransport = {};
      if (company.withoutTransport === false) {
        inputCheckerTransport = validateFieldsHelper(transportSettingsSchema(t), company);
        setValidationErrorsTransport(inputCheckerTransport);
      }

      if (Object.keys(inputChecker).length === 0 && Object.keys(inputCheckerTransport).length === 0) {
        await updateCompany(company);
        setValidationErrors([]);
        setValidationErrorsTransport([]);

        // Load userContext to instantly use new settings
        let companies, currentCompany;
        if (userContext.currentCompany.companyId) {
          const response = await getCompaniesByUserId(userContext.user.id, userContext.currentCompany.companyId);
          companies = response.data;
          currentCompany =
            companies.companies.find((company) => company.companyId === userContext.currentCompany.companyId) ||
            companies.companies[0];
        }
        setUserContext({
          ...userContext,
          companies,
          currentCompany,
        });
        changeLocale(user.language.languageCode, i18n, currentCompany.companyTimeZone);
      }
    } catch (error) {
      setErrorDialogOptions({ message: "Update company feilet.", error });
      showErrorDialog(true);
    }
    setLoadingSave(false);
  };

  //#region Add Edit address
  // Add/Edit address context
  function createAddEditAddressContextOptions(index, array) {
    return [
      {
        label: t("settings.edit"),
        action: () => openEditAddressHandler(index, array),
      },
      {
        label: t("settings.delete"),
        action: () => asyncDeleteAddress(index, array),
      },
    ];
  }

  async function RefreshCompany(companyId) {
    const response = await getCompanyById(companyId);
    setCompany(response.data);
  }

  function resetEditCompany() {
    setMissingFields([]);
    setAddressToEdit(0);
    setAddress("");
    setPostCode("");
    setPlace("");
    setSelectedCountry("");
    setSelectedAddressType("");
  }

  const openAddAddressHandler = () => {
    resetEditCompany();
    setIsNewAddress(true);
    setIsAddEditAddressOpen(true);
  };

  function closeEditAddress() {
    setIsAddEditAddressOpen(false);
    setValidationErrorsAddress({});
  }

  const openEditAddressHandler = (index, array) => {
    resetEditCompany();
    setIsNewAddress(false);

    const addressToEdit = array.find((address) => address === array[index]);
    setAddressToEdit(addressToEdit.id);
    setAddress(addressToEdit.address);
    setPostCode(addressToEdit.postCode);
    setPlace(addressToEdit.place);
    const _country = countryOptions.find((option) => option.value === addressToEdit.country.id);
    setSelectedCountry(_country);
    const _addressType = addressTypeOptions.find((option) => option.value === addressToEdit.addressType);
    setSelectedAddressType(_addressType);

    setIsAddEditAddressOpen(true);
  };

  const addEditAddressHandler = async () => {
    if (isNewAddress) {
      if (
        selectedAddressType.value === "SHIPPING" &&
        company.companyAddress &&
        company.companyAddress.some((address) => address.addressType === "SHIPPING")
      ) {
        setMissingFields(t("validation.onlyOneShippingAddress"));
        return;
      }

      try {
        const inputCheckerAddress = validateFieldsHelper(addressSettingsSchema(t), {
          address: address,
          postCode: postCode,
          place: place,
          country: selectedCountry.label,
          addressType: selectedAddressType.value,
        });
        setValidationErrorsAddress(inputCheckerAddress);

        if (Object.keys(inputCheckerAddress).length === 0) {
          await postNewAddress(company.id, address, postCode, place, selectedCountry.value, selectedAddressType.value);

          setValidationErrorsAddress([]);
          RefreshCompany(company.id);
          setIsAddEditAddressOpen(false);
        }
      } catch (error) {
        setErrorDialogOptions({ message: "Adding new address failed.", error });
        showErrorDialog(true);
      }
    } else {
      try {
        const inputCheckerAddress = validateFieldsHelper(addressSettingsSchema(t), {
          address: address,
          postCode: postCode,
          place: place,
          country: selectedCountry.label,
          addressType: selectedAddressType.value,
        });
        setValidationErrorsAddress(inputCheckerAddress);

        if (Object.keys(inputCheckerAddress).length === 0) {
          const country = {
            id: selectedCountry.value,
            name: selectedCountry.label,
            iso3: selectedCountry.iso3Code,
            iso2: selectedCountry.iso2Code,
          };
          await updateAddress(company.id, addressToEdit, address, postCode, place, country, selectedAddressType.value);

          setValidationErrorsAddress([]);
          RefreshCompany(company.id);
          setIsAddEditAddressOpen(false);
        }
      } catch (error) {
        setErrorDialogOptions({ message: "Editing address failed.", error });
        showErrorDialog(true);
      }
    }
  };

  async function asyncDeleteAddress(index, array) {
    try {
      const addressToDelete = array.find((address) => address === array[index]);
      if (addressToDelete) {
        if (addressToDelete.addressType !== "SHIPPING") {
          await deleteAddress(company.id, addressToDelete.id);
        } else {
          setMessageBarMessage(t("settings.deleteCompanyAddressWarning"));
          setMessageBarType("Warning");
          resetDeleteAddressWarning();
        }
      }
    } catch (error) {
      setErrorDialogOptions({ message: "Delete address failed.", error });
      showErrorDialog(true);
    }

    RefreshCompany(company.id);
  }
  //#endregion

  //#region Add Edit bank account

  function createAddEditBankAccountContextOptions(index, array) {
    return [
      {
        label: t("settings.edit"),
        action: () => openEditBankAccountHandler(index, array),
      },
      {
        label: t("settings.delete"),
        action: () => asyncDeleteBankAccount(index, array),
      },
    ];
  }

  function resetEditBankAccount() {
    setMissingFields([]);
    setBankAccountToEdit(0);
    setBankAccountName("");
    setBankAccountNr("");
    setBankAccountIban("");
  }

  const openAddBankAccountHandler = () => {
    resetEditBankAccount();
    setIsNewBankAccount(true);
    setIsAddEditBankAccountOpen(true);
  };

  function closeEditBankAccount() {
    setIsAddEditBankAccountOpen(false);
    setValidationErrorsBankAccount({});
  }

  const openEditBankAccountHandler = (index, array) => {
    resetEditBankAccount();
    setIsNewBankAccount(false);

    const bankAccountToEdit = array.find((account) => account === array[index]);
    setBankAccountToEdit(bankAccountToEdit.id);
    setBankAccountName(bankAccountToEdit.name);
    setBankAccountNr(bankAccountToEdit.accountNo);
    setBankAccountIban(bankAccountToEdit.iban);

    setIsAddEditBankAccountOpen(true);
  };

  const addEditBankAccountHandler = async () => {
    if (isNewBankAccount) {
      try {
        const inputCheckerBank = validateFieldsHelper(bankAccountSettingsSchema(t), {
          name: bankAccountName,
          accountNo: bankAccountNr,
          iban: bankAccountIban,
        });
        setValidationErrorsBankAccount(inputCheckerBank);

        if (Object.keys(inputCheckerBank).length === 0) {
          await postNewBankAccount(company.id, bankAccountName, bankAccountNr, bankAccountIban);
          setValidationErrorsBankAccount([]);
          RefreshCompany(company.id);
          setIsAddEditBankAccountOpen(false);
        }
      } catch (error) {
        setErrorDialogOptions({
          message: "Adding new bank account failed.",
          error,
        });
        showErrorDialog(true);
      }
    } else {
      try {
        const inputCheckerBank = validateFieldsHelper(bankAccountSettingsSchema(t), {
          name: bankAccountName,
          accountNo: bankAccountNr,
          iban: bankAccountIban,
        });
        setValidationErrorsBankAccount(inputCheckerBank);

        if (Object.keys(inputCheckerBank).length === 0) {
          await updateBankAccount(company.id, bankAccountToEdit, bankAccountName, bankAccountNr, bankAccountIban);
          setValidationErrorsBankAccount([]);
          RefreshCompany(company.id);
          setIsAddEditBankAccountOpen(false);
        }
      } catch (error) {
        setErrorDialogOptions({
          message: "Editing bank account failed.",
          error,
        });
        showErrorDialog(true);
      }
    }
  };

  async function asyncDeleteBankAccount(index, array) {
    try {
      const bankAccountToDelete = array.find((account) => account === array[index]);
      if (bankAccountToDelete) await deleteBankAccount(company.id, bankAccountToDelete.id);
    } catch (error) {
      setErrorDialogOptions({ message: "Delete bank account failed.", error });
      showErrorDialog(true);
    }

    RefreshCompany(company.id);
  }
  //#endregion

  return (
    <div className="company-settings">
      <Grid className="details-card">
        <Grid.Row>
          <Grid.Col span={12}>
            <h3>{t("common.details").toLocaleUpperCase()}</h3>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={4}>
            <TextField
              id="company-name"
              label={t("common.companyName")}
              value={get(company, "companyName", "")}
              onChange={(e) => onChange({ companyName: e.target.value })}
              errormessage={validationErrors.companyName}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TextField
              id="email"
              label={t("common.email")}
              value={get(company, "email", "")}
              onChange={(e) => onChange({ email: e.target.value })}
              errormessage={validationErrors.email}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TextField
              id="invoicemail"
              label={t("common.invoiceemail")}
              value={get(company, "invoiceemail", "")}
              onChange={(e) => onChange({ invoiceemail: e.target.value })}
              errormessage={validationErrors.invoiceemail}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={4}>
            <TextField
              id="tradingemail"
              label={t("common.tradingemail")}
              value={get(company, "tradingemail", "")}
              onChange={(e) => onChange({ tradingemail: e.target.value })}
              errormessage={validationErrors.tradingemail}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TextField
              id="vat-number"
              label={t("common.vatNumber")}
              value={get(company, "vatNumber", "") || ""}
              onChange={(e) => onChange({ vatNumber: e.target.value })}
              errormessage={validationErrors.vatNumber}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TextField
              id="lei-number"
              label={t("common.leiNumber")}
              value={get(company, "leiNumber", "") || ""}
              onChange={(e) => onChange({ leiNumber: e.target.value })}
              errormessage={validationErrors.leiNumber}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={4}>
            <PhoneField
              id="phone"
              value={get(company, "phone")}
              placeholder={t("common.phone")}
              onChange={(e) => onChange({ phone: e })}
              errormessage={validationErrors.phone}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Dropdown
              id="company-type"
              label={t("common.companyType")}
              options={companyTypesOptions}
              onChange={onChangeCompanyType}
              value={selectedCompanyType}
              errormessage={validationErrors.companyType}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TextField
              id="exportlicense"
              label={t("common.exportlicense")}
              value={get(company, "exportLicense", "") || ""}
              onChange={(e) => onChange({ exportLicense: e.target.value })}
              errormessage={validationErrors.exportLicense}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={4}>
            <Dropdown
              id="language"
              label={t("common.language")}
              options={languageOptions}
              onChange={onChangeLanguage}
              value={selectedLanguage}
              errormessage={validationErrors.language}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Dropdown
              id="timezone"
              label={t("common.timeZone")}
              options={timeZoneOptions}
              onChange={onChangeTimeZone}
              value={selectedTimeZone}
              errormessage={validationErrors.timeZone}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={4}>
            <Dropdown
              id="currency"
              label={t("common.currency")}
              options={currencyOptions}
              onChange={onChangeCurrency}
              value={selectedCurrency}
              errormessage={validationErrors.currency}
            />
          </Grid.Col>
          {company && company.membership !== MembershipTypeEnum.Admin && (
            <Grid.Col span={4}>
              <Dropdown
                id="membership"
                label={t("common.membershipType")}
                options={membershipTypeOptions}
                onChange={onChangeMembershipType}
                value={selectedMembershipType}
              />
            </Grid.Col>
          )}
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={12}>
            <CheckBox
              id="allowCollectiveInvoice"
              label={t("common.allowCollectiveInvoice")}
              checked={get(company, "allowCollectiveInvoice", false)}
              onChange={(e) => onChange({ allowCollectiveInvoice: e.checked })}
            />
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col span={12}></Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={8} className="flex align">
            <div className="flex col">
              <h3 className="mbottom20">{t("settings.transport")}</h3>
              <CheckBox
                id="handle-transport"
                label={t("settings.handleTransport")}
                checked={get(company, "withoutTransport", false)}
                onChange={(e) => onChange({ withoutTransport: e.checked })}
              />
            </div>
          </Grid.Col>
          <Grid.Col span={4}>
            <Dropdown
              id="transport-company"
              label={t("settings.defaultTransport")}
              isDisabled={get(company, "withoutTransport", false)}
              options={transportOptions}
              onChange={onChangeTransportCompany}
              value={selectedTransportCompany}
              isClearable
              clearValue={() => onChangeTransportCompany(null)}
              errormessage={validationErrorsTransport.preferredTransportCompanyId}
            />
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col span={12}>
            <h3>{t("dashboard.invoice").toUpperCase()}</h3>
          </Grid.Col>
          <Grid.Col span={8}>
            <CheckBox
              id="prefer-invoice"
              label={t("settings.preferInvoice")}
              checked={get(company, "alwaysInvoice", false)}
              onChange={(e) => onChange({ alwaysInvoice: e.checked })}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row className="mtop30 mbottom30">
          <Grid.Col span={12}>
            <Spinner loading={loadingSave} inline={true} align="center" />
            <BlueButton
              id="save"
              text={t("common.save")}
              buttontype={BlueButton.buttontype.DarkBlue}
              onClick={saveHandler}
            />
          </Grid.Col>
        </Grid.Row>
      </Grid>
      <Grid className="mtop20">
        <Grid.Row>
          <Grid.Col span={12} className="flex align">
            <h3>{t("common.address").toLocaleUpperCase()}</h3>
            <div className="flex right">
              <BlueButton
                id="add-address"
                text={t("common.address")}
                buttontype={BlueButton.buttontype.DarkBlue}
                svgname="pluss-square"
                onClick={openAddAddressHandler}
              />
            </div>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={12}>
            {company && (
              <table className="lightblue">
                <thead>
                  <tr>
                    <th scope="col">{t("common.address")}</th>
                    <th scope="col">{t("common.postCode")}</th>
                    <th scope="col">{t("common.place")}</th>
                    <th scope="col">{t("common.country")}</th>
                    <th scope="col">{t("common.addressType")}</th>
                    <th>{t("settings.actions")}</th>
                  </tr>
                </thead>

                <tbody>
                  {company.companyAddress.map((address, index, array) => (
                    <tr key={`address-row-${index}`}>
                      <td data-label={t("common.address")}>{address.address}</td>
                      <td data-label={t("common.postCode")}>{address.postCode}</td>
                      <td data-label={t("common.place")}>{address.place}</td>
                      <td data-label={t("common.country")}>{address.country.name}</td>
                      <td data-label={t("common.addressType")}>
                        {t("registerCompany.addressTypes." + address.addressType)}
                      </td>
                      <td data-label="Actions">
                        <ContextPanel
                          options={createAddEditAddressContextOptions(index, array)}
                          contextType={ContextPanel.contextType.Secondary}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {messageBarMessage ? <MessageBar message={messageBarMessage} type={messageBarType}></MessageBar> : null}
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col span={12} className="flex align">
            <h3>{t("settings.bankDetails").toUpperCase()}</h3>
            <div className="flex right">
              <BlueButton
                id="add-address"
                text={"konto"}
                buttontype={BlueButton.buttontype.DarkBlue}
                svgname="pluss-square"
                onClick={openAddBankAccountHandler}
              />
            </div>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={12}>
            {company && (
              <table className="lightblue">
                <thead>
                  <tr>
                    <th scope="col">{t("settings.bankAccountName")}</th>
                    <th scope="col">{t("settings.bankAccountNr")}</th>
                    <th scope="col">{"IBAN"}</th>
                    <th>{t("settings.actions")}</th>
                  </tr>
                </thead>

                <tbody>
                  {company.bankAccount.map((account, index, array) => (
                    <tr key={`bankaccount-row-${index}`}>
                      <td data-label={t("settings.bankAccountName")}>{account.name}</td>
                      <td data-label={t("settings.bankAccountNr")}>{account.accountNo}</td>
                      <td data-label={"IBAN"}>{account.iban}</td>
                      <td data-label="Actions">
                        <ContextPanel
                          options={createAddEditBankAccountContextOptions(index, array)}
                          contextType={ContextPanel.contextType.Secondary}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Grid.Col>
        </Grid.Row>
      </Grid>

      {/* ADD/EDIT ADDRESS */}
      <ModalDialog open={isAddEditAddressOpen} onClose={() => closeEditAddress()} headerText={t("settings.addAddress")}>
        <div className="pad30">
          <TextField
            id="add-address-address"
            textFieldClassName="mbottom10"
            label={t("common.address")}
            onChange={(e) => setAddress(e.target.value)}
            value={address}
            errormessage={validationErrorsAddress.address}
          />
          <TextField
            id="add-address-postCode"
            textFieldClassName="mbottom10"
            label={t("common.postCode")}
            onChange={(e) => setPostCode(e.target.value)}
            value={postCode}
            errormessage={validationErrorsAddress.postCode}
          />
          <TextField
            id="add-address-place"
            textFieldClassName="mbottom10"
            label={t("common.place")}
            onChange={(e) => setPlace(e.target.value)}
            value={place}
            errormessage={validationErrorsAddress.place}
          />
          <Dropdown
            id="add-address-country"
            dropDownClassNames="mbottom10"
            label={t("common.country")}
            options={countryOptions}
            onChange={(e) => setSelectedCountry(e)}
            value={selectedCountry}
            errormessage={validationErrorsAddress.country}
          />
          <Dropdown
            id="add-address-addresstype"
            dropDownClassNames="mbottom10"
            label={t("common.addressType")}
            isDisabled={!isNewAddress}
            options={addressTypeOptions}
            onChange={(e) => setSelectedAddressType(e)}
            value={selectedAddressType}
            errormessage={validationErrorsAddress.addressType}
          />
          {missingFields.length > 0 && <MessageBar type={MessageBar.type.Negative} message={`${missingFields}`} />}
        </div>
        <ModalDialogButtonsWrapper>
          <BlueButton
            id="add-address-cancel"
            text={t("common.cancel")}
            buttontype={BlueButton.buttontype.Secondary}
            onClick={() => closeEditAddress()}
          />
          <BlueButton
            id="add-address-save"
            text={t("common.save")}
            buttontype={BlueButton.buttontype.DarkBlue}
            onClick={addEditAddressHandler}
          />
        </ModalDialogButtonsWrapper>
      </ModalDialog>

      {/* ADD/EDIT BANK ACCOUNTS */}
      <ModalDialog
        open={isAddEditBankAccountOpen}
        onClose={() => closeEditBankAccount()}
        headerText={t("settings.addBankAcc")}
      >
        <div className="pad30">
          <TextField
            id="add-bankAccount-accountName"
            textFieldClassName="mbottom10"
            label={t("settings.bankAccountName")}
            onChange={(e) => setBankAccountName(e.target.value)}
            value={bankAccountName}
            errormessage={validationErrorsBankAccount.name}
          />
          <TextField
            id="add-bankAccount-accountNr"
            textFieldClassName="mbottom10"
            label={t("settings.bankAccountNr")}
            onChange={(e) => setBankAccountNr(e.target.value)}
            value={bankAccountNr}
            errormessage={validationErrorsBankAccount.accountNo}
          />
          <TextField
            id="add-bankAccount-iban"
            label={"IBAN"}
            onChange={(e) => setBankAccountIban(e.target.value)}
            value={bankAccountIban}
            errormessage={validationErrorsBankAccount.iban}
          />
        </div>
        <ModalDialogButtonsWrapper>
          <BlueButton
            id="add-bankAccount-cancel"
            text={t("common.cancel")}
            buttontype={BlueButton.buttontype.Secondary}
            onClick={() => closeEditBankAccount()}
          />
          <BlueButton
            id="add-bankAccount-save"
            text={t("common.save")}
            buttontype={BlueButton.buttontype.DarkBlue}
            onClick={addEditBankAccountHandler}
          />
        </ModalDialogButtonsWrapper>
      </ModalDialog>
    </div>
  );
}
