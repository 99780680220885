export default function applyIntercom(user) {
  if (user) {
    const settings = {
      app_id: "pz8ktirp",
      name: `${user.firstname} ${user.lastname}`,
      email: user.email,
      created_at: String(new Date().getTime()),
    };
    // console.log('<<Set intercom with user>> ', settings);
    window.intercomSettings = settings;
  } else {
    // console.log('<<Set intercom without user>>');
    window.intercomSettings = {
      app_id: "pz8ktirp",
    };
  }

  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/pz8ktirp";
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
}
