import { FC } from "react";

const Close: FC = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L25 25" stroke="currentColor" strokeWidth="2" />
      <path d="M1 25L25 0.999999" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
};

export default Close;
