import { FC, PropsWithChildren } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NavItem from "../../atoms/NavItem";
import styles from "./styles.module.css";

export interface NavigationItem {
  url: string;
  translationsKey: string;
}

interface NavigationProps {
  items: NavigationItem[];
  variant?: "primary" | "secondary";
}

const adminNavItems: NavigationItem[] = [
  {
    url: "/verifyCustomer",
    translationsKey: "common.newApplications",
  },
  {
    url: "/statistics",
    translationsKey: "common.statistics",
  },
  {
    url: "/staleTransactions",
    translationsKey: "staleTransactions.staleTransactions",
  },
  {
    url: "/adminFeedback",
    translationsKey: "commonAdmin.adminFeedback",
  },
];

const mainNavItems: NavigationItem[] = [
  {
    url: "/dashboard",
    translationsKey: "dashboard.dashboard",
  },
  {
    url: "/trade",
    translationsKey: "trade.fishmarket",
  },
  {
    url: "/task",
    translationsKey: "common.tasks",
  },
];

export const BaseNavigation: FC<PropsWithChildren<NavigationProps>> = ({ items, variant }) => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <nav className={`${styles.root} ${variant ? styles[variant] : ""}`}>
      {items.map((item, i) => (
        <NavItem href={item.url} text={t(item.translationsKey)} isActive={location.pathname === item.url} key={i} />
      ))}
    </nav>
  );
};

export const Navigation: FC = (props) => <BaseNavigation items={mainNavItems} variant="primary" />;
export const AdminNavigation: FC = (props) => <BaseNavigation items={adminNavItems} variant="secondary" />;

export default Navigation;
