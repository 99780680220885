import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { getLoginUrl } from "../../services/Login";
import { useTranslation } from "react-i18next";
import { validateFieldsHelper } from "../../utils/ValidationHelper";
import { loginCheckValidationSchema } from "./LoginCheckValidationSchema";

import TextField from "../../components/TextField/TextField";
import BlueButton from "../../components/BlueButton/BlueButton";
import SVG from "../../components/SVGAsset/SVGAsset";

import "./Login.css";

function parseCallbackUrl() {
  const queryString = window.location.search;
  return new URLSearchParams(queryString);
}

export default function LoginCheckEmail(props) {
  const { t } = useTranslation();
  const { setRedirection, setUserContext } = props;
  const [validationErrors, setValidationErrors] = useState([]);

  const params = parseCallbackUrl(window.location.search);
  const externalRef = params.get("externalRef");
  const emailParam = params.get("email") || "";
  const signUp = params.get("signup");
  const local = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
  const port = local ? window.location.host.split(":")[1] || "80" : null;

  async function asyncGetLoginUrl(email, externalRef, port) {
    return await getLoginUrl(email, externalRef, port);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const inputChecker = validateFieldsHelper(loginCheckValidationSchema(t), {
        email: email,
      });
      setValidationErrors(inputChecker);
      if (Object.keys(inputChecker).length === 0) {
        const response = await asyncGetLoginUrl(email, externalRef, port);
        const { type, url } = response.data;

        if (type === "INTERNAL") {
          setUserContext({ email: email });
          setRedirection("INTERNAL");
        } else if (type === "EXTERNAL") {
          setUserContext({ redirectUrl: url });
          setRedirection("EXTERNAL");
        } else if (type === "NEW_USER") {
          setUserContext({ email: email });
          setRedirection("NEW_USER");
        }
      }
    } catch (e) {
      alert("E-post-sjekk feilet: " + e);
    }
  };

  const [email, _setEmail] = useState(emailParam);
  const setEmail = (value) => {
    let email = value;
    if (value) {
      email = value.trim();
    }
    _setEmail(email);
  };

  return (
    <>
      <Helmet>
        <title>Reviver - Login Check email</title>
        <meta name="description" content="Reviver - Login check email" />
      </Helmet>
      <div className="login-container">
        <SVG className="logo" name="fished-logo"></SVG>
        <div className="login-wrapper">
          <h1>{signUp ? t("common.signUp") : t("common.login")}</h1>
          <form onSubmit={handleSubmit}>
            <TextField
              id="txt-loginCheckEmail"
              label={t("common.email")}
              defaultValue={emailParam}
              onChange={(e) => setEmail(e.target.value)}
              errormessage={validationErrors.email}
            />
            <BlueButton id="btn-loginCheckEmail" type="submit" className="submit" text={t("common.login")} />
          </form>
        </div>
      </div>
    </>
  );
}
