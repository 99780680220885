import { axios, APIBASE_REVIVERTRANSPORT } from "./ApiConfig";

export const postCanTransportTo = async (toPlace) =>
  axios.post(`${APIBASE_REVIVERTRANSPORT}api/transport/canTransportTo`, {
    place: toPlace,
  });

export const postCanTransportFrom = async (fromPlace) =>
  axios.post(`${APIBASE_REVIVERTRANSPORT}api/transport/canTransportFrom`, {
    place: fromPlace,
  });
