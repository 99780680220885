import React, { useState, useContext } from "react";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { validateFieldsHelper } from "../../../utils/ValidationHelper";
import { registerCompanyStep4SchemaAddMember } from "../RegisterCompanyValidationSchema";
import HeadingWithInfoText from "../../../components/HeadingWithInfoText/HeadingWithInfoText";
import Grid from "../../../components/Grid/Grid";
import BlueButton from "../../../components/BlueButton/BlueButton";
import TextField from "../../../components/TextField/TextField";
import ModalDialog from "../../ModalDialog/ModalDialog";
import ModalDialogButtonsWrapper from "../../ModalDialog/ModalDialogButtonsWrapper";
import ContextPanel from "../../../components/ContextPanel/ContextPanel";
import Dropdown from "../../../components/Dropdown/Dropdown";
import MessageBar from "../../../components/MessageBar/MessageBar";
import Spinner from "../../../components/Spinner/Spinner";
import UserContext from "../../../contexts/UserContext";

export default function Step4(props) {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { draft, setDraft, clickBackHandler, clickNextHandler } = props;

  const [isSavingDraft, setSavingDraft] = useState(false);
  const [currentUserAdded, setCurrentUserAdded] = useState(false);

  // Team members
  const teamMembers = get(draft, "partialCompany.teamMembers", []);

  const hasItems = teamMembers.length !== 0;
  const hasAdmin = hasItems && teamMembers.find((member) => member.roleType === "ADMIN");
  const isMissingAdmin = hasItems && !hasAdmin;

  const teamRoleTypes = ["ADMIN", "USER", "VIEWER"];

  function teamRoleTypeHandler(e) {
    setSelectedRoleType(e);
    setTeamMemberRole(e.label);
  }

  function getTeamRoleTypeOptions() {
    const types = teamRoleTypes;
    return types.map((label, value) => ({
      value,
      label,
    }));
  }

  const teamRoleTypeOptions = getTeamRoleTypeOptions();
  const [selectedRoleType, setSelectedRoleType] = useState();
  const [newMemberDialogOpen, setNewMemberDialogOpen] = useState(false);
  const [teamMemberFirstName, setTeamMemberFirstName] = useState();
  const [teamMemberLastName, setTeamMemberLastName] = useState();
  const [teamMemberEmail, setTeamMemberEmail] = useState();
  const [teamMemberRole, setTeamMemberRole] = useState();
  const [teamMemberRoleTypeOptions, setTeamMemberRoleTypeOptions] = useState(teamRoleTypeOptions);
  const getTeamRoleOptionByLabel = (label) => teamRoleTypeOptions.find((option) => label === option.label);
  const [editReference, setEditReference] = useState();
  const [validationErrors, setValidationErrors] = useState([]);

  function getUpdatedAdmin(members) {
    const admin = members.find((member) => member.roleType === "ADMIN");
    return admin || null;
  }

  function addNewTeamMember(member) {
    const newTeamMembers = [member].concat(draft.partialCompany.teamMembers);
    const admin = getUpdatedAdmin(newTeamMembers);
    setDraft({
      ...draft,
      partialCompany: {
        ...draft.partialCompany,
        teamMembers: newTeamMembers,
        admin,
      },
    });
  }

  if (!hasItems && !currentUserAdded) {
    addNewTeamMember({
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      roleType: "ADMIN",
    });
    setCurrentUserAdded(true);
  }

  function editNewTeamMember(member, editReference) {
    const copy = editReference.array.filter(() => true);
    copy[editReference.index] = member;
    const admin = getUpdatedAdmin(copy);
    setDraft({
      ...draft,
      partialCompany: {
        ...draft.partialCompany,
        teamMembers: [...copy],
        admin,
      },
    });
  }

  function deleteNewTeamMember(index, array) {
    const remainingTeamMembers = array.filter((item) => item !== array[index]);
    const admin = getUpdatedAdmin(remainingTeamMembers);
    setDraft({
      ...draft,
      partialCompany: {
        ...draft.partialCompany,
        teamMembers: remainingTeamMembers,
        admin,
      },
    });
  }

  async function addNewMemberToTeam() {
    const inputChecker = validateFieldsHelper(registerCompanyStep4SchemaAddMember(t), {
      firstname: teamMemberFirstName,
      lastname: teamMemberLastName,
      email: teamMemberEmail,
      roleType: teamMemberRole,
    });
    setValidationErrors(inputChecker);

    if (Object.keys(inputChecker).length === 0) {
      addNewTeamMember({
        firstname: teamMemberFirstName,
        lastname: teamMemberLastName,
        email: teamMemberEmail,
        roleType: teamMemberRole,
      });
      setNewMemberDialogOpen(false);
    }
  }

  async function editMemberOnTheTeam(editReference) {
    const inputChecker = validateFieldsHelper(registerCompanyStep4SchemaAddMember(t), {
      firstname: teamMemberFirstName,
      lastname: teamMemberLastName,
      email: teamMemberEmail,
      roleType: teamMemberRole,
    });
    setValidationErrors(inputChecker);

    if (Object.keys(inputChecker).length === 0) {
      editNewTeamMember(
        {
          firstname: teamMemberFirstName,
          lastname: teamMemberLastName,
          email: teamMemberEmail,
          roleType: teamMemberRole,
        },
        editReference
      );
      setNewMemberDialogOpen(false);
    }
  }

  function openAddNewTeamMemberDialog() {
    const options = getTeamRoleTypeOptions();
    const defaultRoleType = options[0];
    setEditReference(null);
    setTeamMemberFirstName("");
    setTeamMemberLastName("");
    setTeamMemberEmail("");
    setTeamMemberRole(defaultRoleType.label);
    setSelectedRoleType(defaultRoleType);
    setTeamMemberRoleTypeOptions(options);
    setNewMemberDialogOpen(true);
  }

  function openEditNewTeamMemberDialog(index, array) {
    const teamMember = array[index];
    const thisRoleType = getTeamRoleOptionByLabel(teamMember.roleType);
    setEditReference({ index, array });
    setTeamMemberFirstName(teamMember.firstname);
    setTeamMemberLastName(teamMember.lastname);
    setTeamMemberEmail(teamMember.email);
    setTeamMemberRole(thisRoleType.label);
    setSelectedRoleType(thisRoleType);
    setTeamMemberRoleTypeOptions(getTeamRoleTypeOptions());
    setNewMemberDialogOpen(true);
  }

  function closeMemberDialog() {
    setValidationErrors({});
    setNewMemberDialogOpen(false);
  }

  function createContextOptions(index, array) {
    return [
      {
        label: "Rediger",
        action: () => openEditNewTeamMemberDialog(index, array),
      },
      {
        label: "Slett",
        action: () => deleteNewTeamMember(index, array),
      },
    ];
  }

  async function nextHandler() {
    setSavingDraft(true);
    await clickNextHandler();
  }

  async function backHandler() {
    setSavingDraft(true);
    await clickBackHandler();
  }

  return (
    <Grid className="wizard-container-border-top nogap">
      <Grid.Row>
        <Grid.Col span={4} className="wizard-container-info-area">
          <HeadingWithInfoText header={t("step4.infoHeader")} showInfo={true} infoText={t("step4.infoText")} />
        </Grid.Col>
        <Grid.Col span={8} className="content-container">
          <div className="content-row">
            <table className="lines">
              <thead>
                <tr>
                  <th scope="col" colSpan="2">
                    {t("common.user")}
                  </th>
                  <th scope="col" colSpan="2">
                    {t("common.email")}
                  </th>
                  <th scope="col">{t("common.role")}</th>
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody>
                {teamMembers.map((member, index, array) => (
                  <tr key={`table-${index}`}>
                    <td data-label={t("common.user")} colSpan="2">
                      {member.firstname} {member.lastname}
                    </td>
                    <td data-label={t("common.email")} className="break-word" colSpan="2">
                      {member.email}
                    </td>
                    <td data-label={t("common.role")}>{member.roleType}</td>
                    <td>
                      <ContextPanel options={createContextOptions(index, array)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {isMissingAdmin && (
            <MessageBar type={MessageBar.type.Warning} message={t("step4.message")} className="team-member-warning" />
          )}

          <div className="content-row">
            <BlueButton
              id="add-new-team-member"
              text={t("step4.button")}
              onClick={() => openAddNewTeamMemberDialog()}
              buttontype={BlueButton.buttontype.PlusSecondary}
            />
          </div>

          <ModalDialog
            open={newMemberDialogOpen}
            onClose={() => {
              closeMemberDialog();
            }}
          >
            <div className="pad30">
              <TextField
                id="new-team-member-firstname"
                label={t("step4.name")}
                value={teamMemberFirstName}
                onChange={(e) => setTeamMemberFirstName(e.target.value)}
                errormessage={validationErrors.firstname}
              />
              <TextField
                id="new-team-member-lastname"
                label={t("step4.surname")}
                value={teamMemberLastName}
                onChange={(e) => setTeamMemberLastName(e.target.value)}
                errormessage={validationErrors.lastname}
              />
              <TextField
                id="new-team-member-email"
                label={t("step4.email")}
                value={teamMemberEmail}
                onChange={(e) => setTeamMemberEmail(e.target.value)}
                errormessage={validationErrors.email}
              />
              <Dropdown
                id="team-role-type-select"
                label={t("step4.role")}
                options={teamMemberRoleTypeOptions}
                onChange={teamRoleTypeHandler}
                value={selectedRoleType}
                errormessage={validationErrors.roleType}
              />
            </div>
            <ModalDialogButtonsWrapper>
              {!editReference && (
                <BlueButton
                  id="add-new-team-member"
                  text={t("step4.add")}
                  onClick={() => {
                    addNewMemberToTeam();
                  }}
                  buttontype={BlueButton.buttontype.Secondary}
                />
              )}
              {!!editReference && (
                <BlueButton
                  id="edit-new-team-member"
                  text={t("step4.save")}
                  onClick={() => {
                    editMemberOnTheTeam(editReference);
                  }}
                  buttontype={BlueButton.buttontype.Secondary}
                />
              )}
            </ModalDialogButtonsWrapper>
          </ModalDialog>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col push={5} span={8} className="action-buttons">
          <Spinner loading={isSavingDraft} inline={true} />
          <BlueButton
            id="wizard-step4-previous"
            buttontype={BlueButton.buttontype.Secondary}
            text={t("registerCompany.back")}
            onClick={backHandler}
          />
          <BlueButton
            id="wizard-step4-next"
            text={t("registerCompany.next")}
            onClick={nextHandler}
            disabled={isMissingAdmin}
          />
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
}
