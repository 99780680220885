//import PropTypes from 'prop-types'
import "./MediumPage.css";

function MediumPage(props) {
  return (
    <div {...props} className={`medium-page ${props.className || ""}`}>
      {props.children}
    </div>
  );
}

// TODO: impl proptypes
export default MediumPage;
