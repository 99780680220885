import { axios, APIBASE_REVIVERMESSAGING } from "./ApiConfig";

export const postUserMadeAdvertisement = async (recipients, orderIds, senderCompanyName) =>
  axios
    .post(`${APIBASE_REVIVERMESSAGING}api/messaging/send/userMadeAdvertisement/email`, {
      recipients: recipients,
      orders: orderIds,
      sender: senderCompanyName,
    })
    .then((response) => {
      return response;
    })
    .catch((response) => {
      return response.response;
    });
