import Joi from "joi";

export function orderTemplateSchema(translation) {
  const t = translation;

  return Joi.object({
    templateName: Joi.string()
      .required()
      .messages({
        "string.required": t("validation.orderTemplateNameRequired"),
        "string.empty": t("validation.orderTemplateNameRequired"),
      }),
    salesItemId: Joi.number()
      .required()
      .messages({
        "number.base": t("validation.salesItemRequired"),
        "any.required": t("validation.salesItemRequired"),
      }),
    conditionId: Joi.number()
      .required()
      .messages({
        "number.base": t("validation.conditionRequired"),
        "any.required": t("validation.conditionRequired"),
      }),
    sizeId: Joi.number()
      .required()
      .messages({
        "number.base": t("validation.sizeRequired"),
        "any.required": t("validation.sizeRequired"),
      }),
  }).unknown();
}
