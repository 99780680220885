import React, { useState } from "react";
import { get } from "lodash";
import { useTranslation } from "react-i18next";

import SVGAsset from "../../../SVGAsset/SVGAsset";
import Moment from "react-moment";
import ModalDialog from "../../../../containers/ModalDialog/ModalDialog";
import ModalDialogButtonsWrapper from "../../../../containers/ModalDialog/ModalDialogButtonsWrapper";
import BlueButton from "../../../BlueButton/BlueButton";
import OrderTypeEnum from "../../../../constants/enums/OrderTypeEnum";
import CTA from "../../atoms/CTA";
import Text from "../../atoms/Text";

import styles from "./styles.module.css";

export default function ActiveOrderBox(props) {
  const { t } = useTranslation();
  const { order, handleActiveOrder, deleteOrder, orderTypeIndicator } = props;

  const [deleteOrderConfirmationConfig, setDeleteOrderConfirmationConfig] = useState({
    isOpen: false,
    text: "",
  });

  function handleDetails() {
    handleActiveOrder(order);
  }

  function handleCancel() {
    setDeleteOrderConfirmationConfig({
      isOpen: true,
      text: t("commonOrders.cancelTextConfirmation"),
    });
  }

  function cancelOrder() {
    deleteOrder(order.id);
    closeCancelConfirmation();
  }

  function closeCancelConfirmation() {
    setDeleteOrderConfirmationConfig({ isOpen: false, text: "" });
  }

  return (
    <>
      <ModalDialog
        open={deleteOrderConfirmationConfig.isOpen}
        onClose={() => closeCancelConfirmation()}
        headerText={t("common.confirm")}
      >
        <p className="pad30">{deleteOrderConfirmationConfig.text}</p>
        <ModalDialogButtonsWrapper>
          <BlueButton
            id="delete-order-cancel"
            buttontype={BlueButton.buttontype.Secondary}
            onClick={() => closeCancelConfirmation()}
            text={t("common.cancel")}
          />
          <BlueButton id="delete-order-confirm" onClick={() => cancelOrder()} text={t("common.accept")} />
        </ModalDialogButtonsWrapper>
      </ModalDialog>
      {/* <div className="active-order-box"> // FOR FUTURE REFERENCE
        <div className="header">
          <div className="icon-type">
            <SVGAsset name={orderTypeIndicator} />
            <span className="type">{t("common.orderType." + order.orderType.type).toUpperCase()}</span>
          </div>

        </div>

        <div className="data">
          <div className="amount">
            {get(order, "amount", "")} {get(order, "unit.unit", "").toUpperCase()}.
          </div>
          <div className="expiry-date">{`${t("dashboard.remainingAmount").toUpperCase()}: ${get(order, "remainingAmount", "")} ${get(order, "unit.unit", "")}`}</div>
          <div className="expiry-date">
            {t("commonOrders.availableUntil").toUpperCase()}{" "}
            <span className="date">
              <Moment format="DD.MM.YYYY">{order.validTo}</Moment>
            </span>
          </div>
        </div>
        </div>
      </div>
    </div> */}
      <section className={styles.root}>
        <header className={styles.productName}>
          <SVGAsset name="plastic-types/ldps" />
          <Text size="xs" display="title" strong={true}>
            {t("common.fish." + order.salesItem.salesItem)}
          </Text>
        </header>
        <div className={styles.productDetails}>
          <Text size="lg" strong={true} tt="uppercase">
            {get(order, "company.companyName", "")}
          </Text>
          <ul className={styles.productSpecs}>
            <li>
              <Text size="xs" strong={true} tt="uppercase">
                Origin
              </Text>
              <Text size="xs" tt="uppercase">
                Oslo
              </Text>
            </li>
            <li>
              <Text size="xs" tt="uppercase" strong={true}>
                Weight
              </Text>
              <Text size="xs" tt="uppercase">
                {get(order, "amount", "")} {get(order, "unit.unit", "")}
              </Text>
            </li>
          </ul>
        </div>
        <div className={styles.ctas}>
          <CTA intent="secondary" onClick={handleCancel}>
            {t("commonOrders.cancel")}
          </CTA>
          <CTA intent="primary" onClick={handleDetails}>
            {t("common.details")}
          </CTA>
        </div>
      </section>
    </>
  );
}
