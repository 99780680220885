import React, { useEffect, useContext, useState } from "react";
import { getExternalToken, getCompaniesByUserId } from "../../services/Login";
import SVG from "../../components/SVGAsset/SVGAsset";
import { useHistory } from "react-router-dom";
import { ErrorDialogContext } from "../../contexts/ErrorDialogContext";
import Spinner from "../../components/Spinner/Spinner";
import Cookie from "universal-cookie";

import "./Login.css";

function parseCallbackUrl() {
  const queryString = window.location.search;
  return new URLSearchParams(queryString);
}

export default function ExternalAuthSession(props) {
  const history = useHistory();
  const { setToken, setRedirection, setUserContext, userContext, setLocaleOnLogin } = props;
  const errorDialogContext = useContext(ErrorDialogContext);
  const { showErrorDialog, setErrorDialogOptions } = errorDialogContext;

  const redirectUrl = userContext && userContext.redirectUrl;
  const params = parseCallbackUrl(window.location.search);
  const authProviderValue = params.get("sessionId") || params.get("code");
  const externalRef = params.get("externalRef") || params.get("state");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function asyncGetExternalToken() {
      setLoading(true);
      const local = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
      const port = local ? window.location.host.split(":")[1] || "80" : null;

      let user, companies, currentCompany;
      try {
        const response = await getExternalToken(authProviderValue, externalRef, port); // user
        user = response.data;
        const headers = response.headers;
        const authHeader = headers.authorization;
        setToken(authHeader);
      } catch (error) {
        setErrorDialogOptions({
          message: "Henting av ekstern token feilet.",
          error,
        });
        showErrorDialog(true);
      }

      try {
        const companyResponse = await getCompaniesByUserId(user.id, user.role[0].companyId);
        companies = companyResponse.data;
        currentCompany = companies.companies[0];
      } catch (error) {
        setErrorDialogOptions({
          message: "Henting av selskap feilet.",
          error,
        });
        showErrorDialog(true);
      }

      if (user) {
        setUserContext({
          user,
          companies,
          currentCompany,
        });
        setLocaleOnLogin(user);

        // Reset company select on every login
        const cookies = new Cookie();
        const cookie = cookies.get("choosenCompany");
        if (cookie) cookies.remove("choosenCompany", { path: "/" });

        setRedirection(null);
        history.push("/");
      } else setLoading(false);
    }

    if (authProviderValue) {
      asyncGetExternalToken();
    } else if (redirectUrl) {
      sessionStorage.removeItem("userContext");
      window.location = redirectUrl;
    } else {
      setRedirection(null);
    }
  }, [
    authProviderValue,
    externalRef,
    history,
    setToken,
    setRedirection,
    setUserContext,
    setErrorDialogOptions,
    showErrorDialog,
    redirectUrl,
    setLocaleOnLogin,
  ]);

  return (
    <div className="login-container">
      <Spinner loading={loading} />
      <SVG className="logo" name="fished-logo"></SVG>
      {!!authProviderValue && <h3>Logging in...</h3>}
      {!authProviderValue && <h3>Redirecting...</h3>}
    </div>
  );
}
