import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { registerCompanyStep6SchemaTerms } from "../RegisterCompanyValidationSchema";
import { validateFieldsHelper } from "../../../utils/ValidationHelper";
import CheckBox from "../../../components/Checkbox/Checkbox";
import Grid from "../../../components/Grid/Grid";
import HeadingWithInfoText from "../../../components/HeadingWithInfoText/HeadingWithInfoText";
import BlueButton from "../../../components/BlueButton/BlueButton";
import Spinner from "../../../components/Spinner/Spinner";
import SVGAsset from "../../../components/SVGAsset/SVGAsset";

import "./Step6.css";

export default function Step6(props) {
  const { t } = useTranslation();
  const { onChange, submit, clickBackHandler } = props;
  const [isSavingDraftOrSubmitting, setSavingDraftOrSubmitting] = useState(false);
  const [terms, setTerms] = useState(props.terms || false);
  const [validationErrors, setValidationErrors] = useState([]);

  function toggleTerms(e) {
    const checked = e.checked;
    onChange({
      terms: checked,
    });
    setTerms(checked);
    setValidationErrors({});
  }

  function getTermsPath() {
    return "/terms/" + t("step6.pdf");
  }

  async function backHandler() {
    setSavingDraftOrSubmitting(true);
    await clickBackHandler();
  }

  async function completeWizard() {
    const inputChecker = validateFieldsHelper(registerCompanyStep6SchemaTerms(t), { terms: terms });
    setValidationErrors(inputChecker);

    if (Object.keys(inputChecker).length === 0) {
      setSavingDraftOrSubmitting(true);
      try {
        await submit();
      } finally {
        setSavingDraftOrSubmitting(false);
      }
    }
  }

  return (
    <div>
      <Grid className="nogap mtop30">
        <Grid.Row>
          <Grid.Col span={12} className="flex">
            <div className="flex align gap30 center tos-container">
              <SVGAsset name="signature" />
              <div className="flex col">
                <HeadingWithInfoText
                  className="mbottom20"
                  header={t("step6.infoHeader")}
                  showInfo={true}
                  infoText={t("step6.infoText")}
                />
                <CheckBox onChange={(e) => toggleTerms(e)} checked={terms} label={t("step6.checkboxText")} />
                {<div className="error-message">{validationErrors.terms}</div>}
                <a className="tos-text" href={getTermsPath()} target="_blank" rel="noreferrer">
                  {t("step6.linkText")}
                </a>
              </div>
            </div>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={12} className="flex right gap20 pad20">
            <Spinner loading={isSavingDraftOrSubmitting} inline={true} />
            <BlueButton
              id="wizard-step6-previous"
              buttontype={BlueButton.buttontype.Secondary}
              text={t("registerCompany.back")}
              onClick={backHandler}
            />
            <BlueButton id="wizard-step6-complete" text={t("registerCompany.submit")} onClick={completeWizard} />
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </div>
  );
}
