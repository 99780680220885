import Joi from "joi";

export function teamSettingsSchema(translation) {
  const t = translation;

  return Joi.object({
    firstname: Joi.string()
      .max(100)
      .required()
      .messages({
        "string.empty": t("validation.firstNameRequired"),
        "any.required": t("validation.firstNameRequired"),
        "string.max": t("validation.firstNameMaxLength"),
      }),
    lastname: Joi.string()
      .max(100)
      .required()
      .messages({
        "string.empty": t("validation.lastNameRequired"),
        "any.required": t("validation.lastNameRequired"),
        "string.max": t("validation.lastNameMaxLength"),
      }),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .max(100)
      .required()
      .messages({
        "string.empty": t("validation.emailRequired"),
        "string.email": t("validation.emailFormatError"),
        "any.required": t("validation.emailRequired"),
        "string.max": t("validation.emailMaxLength"),
      }),
    roleType: Joi.string()
      .required()
      .messages({
        "string.empty": t("validation.roleTypeRequired"),
        "any.required": t("validation.roleTypeRequired"),
      }),
  }).unknown();
}

export function editRoleSchema(translation) {
  const t = translation;

  return Joi.object({
    roleType: Joi.string()
      .required()
      .messages({
        "string.empty": t("validation.roleTypeRequired"),
        "any.required": t("validation.roleTypeRequired"),
      }),
  }).unknown();
}
