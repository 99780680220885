import React from "react";

import { Route, Redirect } from "react-router-dom";

export default function ProtectedRoute(props) {
  const { isAdmin } = props;
  return (
    <Route
      render={() => {
        return isAdmin ? props.children : <Redirect to="/trade" />;
      }}
    ></Route>
  );
}
