import React from "react";
import PropTypes from "prop-types";
import SVGAsset from "../../../components/SVGAsset/SVGAsset";

import "./CreateOrderModal.css";

export default function CreateOrderModal(props) {
  const { open, onClose, headerText, svg } = props;

  function closeDialog() {
    if (onClose) {
      onClose();
    }
  }

  return (
    <>
      {!!open && (
        <div className={`create-order-modal-container ${props.className || ""}`}>
          <div className="create-order-modal-content">
            <div className="create-order-modal-header">
              <div className="flex align gap10">
                <SVGAsset name={svg} />
                <h2>{headerText}</h2>
              </div>
              <div className="close-dialog" onClick={() => closeDialog()}>
                <SVGAsset className="close-x clickable" name="close-white"></SVGAsset>
              </div>
            </div>
            <div className="flex">{props.children}</div>
          </div>
        </div>
      )}
    </>
  );
}

CreateOrderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};
