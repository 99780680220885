const MembershipTypeEnum = {
  Bronze: "BRONZE",
  Silver: "SILVER",
  Gold: "GOLD",
  Admin: "ADMIN",
};

Object.freeze(MembershipTypeEnum);

export default MembershipTypeEnum;
