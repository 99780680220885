import React, { useEffect, useState } from "react";

import "./DebugTools.css";

export default function DebugTools(props) {
  const [isVisible, setIsVisible] = useState();

  useEffect(() => {
    let throttle = false;

    const handler = (evt) => {
      if (!throttle && evt.detail === 3) {
        setIsVisible(!isVisible);
        throttle = true;
        setTimeout(function () {
          throttle = false;
        }, 1000);
      }
    };

    document.body.addEventListener("click", handler);

    const cleanup = () => {
      document.body.removeEventListener("click", handler);
    };

    return cleanup;
  }, [isVisible, setIsVisible]);

  if (props.off) return null;

  return <>{isVisible && <div className="debug-tools">My debug tools!</div>}</>;
}
