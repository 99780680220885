import React from "react";

import "./StepLabel.css";

function stepLabel(props) {
  const { titleStatus, label, notStep, polySansLabel } = props;
  return (
    <div>
      <div className="container">
        {!notStep && <span className={"status-indicator " + titleStatus}></span>}
        {polySansLabel && <label className={"font-family-polysans label " + titleStatus}>{label}</label>}
        {!polySansLabel && <label className={"label " + titleStatus}>{label}</label>}
      </div>
    </div>
  );
}

export default stepLabel;
