import { default as common } from "./common.json";
import { default as dashboard } from "./dashboard.json";
import { default as registerCompany } from "./registerCompany.json";
import { default as verifyCustomer } from "./verifyCustomer.json";
import { default as commonOrders } from "./commonOrders.json";
import { default as commonLoginAndUsers } from "./commonLoginAndUsers.json";
import { default as settings } from "./settings.json";
import { default as trade } from "./trade.json";
import { default as step1 } from "./step1.json";
import { default as step3 } from "./step3.json";
import { default as step4 } from "./step4.json";
import { default as step5 } from "./step5.json";
import { default as step6 } from "./step6.json";
import { default as commonAuction } from "./commonAuction.json";
import { default as charts } from "./charts.json";
import { default as statistics } from "./statistics.json";
import { default as staleTransactions } from "./staleTransactions.json";
import { default as documents } from "./documents.json";
import { default as validation } from "./validation.json";
import { default as commonComponents } from "./commonComponents.json";
import { default as frontPage } from "./frontPage.json";
import { default as tasks } from "./tasks.json";
import { default as commonAdmin } from "./commonAdmin.json";

const nb = {
  common,
  dashboard,
  registerCompany,
  step1,
  step3,
  step4,
  step5,
  step6,
  verifyCustomer,
  commonOrders,
  commonLoginAndUsers,
  settings,
  trade,
  commonAuction,
  charts,
  statistics,
  staleTransactions,
  documents,
  validation,
  commonComponents,
  frontPage,
  tasks,
  commonAdmin,
};

export default nb;
