export function calcNumberOfPallets(nrOfBoxes, boxesOnPallet) {
  // Calculate number of pallets rounded to the closes half
  let numberOfPalletsRaw = nrOfBoxes / boxesOnPallet;
  let numberOfPalletsRounded = Math.ceil(numberOfPalletsRaw * 2) / 2;

  // When less then 2 pallets, they only operate with wholes
  if (numberOfPalletsRounded < 2) return Math.ceil(numberOfPalletsRounded);
  else return numberOfPalletsRounded;
}

export function calcMinAmountOfBoxes(salesItemAmount, boxtypeMaxWeight) {
  const numberOfBoxesRaw = salesItemAmount / boxtypeMaxWeight;
  const numberOfBoxesRounded = Math.ceil(numberOfBoxesRaw);
  return numberOfBoxesRounded;
}
