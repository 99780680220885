import React, { useState, useEffect, useContext } from "react";
import Grid from "../../../components/Grid/Grid";
import BlueButton from "../../../components/BlueButton/BlueButton";
import Textfield from "../../../components/TextField/TextField";
import CheckBox from "../../../components/Checkbox/Checkbox";
import Dropdown from "../../../components/Dropdown/Dropdown";
import UserContext from "../../../contexts/UserContext";
import Spinner from "../../../components/Spinner/Spinner";
import PhoneField from "../../../components/PhoneInput/PhoneInput";

import { ErrorDialogContext } from "../../../contexts/ErrorDialogContext";
import { getLanguages } from "../../../services/RegisterCompany";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import { getUserById } from "../../../services/Login";
import { updateUser } from "../../../services/Settings";
import { validateFieldsHelper } from "../../../utils/ValidationHelper";
import { accountSettingsSchema } from "./AccountSettingsValidationSchemas";

import "./AccountSettings.css";

export default function AccountSettings(props) {
  const { i18n, t } = useTranslation();

  const { changeLocale } = props;

  const errorDialogContext = useContext(ErrorDialogContext);
  const { showErrorDialog, setErrorDialogOptions } = errorDialogContext;
  const userContext = useContext(UserContext);
  const { user, currentCompany } = userContext;

  const [languageOptions, setLanguageOptions] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [currentUser, setCurrentUser] = useState([]);
  const [loadingSave, setLoadingSave] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  useEffect(() => {
    async function asyncGetUser() {
      const response = await getUserById(user.id);
      setCurrentUser(response.data);
    }

    // Language dropdown
    async function loadLanguages() {
      const response = await getLanguages();
      let languageOptions = response.data;

      const _languageOptions = languageOptions.languages.map((language) => ({
        value: language.id,
        label: language.name,
        languageCode: language.languageCode,
      }));
      setLanguageOptions(_languageOptions);
    }

    loadLanguages();
    asyncGetUser();
  }, [user.id]);

  useEffect(() => {
    // prefill language
    const _language = languageOptions.find((option) => option.value === get(user, "language.id"));
    setSelectedLanguage(_language);
  }, [user, languageOptions]);

  function onChange(change) {
    setCurrentUser({
      ...currentUser,
      ...change,
    });
  }

  function onChangeLanguage(e) {
    setSelectedLanguage(e);
    changeLocale(e.languageCode, i18n, currentCompany && currentCompany.companyTimeZone);
    const language = e;
    onChange({
      language: {
        id: language.value,
        name: language.label,
        languageCode: language.languageCode,
      },
    });
  }

  const saveHandler = async () => {
    try {
      setLoadingSave(true);
      const inputChecker = validateFieldsHelper(accountSettingsSchema(t), currentUser);
      setValidationErrors(inputChecker);
      if (Object.keys(inputChecker).length === 0) {
        setValidationErrors([]);
        await updateUser(
          currentUser.id,
          currentUser.firstname,
          currentUser.lastname,
          currentUser.email,
          currentUser.phone,
          currentUser.language.id,
          currentUser.receiveCopyOfTradingNotifications
        );
      }
    } catch (error) {
      setErrorDialogOptions({ message: "Update user feilet.", error });
      showErrorDialog(true);
    }
    setLoadingSave(false);
  };

  return (
    <Grid className="account-settings-container">
      <Grid.Row className="settings-heading">
        <Grid.Col span={12}>
          <h3>{t("settings.youraccount").toLocaleUpperCase()}</h3>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <p>{t("common.details").toUpperCase()}</p>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col span={6}>
          <Textfield
            id="account-firstname"
            label={t("common.firstname")}
            value={get(currentUser, "firstname", "")}
            onChange={(e) => onChange({ firstname: e.target.value })}
            errormessage={validationErrors.firstname}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Textfield
            id="account-lastname"
            label={t("common.lastname")}
            value={get(currentUser, "lastname", "")}
            onChange={(e) => onChange({ lastname: e.target.value })}
            errormessage={validationErrors.lastname}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col span={6}>
          <Textfield
            id="account-email"
            label={t("common.email")}
            value={get(currentUser, "email", "")}
            onChange={(e) => onChange({ email: e.target.value })}
            errormessage={validationErrors.email}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <PhoneField
            id="account-phone"
            value={get(currentUser, "phone")}
            placeholder={t("common.phone")}
            onChange={(e) => onChange({ phone: e })}
            errormessage={validationErrors.phone}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col span={12} className="flex align">
          <CheckBox
            id="receiveCopyOfTradingNotifications"
            label={t("common.receiveCopyOfTradingNotifications")}
            checked={get(currentUser, "receiveCopyOfTradingNotifications", false)}
            onChange={(e) => onChange({ receiveCopyOfTradingNotifications: e.checked })}
          />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <p>{t("common.language").toUpperCase()}</p>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col span={6}>
          <Dropdown
            id="account-language"
            label={t("settings.languageGUI")}
            options={languageOptions}
            onChange={onChangeLanguage}
            value={selectedLanguage}
          />
        </Grid.Col>
        <Grid.Col span={6} className="vbottom">
          <div className="flex">
            <BlueButton
              id="account-save-language"
              text={t("common.save")}
              buttontype={BlueButton.buttontype.DarkBlue}
              onClick={saveHandler}
            />
            <Spinner loading={loadingSave} inline={true} align="center" />
          </div>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
}
