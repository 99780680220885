import React from "react";
import Textfield from "../TextField/TextField";
import Dropdown from "../Dropdown/Dropdown";
import PropTypes from "prop-types";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

import "./TextfieldDropdown.css";

export default function TextfieldDropdown(props) {
  const {
    id,
    textfieldLabel,
    textfieldPlaceholder,
    textfieldValue,
    textfieldOnChange,
    textfieldDisabled,
    textFieldType,
    textFieldOnBlur,
    textFieldName,

    dropdownLabel,
    dropdownOptions,
    dropdownValue,
    dropdownDisabled,
    dropdownOnChange,
    dropdownIsClearable,
    dropdownClearValue,
    menuPlacement,

    errormessage,
  } = props;

  const overrideDropdownStyle = {
    control: (base) => ({
      ...base,
      borderRadius: "5px",
      border: "2px solid var(--SFPlightBorderGray)",
      /* remove border radius from left corners */
      borderBottomLeftRadius: "0px",
      borderTopLeftRadius: "0px",
      /* add border radius to right corners */
      borderBottomRightRadius: "5px",
      borderTopRightRadius: "5px",
      /* make left side border thinner */
      borderLeft: "1px solid var(--SFPlightBorderGray)",
      borderTop: "2px solid var(--SFPlightBorderGray)",
      borderBottom: "2px solid var(--SFPlightBorderGray)",
      borderRight: "2px solid var(--SFPlightBorderGray)",

      minWidth: dropdownIsClearable && dropdownValue ? "140px" : "100px",
      height: "53px",
      ":hover": {
        borderColor: "#0000FF",
      },
      ":focus-within": {
        borderColor: "#0000FF",
        boxShadow: "none",
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "black",
    }),
  };

  return (
    <div id={id} className="flex col txtdropdown-area">
      <div className="txtdropdown">
        <Textfield
          id={`id-${"textfield"}`}
          name={textFieldName}
          label={textfieldLabel}
          disabled={textfieldDisabled}
          type={textFieldType}
          placeholder={textfieldPlaceholder}
          value={textfieldValue}
          onChange={textfieldOnChange}
          onBlur={textFieldOnBlur}
        />
        <Dropdown
          id={`id-${"dropdown"}`}
          overrideStyle={overrideDropdownStyle}
          label={dropdownLabel}
          isDisabled={dropdownDisabled}
          options={dropdownOptions}
          value={dropdownValue}
          onChange={dropdownOnChange}
          isClearable={dropdownIsClearable}
          clearValue={dropdownClearValue}
          menuPlacement={menuPlacement}
        />
      </div>
      <ErrorMessage errormessage={errormessage} />
    </div>
  );
}

TextfieldDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  textfieldLabel: PropTypes.string,
  textfieldPlaceholder: PropTypes.string,
  textfieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textfieldOnChange: PropTypes.func,
  textfieldDisabled: PropTypes.bool,
  textFieldType: PropTypes.string,
  textFieldOnBlur: PropTypes.func,
  textFieldName: PropTypes.string,

  dropdownLabel: PropTypes.string,
  dropdownOptions: PropTypes.array.isRequired,
  dropdownValue: PropTypes.any,
  dropdownDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  dropdownOnChange: PropTypes.func,
  dropdownIsClearable: PropTypes.bool,
  dropdownClearValue: PropTypes.func,

  errormessage: PropTypes.string,
};
