export default function createOrderTemplateStructure() {
  return {
    templateId: null,
    companyId: 0,
    templateName: "",
    salesItemId: null,
    conditionId: null,
    sizeId: null,
    gearId: null,
    treatmentId: null,
    boxTypeId: null,
    orderTemplateNetwork: [],
  };
}
