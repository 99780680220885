export function calculateDeviation(amount, deviation) {
  if (amount <= 0 || deviation <= 0) {
    return "";
  }

  const _deviation = (amount / 100) * deviation;
  const lowEnd = Number.parseFloat(amount) - _deviation;
  const highEnd = Number.parseFloat(amount) + _deviation;
  return `${lowEnd.toFixed(2)} kg - ${highEnd.toFixed(2)} kg`;
}
