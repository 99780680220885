import React from "react";
import Grid from "../../../components/Grid/Grid";
import { useTranslation } from "react-i18next";
import { get, capitalize } from "lodash";

import "./ApplicationTabContent.css";

export default function ApplicationTabContent(props) {
  const { t } = useTranslation();
  const company = props.company;
  const addressType = get(company, "partialCompany.address[0].addressType", "");
  const country = get(company, "partialCompany.country.name", "");
  const address = get(company, "partialCompany.address[0].address", "");
  const place = get(company, "partialCompany.address[0].place", "");
  const postCode = get(company, "partialCompany.address[0].postCode", "");
  const bankAccNr = get(company, "partialCompany.bankAccount[0].accountNo", "");
  const iban = get(company, "partialCompany.bankAccount[0].iban", "");
  const addressType2 = get(company, "partialCompany.address[1].addressType", "");
  const country2 = get(company, "partialCompany.address[1].country.name", "");
  const address2 = get(company, "partialCompany.address[1].address", "");
  const place2 = get(company, "partialCompany.address[1].place", "");
  const postCode2 = get(company, "partialCompany.address[1].postCode", "");

  return (
    <div className="app-tab-container">
      <Grid className="app-tab-section">
        <Grid.Row>
          <Grid.Col span={12}>
            <p>{t("verifyCustomer.registeredCountry")}</p>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={2}>
            <label>{t("common.country")}</label>
          </Grid.Col>
          <Grid.Col span={10}>
            <div>{country}</div>
          </Grid.Col>
        </Grid.Row>
      </Grid>

      <Grid className="app-tab-section">
        <Grid.Row>
          <Grid.Col span={12}>
            <p>{t("verifyCustomer.theCompany")}</p>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={2}>
            <label>Org.nr</label>
          </Grid.Col>
          <Grid.Col span={4}>
            <div>{company.partialCompany.vatNumber}</div>
          </Grid.Col>
          <Grid.Col span={2}>
            <label>Lei.nr.</label>
          </Grid.Col>
          <Grid.Col span={4}>
            <div>{company.partialCompany.leiNumber}</div>
          </Grid.Col>
        </Grid.Row>
      </Grid>

      <Grid className="app-tab-section break-word">
        <Grid.Row>
          <Grid.Col span={12}>
            <p>{t("verifyCustomer.contactInfo")}</p>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={2}>
            <label>{t("common.phone")}</label>
          </Grid.Col>
          <Grid.Col span={4}>
            <div>{company.partialCompany.phone}</div>
          </Grid.Col>
          <Grid.Col span={2}>
            <label>{t("common.email")}</label>
          </Grid.Col>
          <Grid.Col span={4}>
            <div>{company.partialCompany.email}</div>
          </Grid.Col>
        </Grid.Row>
      </Grid>

      <Grid className="app-tab-section">
        <Grid.Row>
          <Grid.Col span={12}>
            <p>{t("common.address")}</p>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={6} className="address-field">
            <label>{t("registerCompany.addressTypesWithSufix." + addressType)}</label>
            <div>{address}</div>
            <div>{place}</div>
            <div>{postCode}</div>
            <br></br>
            <div>{country}</div>
          </Grid.Col>
          <Grid.Col span={6}>
            <label>{t("registerCompany.addressTypesWithSufix." + addressType2)}</label>
            <div>{address2}</div>
            <div>{place2}</div>
            <div>{postCode2}</div>
            <br></br>
            <div>{country2}</div>
          </Grid.Col>
        </Grid.Row>
      </Grid>

      <Grid className="app-tab-section">
        <Grid.Row>
          <Grid.Col span={12}>
            <p>{t("verifyCustomer.companySettings")}</p>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row className="app-tab-sub-section">
          <Grid.Col span={4}>
            <label>{t("verifyCustomer.bankAccNbr")}</label>
          </Grid.Col>
          <Grid.Col span={3}>
            <div>{bankAccNr}</div>
          </Grid.Col>
          <Grid.Col span={2}>
            <label>Imei nr.</label>
          </Grid.Col>
          <Grid.Col span={3}>
            <div>{iban}</div>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row className="app-tab-sub-section">
          <Grid.Col span={4}>
            <label>{t("common.language")}</label>
          </Grid.Col>
          <Grid.Col span={8}>
            <div>{company.partialCompany.language.name}</div>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row className="app-tab-sub-section">
          <Grid.Col span={4}>
            <label>{t("verifyCustomer.handlingTransport")}</label>
          </Grid.Col>
          <Grid.Col span={8}>
            <div>{company.partialCompany.companyProvidesTransport ? t("common.yes") : t("common.no")}</div>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row className="app-tab-sub-section">
          <Grid.Col span={4}>
            <label>{t("common.companyType")}</label>
          </Grid.Col>
          <Grid.Col span={8}>
            <div>{capitalize(company.partialCompany.companyType)}</div>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row className="app-tab-sub-section">
          <Grid.Col span={4}>
            <label>{t("common.membershipType")}</label>
          </Grid.Col>
          <Grid.Col span={8}>
            <div>{capitalize(company.partialCompany.membershipType)}</div>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </div>
  );
}
