import { FC } from "react";

const Hamburger: FC = () => {
  return (
    <svg width="30" height="18" viewBox="0 0 30 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 1H30" stroke="currentColor" strokeWidth="2" />
      <path d="M0 9H30" stroke="currentColor" strokeWidth="2" />
      <path d="M0 17H30" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
};

export default Hamburger;
