import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { ErrorDialogContext } from "../../contexts/ErrorDialogContext";
import { createPayment, completePayment } from "../../services/Orders";
import Spinner from "../../components/Spinner/Spinner";

function parseCallbackUrl() {
  const queryString = window.location.search;
  return new URLSearchParams(queryString);
}

export default function DoCheckout() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const errorDialogContext = useContext(ErrorDialogContext);
  const { showErrorDialog, setErrorDialogOptions } = errorDialogContext;

  const params = parseCallbackUrl(window.location.search);
  const orderId = params.get("orderId");

  useEffect(() => {
    async function startCheckout() {
      setLoading(true);
      let payment;
      try {
        const response = await createPayment(orderId);
        payment = response.data;
      } catch (error) {
        setErrorDialogOptions({
          message: "Oppretting av betaling feilet.",
          error,
        });
        showErrorDialog(true);
        setLoading(false);
        return;
      }

      const checkoutOptions = {
        checkoutKey: payment.checkoutKey,
        paymentId: payment.paymentId,
        containerId: "dibs-complete-checkout",
      };
      const checkout = new window.Dibs.Checkout(checkoutOptions);

      checkout.on("payment-completed", async function (response) {
        setLoading(true);
        await completePayment(orderId);
        history.push(`/dashboard`);
        setLoading(false);
      });
      setLoading(false);
    }
    if (orderId) startCheckout();
  }, [orderId, setErrorDialogOptions, showErrorDialog, history]);

  return (
    <div>
      <div id="dibs-complete-checkout">
        <Spinner loading={loading} inline={true} align="left" />
      </div>
    </div>
  );
}
