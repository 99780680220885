import React from "react";
import { Route, useRouteMatch } from "react-router";
import { Link, Switch, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import SVGAsset from "../../components/SVGAsset/SVGAsset";
import AccountSettings from "./SettingsContent/AccountSettings";
import TeamSettings from "./SettingsContent/TeamSettings";
import CompanySettings from "./SettingsContent/CompanySettings";
import OrderTemplates from "./SettingsContent/OrderTemplates";
import NetworkSettings from "./SettingsContent/NetworkSettings";
import UserAdvertising from "./SettingsContent/UserAdvertising";
import MediumPage from "../../components/PageSizes/MediumPage";

import "./Settings.css";

export default function Settings(props) {
  const { t } = useTranslation();
  const { setUserContext, changeLocale } = props;

  const { path } = useRouteMatch();
  const location = useLocation();
  const activePage = location.pathname.split("/")[2];
  return (
    <MediumPage>
      <Helmet>
        <title>Reviver - {t("settings.settings")}</title>
        <meta name="description" content="Reviver - {t('settings.settings')}" />
      </Helmet>

      <div className="settings-container">
        <nav>
          <menu className="menu">
            <Link to={`${path}/account`}>
              <div className={`menu-item ${activePage === "account" ? "active" : ""}`}>
                <SVGAsset name="person-circle-white" />
                <span>{t("settings.youraccount")}</span>
              </div>
            </Link>

            <Link to={`${path}/settings`}>
              <div className={`menu-item ${activePage === "settings" ? "active" : ""}`}>
                <SVGAsset name="cogs-white" />
                <span>{t("settings.companydetails")}</span>
              </div>
            </Link>

            <Link to={`${path}/team`}>
              <div className={`menu-item ${activePage === "team" ? "active" : ""}`}>
                <SVGAsset name="users-white" />
                <span>{t("settings.team")}</span>
              </div>
            </Link>

            <Link to={`${path}/orderTemplates`}>
              <div className={`menu-item ${activePage === "orderTemplates" ? "active" : ""}`}>
                <SVGAsset name="template-white" />
                <span>{t("settings.templates")}</span>
              </div>
            </Link>

            <Link to={`${path}/network`}>
              <div className={`menu-item ${activePage === "network" ? "active" : ""}`}>
                <SVGAsset name="globe-white" />
                <span>{t("settings.network")}</span>
              </div>
            </Link>

            <Link to={`${path}/advertisement`}>
              <div className={`menu-item ${activePage === "advertisement" ? "active" : ""}`}>
                <SVGAsset name="email-white" />
                <span>{t("settings.advertisement")}</span>
              </div>
            </Link>
          </menu>
        </nav>
        <div className="settings-content">
          <Switch>
            <Route path={`${path}/account`}>
              <AccountSettings changeLocale={changeLocale} />
            </Route>
            <Route path={`${path}/settings`}>
              <CompanySettings setUserContext={setUserContext} changeLocale={changeLocale} />
            </Route>
            <Route path={`${path}/team`} component={TeamSettings} />
            <Route path={`${path}/orderTemplates`} component={OrderTemplates} />
            <Route path={`${path}/network`} component={NetworkSettings} />
            <Route path={`${path}/advertisement`} component={UserAdvertising} />
          </Switch>
        </div>
      </div>
    </MediumPage>
  );
}
