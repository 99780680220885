import { useState } from "react";

export const getUserContextIds = () => {
  const tokenString = sessionStorage.getItem("current");
  return JSON.parse(tokenString);
};

export default function useUserContext() {
  const [userContext, setUserContext] = useState();

  const saveUserContext = (userContext) => {
    if (userContext === null) sessionStorage.removeItem("current");
    else {
      const userContextIds = {
        userId: userContext.user && userContext.user.id,
        companyId: userContext.currentCompany && userContext.currentCompany.companyId,
      };
      sessionStorage.setItem("current", JSON.stringify(userContextIds));
    }
    setUserContext(userContext);
  };

  return {
    userContext,
    setUserContext: saveUserContext,
  };
}
