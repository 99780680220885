import React from "react";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import { get } from "lodash";

function VerifyCustomerTable(props) {
  const { t } = useTranslation();
  function BuildTable() {
    if (props.data) {
      let table = (
        <table>
          <thead>
            <tr>
              <th scope="col">{t("common.date")}</th>
              <th scope="col">{t("common.companyName")}</th>
              <th scope="col">{t("common.companyType")}</th>
              <th scope="col">{t("common.country")}</th>
            </tr>
          </thead>

          <tbody>
            {props.data.map((company, index) => (
              <tr
                id={`verify-customer-row-${index}`}
                key={`verify-customer-row-${index}`}
                onClick={(e) => props.onSelect(company, e)}
                className="clickable"
              >
                <td data-label={t("common.date")}>
                  <Moment format="DD.MM.YYYY">{company.updated}</Moment>
                </td>
                <td data-label={t("common.companyName")}>{company.partialCompany.name}</td>
                <td data-label={t("common.companyType")}>{company.partialCompany.companyType}</td>
                <td data-label={t("common.country")}>{get(company, "partialCompany.country.name", "")}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
      return table;
    } else return null;
  }

  return <BuildTable></BuildTable>;
}

export default VerifyCustomerTable;
