import OrderTypeEnum from "../constants/enums/OrderTypeEnum";

export function getOrderTypeIndicator(orderTypeId) {
  if (orderTypeId === OrderTypeEnum.SELL) return "saleIndicator";
  else if (orderTypeId === OrderTypeEnum.BUY) return "purchaseIndicator";
  else return "auction-small-blue";
}

export function getOrderTypeIndicatorLarge(orderTypeId) {
  if (orderTypeId === OrderTypeEnum.SELL) return "salesIndicatorLarge";
  else if (orderTypeId === OrderTypeEnum.BUY) return "purchaseIndicatorLarge";
  else return "auction-blue";
}

export function getOrderTypeIndicatorWhite(orderTypeId) {
  if (orderTypeId === OrderTypeEnum.SELL) return "saleIndicator-white";
  else if (orderTypeId === OrderTypeEnum.BUY) return "purchaseIndicator-white";
  else return "auction-white";
}
