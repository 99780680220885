import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { createPassword } from "../../services/Login";
import { validateFieldsHelper } from "../../utils/ValidationHelper";
import { createPasswordSchema } from "./RegisterUserValidationSchema";
import Grid from "../../components/Grid/Grid";
import TextField from "../../components/TextField/TextField";
import BlueButton from "../../components/BlueButton/BlueButton";
import SVGAsset from "../../components/SVGAsset/SVGAsset";
import ModalDialog from "../../containers/ModalDialog/ModalDialog";
import ModalDialogButtonsWrapper from "../ModalDialog/ModalDialogButtonsWrapper";
import MessageBar from "../../components/MessageBar/MessageBar";

export default function CreatePassword(props) {
  const { setRedirection, setUserContext } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const [dialogOptions, setDialogOptions] = useState({});

  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [validationErrors, setValidationErrors] = useState([]);

  const params = new URL(document.location).searchParams;
  const token = params.get("token");
  if (!token) {
    history.push("/");
  }

  function getMessageBar(exception) {
    return <MessageBar type={MessageBar.type.Exception} message={exception.message} />;
  }

  async function asyncCreatePassword(password, token) {
    return await createPassword(password, token);
  }

  const createPasswordHandler = async (e) => {
    e.preventDefault();

    if (password === rePassword) {
      try {
        const inputChecker = validateFieldsHelper(createPasswordSchema(t), {
          password: password,
          rePassword: rePassword,
        });
        setValidationErrors(inputChecker);
        if (Object.keys(inputChecker).length === 0) {
          const response = await asyncCreatePassword(rePassword, token);
          const user = response.data;

          if (user) {
            setUserContext({ email: user.email });
            setDialogOptions({
              open: true,
              message: t("commonLoginAndUsers.createPasswordSuccess"),
              onClose: () => {
                setDialogOptions({ open: false });
                setRedirection("INTERNAL");
              },
            });
          }
        }
      } catch (e) {
        setDialogOptions({
          open: true,
          message: t("commonLoginAndUsers.createPasswordError"),
          error: getMessageBar(e),
          onClose: () => setDialogOptions({ open: false }),
        });
      }
    } else {
      setDialogOptions({
        open: true,
        error: t("commonLoginAndUsers.createPasswordMismatch"),
        onClose: () => {
          setDialogOptions({ open: false });
        },
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Reviver - {t("registerCompany.registerUserCreatePassword")}</title>
        <meta name="description" content="Reviver - Create password" />
      </Helmet>
      <ModalDialog open={dialogOptions.open || false} onClose={dialogOptions.onClose || null} className="dialog">
        <p className="pad30">{dialogOptions.message}</p>
        {!!dialogOptions.error && dialogOptions.error}
        <ModalDialogButtonsWrapper>
          <BlueButton id="btn-close-dialog" text="OK" onClick={dialogOptions.onClose} />
        </ModalDialogButtonsWrapper>
      </ModalDialog>
      <Grid className="container-register-user-image-area">
        <Grid.Col span={6}>
          <SVGAsset className="logo" name="fished-logo" />
          <p className="img-phrase">{t("registerCompany.registerSlogan")}</p>
        </Grid.Col>
        <Grid.Col span={6} className="container-register-user create-password">
          <div className="container-register-user-area">
            <SVGAsset name="fished-logo" className="mobile-logo" caption="Fished Logo" />
            <h1>{t("registerCompany.registerUserCreatePassword")}</h1>
            <p>{t("registerCompany.registerUserCreatePasswordDescription")}</p>
            <form onSubmit={createPasswordHandler}>
              <Grid>
                <Grid.Row>
                  <Grid.Col span={6}>
                    <TextField
                      id="txt-createpassword-pw"
                      label={t("registerCompany.registerUserPassword")}
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      errormessage={validationErrors.password}
                    />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col span={6}>
                    <TextField
                      id="txt-createpassword-repeatpw"
                      label={t("registerCompany.registerUserPasswordRepeat")}
                      type="password"
                      onChange={(e) => setRePassword(e.target.value)}
                      errormessage={validationErrors.rePassword}
                    />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col span={4}>
                    <BlueButton
                      id="btn-createpassword-save"
                      text={t("registerCompany.registerUserSavePassword")}
                      type="submit"
                      disabled={dialogOptions.open}
                    />
                  </Grid.Col>
                </Grid.Row>
              </Grid>
            </form>
          </div>
        </Grid.Col>
      </Grid>
    </>
  );
}
