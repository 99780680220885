import React from "react";
import PropTypes from "prop-types";
import SvgAsset from "../SVGAsset/SVGAsset";

import "./BlueButton.css";

const buttontype = {
  Primary: "Primary",
  Secondary: "Secondary", // White background
  Tertiary: "Tertiary", // Background green with white text and no border
  Quaternary: "Quaternary", // Background red with white text and no border
  Autobid: "Autobid", // autobid SVG
  OnlyText: "OnlyText",
  PlusSecondary: "PlusSecondary", //secondary style with + icon infront of text
  DarkBlue: "DarkBlue",
};

const buttonsize = {
  Large: "Large",
  Medium: "Medium",
  Small: "Small",
};

blueButton.buttontype = buttontype;
blueButton.buttonsize = buttonsize;

function blueButton(props) {
  const buttontype = (props.buttontype || blueButton.buttontype.Primary).toLowerCase();
  const buttonsize = (props.buttonsize || blueButton.buttonsize.Large).toLowerCase();
  return (
    <div className={`button-area ${buttontype} ${buttonsize}`} id={props.id}>
      <button {...props} id={props.id ? props.id + "-button" : null}>
        <div className="flex align">
          {buttontype === "autobid" && <SvgAsset className="button-svg" name="autobid" />}
          {buttontype === "plussecondary" && <SvgAsset className="button-svg" name="plus-circle-blue" />}
          {props.svgname && <SvgAsset className="button-svg" name={props.svgname} />}
          {props.text}
        </div>
      </button>
    </div>
  );
}

blueButton.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  buttontype: PropTypes.oneOf([...Object.keys(buttontype)]),
  buttonsize: PropTypes.oneOf([...Object.keys(buttonsize)]),
};

export default blueButton;
