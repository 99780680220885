import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { approveFeedback, declineFeedback, getAllFeedback } from "../../services/Feedback";
import { ErrorDialogContext } from "../../contexts/ErrorDialogContext";
import { Helmet } from "react-helmet-async";

import BlueButton from "../../components/BlueButton/BlueButton";
import Spinner from "../../components/Spinner/Spinner";
import Drawer from "../../components/Drawer/Drawer";
import Grid from "../../components/Grid/Grid";
import Moment from "react-moment";

export default function AdminFeedback() {
  const { t } = useTranslation();
  const { showErrorDialog, setErrorDialogOptions } = useContext(ErrorDialogContext);

  const [allFeedbackData, setAllFeedbackData] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerLoading, setIsLoadingDrawer] = useState(false);
  const [feedbackInDrawer, setFeedbackInDrawer] = useState({});

  useEffect(() => {
    async function asyncgetAllFeedback() {
      setIsLoading(true);
      try {
        const response = await getAllFeedback();
        setAllFeedbackData(response.data);
        setIsLoading(false);
      } catch (error) {
        setErrorDialogOptions({
          message: t("commonAdmin.retrievingFailed"),
          error,
        });
        showErrorDialog(true);
      }
    }
    asyncgetAllFeedback();
  }, [setErrorDialogOptions, showErrorDialog, t]);

  async function asyncApproveFeedback(feedbackId) {
    setIsLoading(true);
    try {
      const response = await approveFeedback(feedbackId);
      await refreshFeedback();
      console.log(response.data);
      setIsLoading(false);
    } catch (error) {
      setErrorDialogOptions({
        message: t("commonAdmin.approvalFailed"),
        error,
      });
      showErrorDialog(true);
    }
  }

  async function asyncDeclineFeedback(feedbackId) {
    setIsLoading(true);
    try {
      const response = await declineFeedback(feedbackId);
      await refreshFeedback();
      console.log(response.data);
      setIsLoading(false);
    } catch (error) {
      setErrorDialogOptions({
        message: t("commonAdmin.decliningFailed"),
        error,
      });
      showErrorDialog(true);
    }
  }

  async function refreshFeedback() {
    setIsLoading(true);
    try {
      const response = await getAllFeedback();
      setAllFeedbackData(response.data);
      setIsLoading(false);
    } catch (error) {
      setErrorDialogOptions({ message: t("commonAdmin.refreshFailed"), error });
      showErrorDialog(true);
    }
  }

  async function openDrawerHandler(item) {
    setIsDrawerOpen(true);
    setIsLoadingDrawer(true);
    setFeedbackInDrawer(item);
    setIsLoadingDrawer(false);
  }

  async function declineFeedbackAndCloseDrawer() {
    asyncDeclineFeedback(feedbackInDrawer.id);
    setIsDrawerOpen(false);
  }

  async function acceptFeedbackAndCloseDrawer() {
    asyncApproveFeedback(feedbackInDrawer.id);
    setIsDrawerOpen(false);
  }
  return (
    <div className="admin-feedback">
      <Helmet>
        <title>Admin - Reviver - {t("commonAdmin.adminFeedback")}</title>
        <meta name="description" content="Admin - Reviver - {t('commonAdmin.adminFeedback')}" />
      </Helmet>
      <h3 className="mbottom20">{t("commonAdmin.feedback")}</h3>
      <Spinner loading={loading} inline={true} align="center" />
      {!!allFeedbackData && (
        <div className="feedback">
          <div className="feedback">
            <table className="feedBack">
              <thead>
                <tr>
                  <th scope="col">{t("commonAdmin.postedAt")}</th>
                  <th scope="col">{t("commonAdmin.comment")}</th>
                  <th scope="col">{t("commonAdmin.rating")}</th>
                  {/* <th scope="col">{t('commonAdmin.status')}</th> */}
                  <th scope="col">{t("commonAdmin.receiverCompanyId")}</th>
                  <th scope="col">{t("commonAdmin.senderCompanyId")}</th>
                  <th scope="col">{t("commonAdmin.process")}</th>
                </tr>
              </thead>
              <tbody>
                {allFeedbackData.map((item, index) => {
                  return (
                    <tr
                      id={`id-${index}`}
                      key={`comment-${index}`}
                      className={`clickable ${index}`}
                      onClick={() => openDrawerHandler(item)}
                    >
                      <td data-label={t("commonAdmin.postedAt")}>
                        <Moment format="MMMM Do YYYY, HH:mm">{item.postedAt}</Moment>
                      </td>
                      <td data-label={t("commonAdmin.comment")}>{item.comment}</td>
                      <td data-label={t("commonAdmin.rating")}>{item.rating}</td>
                      {/* <td data-label={t('commonAdmin.status')}>{item.status}</td> */}
                      <td data-label={t("commonAdmin.receiverCompanyId")}>{item.receiverCompanyId}</td>
                      <td data-label={t("commonAdmin.senderCompanyId")}>{item.senderCompanyId}</td>
                      <td data-label="">
                        <BlueButton
                          text={t("commonAdmin.process")}
                          onClick={() => openDrawerHandler(item) && console.log(feedbackInDrawer)}
                          buttonsize={BlueButton.buttonsize.Small}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <Drawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <Spinner loading={isDrawerLoading} />
        {feedbackInDrawer && (
          <div className="full flex col">
            <div className="flex gap10 align pad20">
              <Grid className="font-family-polysans pad30 taskinfo-container">
                <Grid.Row>
                  <Grid.Col span={4}>
                    <h3>{t("commonAdmin.feedback")}</h3>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col span={6}>
                    <label>{t("commonAdmin.postedAt")}</label>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <span>{<Moment format="MMMM Do YYYY, HH:mm">{feedbackInDrawer.postedAt}</Moment>}</span>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col span={6}>
                    <label>{t("commonAdmin.comment")}</label>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <span>{feedbackInDrawer.comment}</span>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col span={6}>
                    <label>{t("commonAdmin.rating")}</label>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <span>{feedbackInDrawer.rating}</span>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col span={6}>
                    <label>{t("commonAdmin.receiverCompanyId")}</label>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <span>{feedbackInDrawer.receiverCompanyId}</span>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col span={6}>
                    <label>{t("commonAdmin.senderCompanyId")}</label>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <span>{feedbackInDrawer.senderCompanyId}</span>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col>
                    <label>
                      <BlueButton
                        buttontype={BlueButton.buttontype.Secondary}
                        text={t("commonAdmin.decline")}
                        onClick={() => declineFeedbackAndCloseDrawer()}
                        buttonsize={BlueButton.buttonsize.Small}
                      ></BlueButton>
                    </label>
                  </Grid.Col>
                  <Grid.Col>
                    <label>
                      <BlueButton
                        text={t("commonAdmin.approve")}
                        onClick={() => acceptFeedbackAndCloseDrawer()}
                        buttonsize={BlueButton.buttonsize.Small}
                      ></BlueButton>
                    </label>
                  </Grid.Col>
                </Grid.Row>
              </Grid>
            </div>
          </div>
        )}
      </Drawer>
    </div>
  );
}
