import { axios, APIBASE_REVIVERAUTHENTICATION, APIBASE_REVIVERUSERS } from "./ApiConfig";

export const getLoginUrl = (email, externalRef, port) =>
  axios.get(`${APIBASE_REVIVERAUTHENTICATION}api/authentication/loginUrl?`, {
    params: {
      email,
      externalRef,
      port,
    },
  });

export const login = async (email, password) =>
  axios.post(`${APIBASE_REVIVERUSERS}api/user/login`, {
    email,
    password,
  });

export const getExternalToken = async (authProviderValue, externalRef, port) =>
  axios.post(
    `${APIBASE_REVIVERAUTHENTICATION}api/authentication/login/${authProviderValue}`,
    !!externalRef ? '"' + externalRef + '"' : externalRef,
    {
      params: {
        port,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const createUser = async (firstName, lastName, email, phone) =>
  axios.post(
    `${APIBASE_REVIVERUSERS}api/user/create`,
    {
      firstname: firstName,
      lastname: lastName,
      email: email,
      phone: phone,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const resendEmailVerification = async (token) =>
  axios.post(`${APIBASE_REVIVERUSERS}api/user/resendEmailVerification?token=${token}`);

export const createPassword = async (password, token) =>
  axios.post(`${APIBASE_REVIVERUSERS}api/user/verifyEmail?token=${token}`, password, {
    headers: {
      "Content-Type": "text/plain",
    },
  });

export const getUserById = async (id) => axios.get(`${APIBASE_REVIVERUSERS}api/user/get/${id}`);

export const getCompaniesByUserId = async (id, companyId) =>
  axios.get(`${APIBASE_REVIVERUSERS}api/user/${id}/companies`, {
    headers: {
      UserId: id,
      CompanyId: companyId,
    },
  });

export const logOut = () => axios.post(`${APIBASE_REVIVERAUTHENTICATION}api/authentication/logout`);

export const getLogoutUrl = (port) =>
  axios.get(`${APIBASE_REVIVERAUTHENTICATION}api/authentication/logoutUrl`, {
    params: {
      port,
    },
  });
