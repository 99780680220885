import Joi from "joi";

export function loginCheckValidationSchema(translation) {
  const t = translation;

  return Joi.object({
    email: Joi.string()
      .max(100)
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        "string.empty": t("validation.emailRequired"),
        "string.max": t("validation.emailMaxLength"),
        "string.email": t("validation.emailFormatError"),
        "any.required": t("validation.emailRequired"),
      }),
  }).unknown();
}
