import React, { useState, useEffect, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { createUser, deleteTeamMember, postChangeRole } from "../../../services/Settings";
import { getCompanyTeam } from "../../../services/Company";
import { ErrorDialogContext } from "../../../contexts/ErrorDialogContext";
import { teamSettingsSchema, editRoleSchema } from "./TeamSettingsValidationSchema";
import { validateFieldsHelper } from "../../../utils/ValidationHelper";

import Grid from "../../../components/Grid/Grid";
import BlueButton from "../../../components/BlueButton/BlueButton";
import ModalDialog from "../../../containers/ModalDialog/ModalDialog";
import Textfield from "../../../components/TextField/TextField";
import Dropdown from "../../../components/Dropdown/Dropdown";
import UserContext from "../../../contexts/UserContext";
import ContextPanel from "../../../components/ContextPanel/ContextPanel";

import "./TeamSettings.css";
import ModalDialogButtonsWrapper from "../../ModalDialog/ModalDialogButtonsWrapper";

export default function TeamSettings() {
  const { t } = useTranslation();
  const errorDialogContext = useContext(ErrorDialogContext);
  const { showErrorDialog, setErrorDialogOptions } = errorDialogContext;

  const userContext = useContext(UserContext);
  const { currentCompany } = userContext;
  const [activeTeamMembers, setActiveTeamMembers] = useState([]);
  const [isEditMemberRoleOpen, setIsEditMemberRoleOpen] = useState(false);

  const [validationErrors, setValidationErrors] = useState([]);
  const [validationErrorsUpdate, setValidationErrorsUpdate] = useState([]);

  const asyncGetCompanyTeam = useCallback(async () => {
    const response = await getCompanyTeam(currentCompany.companyId);
    setActiveTeamMembers(response.data.activeUsers);
  }, [currentCompany.companyId]);

  useEffect(() => {
    asyncGetCompanyTeam();
  }, [asyncGetCompanyTeam]);

  // Team Role
  const teamRoleTypes = ["ADMIN", "USER", "VIEWER"];
  function getTeamRoleTypeOptions() {
    const types = teamRoleTypes;
    return types.map((label, value) => ({
      value,
      label,
    }));
  }
  const teamRoleTypeOptions = getTeamRoleTypeOptions();
  const [isNewMemberDialogOpen, setIsNewMemberDialogOpen] = useState(false);
  const [newMemberRoleTypeOptions] = useState(teamRoleTypeOptions);
  const [newMemberEmail, setNewMemberEmail] = useState("");
  const [newMemberFirstName, setNewMemberFirstName] = useState("");
  const [newMemberLastName, setNewMemberLastName] = useState("");
  const [selectedNewMemberRoleType, setSelectedNewMemberRoleType] = useState("");
  const [selectedUserId, setSelectedUserId] = useState();

  function teamRoleTypeHandler(e) {
    setSelectedNewMemberRoleType(e);
  }

  function openAddNewMemberDialog() {
    setNewMemberFirstName("");
    setNewMemberLastName("");
    setNewMemberEmail("");
    setSelectedNewMemberRoleType("");
    setIsNewMemberDialogOpen(true);
  }

  const inviteNewMemberHandler = async () => {
    try {
      const inputChecker = validateFieldsHelper(teamSettingsSchema(t), {
        firstname: newMemberFirstName,
        lastname: newMemberLastName,
        email: newMemberEmail,
        roleType: selectedNewMemberRoleType.label,
      });
      setValidationErrors(inputChecker);

      if (Object.keys(inputChecker).length === 0) {
        await createUser(
          newMemberFirstName,
          newMemberLastName,
          newMemberEmail,
          selectedNewMemberRoleType.label,
          currentCompany.companyId
        );
        asyncGetCompanyTeam();
        setIsNewMemberDialogOpen(false);
      }
    } catch (error) {
      setErrorDialogOptions({ message: "Create user feilet.", error });
      showErrorDialog(true);
    }
  };

  // Active member context
  function createActiveMemberContextOptions(index, array) {
    return [
      {
        label: t("settings.edit"),
        action: () => openEditTeamMemberDialog(index, array),
      },
      {
        label: t("settings.delete"),
        action: () => removeTeamMember(index, array),
      },
    ];
  }

  const getTeamRoleOptionByLabel = (label) => teamRoleTypeOptions.find((option) => label === option.label);

  function openEditTeamMemberDialog(index, array) {
    const teamMember = array[index];

    const thisRoleType = getTeamRoleOptionByLabel(teamMember.roleType);
    setSelectedNewMemberRoleType(thisRoleType);

    setSelectedUserId(teamMember.id);
    setNewMemberFirstName(teamMember.firstName);
    setNewMemberLastName(teamMember.lastName);
    setNewMemberEmail(teamMember.email);

    setIsEditMemberRoleOpen(true);
  }

  async function removeTeamMember(index, array) {
    try {
      const memberToDelete = array.filter((member) => member === array[index]);
      if (memberToDelete.length > 0) {
        await deleteTeamMember(currentCompany.companyId, memberToDelete[0].id);
        asyncGetCompanyTeam();
      }
    } catch (error) {
      setErrorDialogOptions({ message: "Remove user failed", error });
      showErrorDialog(true);
    }
  }

  const editMemberRole = async () => {
    try {
      const inputCheckerUpdate = validateFieldsHelper(editRoleSchema(t), {
        roleType: selectedNewMemberRoleType.label,
      });
      setValidationErrorsUpdate(inputCheckerUpdate);
      if (Object.keys(inputCheckerUpdate).length === 0) {
        await postChangeRole(selectedUserId, selectedNewMemberRoleType.label, currentCompany.companyId);
        setIsEditMemberRoleOpen(false);
        asyncGetCompanyTeam();
      }
    } catch (error) {
      setErrorDialogOptions({ message: "Edit role feilet.", error });
      showErrorDialog(true);
    }
  };

  function closeAddNewTeamMember() {
    setIsNewMemberDialogOpen(false);
    setValidationErrors({});
  }

  function closeEditTeamMember() {
    setIsEditMemberRoleOpen(false);
    setValidationErrorsUpdate({});
  }

  return (
    <Grid className="team-settings-container">
      <Grid.Row>
        <Grid.Col span={4} className="top-card">
          <h3>{t("settings.team")}</h3>
        </Grid.Col>
        <Grid.Col span={2} className="at11">
          <BlueButton
            id="settings-team-add"
            text={t("settings.newUser")}
            buttontype={BlueButton.buttontype.DarkBlue}
            svgname="pluss-square"
            onClick={() => openAddNewMemberDialog()}
          />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col span={12}>
          <table className="lightblue">
            <thead>
              <tr>
                <th scope="col" colSpan="3">
                  {t("common.user")}
                </th>
                <th scope="col" colSpan="2">
                  {t("common.role")}
                </th>
                <th>{t("settings.actions")}</th>
              </tr>
            </thead>

            <tbody>
              {activeTeamMembers.map((activeUser, index, array) => (
                <tr key={`active-member-row-${index}`}>
                  <td data-label={t("common.user")} colSpan="3">
                    {activeUser.firstName} {activeUser.lastName}
                  </td>
                  <td data-label={t("common.role")} colSpan="2">
                    {activeUser.roleType}
                  </td>
                  <td data-label="Actions">
                    <ContextPanel
                      options={createActiveMemberContextOptions(index, array)}
                      contextType={ContextPanel.contextType.Secondary}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Grid.Col>
      </Grid.Row>

      {/* INVITE NEW MEMBER */}
      <ModalDialog
        open={isNewMemberDialogOpen}
        onClose={() => closeAddNewTeamMember()}
        headerText={t("settings.addNewUser")}
      >
        <div className="pad30">
          <Textfield
            id="settings-team-firstname"
            textFieldClassName="mbottom10"
            label={t("common.firstname")}
            value={newMemberFirstName}
            onChange={(e) => setNewMemberFirstName(e.target.value)}
            errormessage={validationErrors.firstname}
          />
          <Textfield
            id="settings-team-lastname"
            textFieldClassName="mbottom10"
            label={t("common.lastname")}
            value={newMemberLastName}
            onChange={(e) => setNewMemberLastName(e.target.value)}
            errormessage={validationErrors.lastname}
          />
          <Textfield
            id="settings-team-email"
            textFieldClassName="mbottom10"
            label={t("common.email")}
            value={newMemberEmail}
            onChange={(e) => setNewMemberEmail(e.target.value)}
            errormessage={validationErrors.email}
          />
          <Dropdown
            id="settings-team-role"
            dropDownClassNames="mbottom10"
            label={t("common.role")}
            options={newMemberRoleTypeOptions}
            onChange={teamRoleTypeHandler}
            value={selectedNewMemberRoleType}
            errormessage={validationErrors.roleType}
          />
        </div>
        <ModalDialogButtonsWrapper>
          <BlueButton
            id="settings-team-cancel"
            text={t("common.cancel")}
            buttontype={BlueButton.buttontype.Secondary}
            onClick={() => closeAddNewTeamMember()}
          />
          <BlueButton id="settings-team-invite" text={t("settings.invite")} onClick={inviteNewMemberHandler} />
        </ModalDialogButtonsWrapper>
      </ModalDialog>

      <ModalDialog open={isEditMemberRoleOpen} onClose={() => setIsEditMemberRoleOpen(false)}>
        <div className="pad30">
          <h3 className="mbottom30">{`${t("settings.editRole")}  ${newMemberFirstName} ${newMemberLastName}`}</h3>
          <Dropdown
            id="settings-team-edit-role"
            label={t("common.role")}
            options={newMemberRoleTypeOptions}
            onChange={teamRoleTypeHandler}
            value={selectedNewMemberRoleType}
            errormessage={validationErrorsUpdate.roleType}
          />
        </div>
        <ModalDialogButtonsWrapper>
          <BlueButton
            id="settings-team-edit-cancel"
            text={t("common.cancel")}
            buttontype={BlueButton.buttontype.Secondary}
            onClick={() => closeEditTeamMember()}
          />
          <BlueButton id="settings-team-invite" text={t("common.save")} onClick={editMemberRole} />
        </ModalDialogButtonsWrapper>
      </ModalDialog>
    </Grid>
  );
}
