import React, { useState, useContext, useEffect, FC, PropsWithChildren } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UserContext from "../../../../contexts/UserContext";
import styles from "./styles.module.css";
import Logo from "../../atoms/Logo";
import SelectBox from "../SelectBox";
import { AdminNavigation, Navigation } from "../../molecules/Navigation";
import HamburgerNav from "../../atoms/HamburgerNav";
import { buildUserOptions } from "../../../../containers/UserOptions/UserOptions";
import { getOpenTasks } from "../../../../services/Tasks";

interface BaseHeaderProps {
  variant: "primary" | "secondary";
  disableNavigation?: boolean;
}
interface MainHeaderProps {
  setUserContext: (userContext: any) => void;
  logOut: () => Promise<void>;
  changeLocale: (locale: any, i18n: any, timeZone: any) => void;
}

interface Companies {
  companies: any;
}

interface User {
  user: any;
  companies: Companies;
  currentCompany: any;
}

const UPDATE_INTERVAL = 60000;

export const BaseHeader: FC<PropsWithChildren<BaseHeaderProps>> = ({ variant, disableNavigation, ...props }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <header className={`${styles.root} ${styles[variant]}`}>
      <div className={styles.inner}>
        <Logo variant={variant} />
        {!disableNavigation && (
          <HamburgerNav
            variant={variant}
            showCloseButton={isMobileMenuOpen}
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          />
        )}
        <div className={`${styles.menu} ${isMobileMenuOpen ? styles.menuOpen : ""}`}>{props.children}</div>
      </div>
    </header>
  );
};

export const AdminHeader: FC<PropsWithChildren> = ({ ...props }) => {
  return (
    <BaseHeader variant="secondary">
      <AdminNavigation />
    </BaseHeader>
  );
};

export const MainHeader: FC<PropsWithChildren<MainHeaderProps>> = ({ ...props }) => {
  const { t, i18n } = useTranslation();
  const { setUserContext, logOut, changeLocale } = props;
  const [nrOfOpenTasks, setNrOfOpenTasks] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const activePage = location.pathname.split("/")[1];
  const userContext = useContext(UserContext) || {};
  const { user, companies, currentCompany } = userContext as User;
  const disabledNavigationList = ["registercompany"];
  const isNavigationDisabled = disabledNavigationList.includes(activePage.toLowerCase());
  const userOptions = buildUserOptions(user, currentCompany, companies, t, isNavigationDisabled);

  useEffect(() => {
    if (!currentCompany) return;
    let openTaskInterval: any;
    async function asyncGetOpenTasks() {
      const result = await getOpenTasks(currentCompany.companyId);
      setNrOfOpenTasks(result.data.length);
    }

    asyncGetOpenTasks();

    if (!openTaskInterval) {
      openTaskInterval = setInterval(() => {
        asyncGetOpenTasks();
      }, UPDATE_INTERVAL);
    }

    return () => {
      clearInterval(openTaskInterval);
    };
  }, [currentCompany]);

  function onChangeCompany(companyId: any) {
    const currentCompany = companies.companies.find((c: any) => c.companyId === companyId);
    setUserContext({
      ...userContext,
      currentCompany,
    });

    changeLocale(user.language.languageCode, i18n, currentCompany.companyTimeZone);
  }

  const userOptionsChangedHandler = (item: any) => {
    switch (item.value) {
      case "settings":
        history.push("/settings/account");
        // setIsMobileMenuOpen(false); TODO
        break;
      case "logout":
        logOut();
        break;
      default:
        const companyId = item.value;
        onChangeCompany(companyId);
    }
  };

  console.log(nrOfOpenTasks); // TODO: integrate

  return (
    <BaseHeader variant="primary" disableNavigation={isNavigationDisabled}>
      {!isNavigationDisabled && <Navigation />}
      <SelectBox userOptions={userOptions} onChange={userOptionsChangedHandler} />
    </BaseHeader>
  );
};

export const HeadersContainer: FC<PropsWithChildren> = (props) => {
  return <div className={styles.container}>{props.children}</div>;
};
