import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import SVGasset from "../SVGAsset/SVGAsset";

import "./Pagination.css";

export default function Pagination(props) {
  //neigbours = amount of numbers next to current selected (on each side)
  const { numberOfPages, onClick, neighbours, reset } = props;
  const [currentActive, setCurrentActive] = useState(1);

  useEffect(() => {
    setCurrentActive(1);
  }, [reset]);

  const pageNumbers = [];
  let minPage = 1;
  let maxPage = numberOfPages;
  if (currentActive - neighbours > 1) minPage = currentActive - neighbours;
  if (currentActive + neighbours < numberOfPages) maxPage = currentActive + neighbours;

  for (let i = minPage; i <= maxPage; i++) {
    pageNumbers.push(i);
  }

  const changePageHandler = (number) => {
    onClick(number);
    setCurrentActive(number);
  };

  const RenderPageNumbers = () => {
    return pageNumbers.map((number) => {
      const isActive = number === currentActive ? "active" : "";
      return (
        <li key={number} id={number} onClick={() => changePageHandler(number)} className={`clickable ${isActive}`}>
          {number}
        </li>
      );
    });
  };

  const clickLeftHandler = () => {
    if (currentActive > 1) changePageHandler(currentActive - 1);
  };

  const clickRightHandler = () => {
    if (currentActive < numberOfPages) changePageHandler(currentActive + 1);
  };

  const showMinPage = currentActive > 1 + neighbours ? true : false;
  const showMaxPage = currentActive < numberOfPages - neighbours ? true : false;

  return (
    <div className="pagination-container flex align gap10">
      <div className="clickable" onClick={clickLeftHandler}>
        <SVGasset name="chevron-double-left" />
      </div>
      <ul>
        {showMinPage && (
          <>
            <li className="clickable" onClick={() => changePageHandler(1)}>
              {1}
            </li>
            <li>
              <span>&#8230;</span>
            </li>
          </>
        )}
        <RenderPageNumbers />
        {showMaxPage && (
          <>
            <li>
              <span>&#8230;</span>
            </li>
            <li className="clickable" onClick={() => changePageHandler(numberOfPages)}>
              {numberOfPages}
            </li>
          </>
        )}
      </ul>
      <div className="clickable" onClick={clickRightHandler}>
        <SVGasset name="chevron-double-left" className="right-arrow" />
      </div>
    </div>
  );
}

Pagination.propTypes = {
  numberOfPages: PropTypes.number,
  onClick: PropTypes.func,
};
