import React, { useEffect, useState, useMemo, useContext } from "react";
import { useTranslation } from "react-i18next";
import { getOrder, getMyOrderList } from "../../services/Orders";
import { Helmet } from "react-helmet-async";
import { getOrderTypeIndicatorLarge } from "../../utils/Orders";

import OrdersTable from "../../components/Tables/OrdersTable";
import UserContext from "../../contexts/UserContext";
import Grid from "../../components/Grid/Grid";
import SVGAsset from "../../components/SVGAsset/SVGAsset";
import Tabs from "../../components/Tabs/Tabs";
import Tab from "../../components/Tabs/Tab";
import OrderTabContent from "./DashboardOrderTabContent";
import TransactionTabContent from "./TransactionTabContent";
import Spinner from "../../components/Spinner/Spinner";
import DocumentsTabContent from "./DocumentsTabContent";
import Pagination from "../../components/Pagination/Pagination";
import AccumulatedSalesChart from "../../components/charts/AccumulatedSalesChart";
import CurrencyAveragesChart from "../../components/charts/CurrencyAveragesChart";
import DatePicker from "../../components/DatePicker/DatePicker";
import Drawer from "../../components/Drawer/Drawer";

import "./Dashboard.css";

export default function Dashboard() {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const { currentCompany } = userContext;

  const [tableData, setTableData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState();

  // Drawer
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [orderInDrawer, setOrderInDrawer] = useState(null);
  const [isLoadingDrawer, setIsLoadingDrawer] = useState(false);

  // Charts
  const initialFromDate = new Date();
  initialFromDate.setDate(initialFromDate.getDate() - 30);
  const [fromDate, setFromDate] = useState(initialFromDate);
  const [toDate, setToDate] = useState(new Date());

  const memoGetMyOrderListParams = useMemo(
    () => ({
      companyid: currentCompany && currentCompany.companyId,
      includeInactive: true,
    }),
    [currentCompany]
  );

  useEffect(() => {
    async function asyncGetMyOrderList() {
      setIsLoading(true);
      const response = await getMyOrderList(...Object.values(memoGetMyOrderListParams));
      setTableData(response.data.orders);
      setNumberOfPages(response.data.numberOfPages);
      setIsLoading(false);
    }

    asyncGetMyOrderList();
  }, [memoGetMyOrderListParams]);

  async function asyncGetOrder(orderId) {
    const resp = await getOrder(orderId);
    return resp.data;
  }

  async function openDrawerHandler(orderId) {
    setOrderInDrawer(null);
    setIsDrawerOpen(true);
    setIsLoadingDrawer(true);
    const order = await asyncGetOrder(orderId);

    setOrderInDrawer(order);
    setIsLoadingDrawer(false);
  }

  // Paginating
  const changePangeHandler = async (pageNumber) => {
    setIsLoading(true);
    const response = await getMyOrderList(...Object.values(memoGetMyOrderListParams), pageNumber);
    setTableData(response.data.orders);
    setNumberOfPages(response.data.numberOfPages);
    setIsLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>Reviver - {t("dashboard.dashboard")}</title>
        <meta name="description" content="Reviver - {t('dashboard.dashboard')}" />
      </Helmet>

      <Grid className="dashboard-container">
        <Grid.Col span={12}>
          <Grid.Col span={12} className="mbottom30 mtop30">
            <div className="align chart-datepickers">
              <DatePicker value={fromDate} onChange={(e) => setFromDate(e)} setmaxdate={new Date()} />
              <p>{t("dashboard.comparedTo").toUpperCase()}</p>
              <DatePicker value={toDate} onChange={(e) => setToDate(e)} setmaxdate={new Date()} />
            </div>
          </Grid.Col>
          <Grid.Row className="dashboard-charts-container mobile-side-scroll">
            <Grid.Col span={6}>
              <AccumulatedSalesChart fromDate={fromDate} toDate={toDate} />
            </Grid.Col>
            <Grid.Col span={6}>
              <CurrencyAveragesChart fromDate={fromDate} toDate={toDate} />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row className="dashboard-table center">
            <Grid.Col span={12}>
              <h3>{t("common.orders").toUpperCase()}</h3>
              <Spinner loading={isLoading} inline />
              {currentCompany && <OrdersTable data={tableData} onSelect={openDrawerHandler} />}
            </Grid.Col>
            <Grid.Row>
              <Grid.Col span={12} className="flex right">
                <Pagination numberOfPages={numberOfPages} neighbours={1} onClick={changePangeHandler} />
              </Grid.Col>
            </Grid.Row>
          </Grid.Row>
        </Grid.Col>
      </Grid>

      <Drawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <Spinner loading={isLoadingDrawer} />
        {orderInDrawer && (
          <div className="full flex col">
            <div className="flex gap10 pad20 dashboard-sidepanel-header">
              <SVGAsset name={getOrderTypeIndicatorLarge(orderInDrawer.orderType.id)} />
              <h3 className="no-margin">
                {t("commonOrders.orderTypes." + orderInDrawer.orderType.type).toUpperCase()}
              </h3>
            </div>
            <Tabs>
              <Tab title={t("common.order")}>
                <OrderTabContent order={orderInDrawer} />
              </Tab>
              <Tab title={t("common.transactions")}>
                <div className="pad20">
                  <TransactionTabContent order={orderInDrawer} />
                </div>
              </Tab>
              <Tab title={t("common.documents")}>
                <div className="pad20">
                  <DocumentsTabContent order={orderInDrawer} />
                </div>
              </Tab>
            </Tabs>
          </div>
        )}
      </Drawer>
    </>
  );
}
