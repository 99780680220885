import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import nb from "./locales/nb";
import en from "./locales/en";
import pl from "./locales/pl";

// const locale = navigator.language;
const lng = sessionStorage.getItem("lng") || "nb";

const languages = {
  nb,
  en,
  pl,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {},
    lng: lng,
    fallbackLng: "nb",

    ns: ["ns.translate"],
    defaultNS: "ns.translate",

    debug: true,

    interpolation: {
      escapeValue: false, // react safe from xss
    },
  });

Object.keys(languages).forEach((lang) => {
  i18n.addResourceBundle(lang, "ns.translate", languages[lang], true);
});

export default i18n;
