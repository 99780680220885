import PropTypes from "prop-types";

import "./ErrorMessage.css";

export default function errorMessage(props) {
  return <label className="textfield-error">{props.errormessage}</label>;
}

errorMessage.propTypes = {
  errormessage: PropTypes.string,
};
