import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ErrorDialogContext } from "../../../contexts/ErrorDialogContext";
import { postFinalAmount } from "../../../services/Orders";

import TextField from "../../../components/TextField/TextField";
import Button from "../../../components/BlueButton/BlueButton";
import Spinner from "../../../components/Spinner/Spinner";
import SVGAsset from "../../../components/SVGAsset/SVGAsset";

import "./FinalAmountTask.css";

export default function FinalAmountTask(props) {
  const { t } = useTranslation();
  const errorDialogContext = useContext(ErrorDialogContext);
  const { showErrorDialog, setErrorDialogOptions } = errorDialogContext;

  const { amount, amountDeviation, orderId, taskId, onCloseCB } = props;
  const [finalAmount, setFinalAmount] = useState("");
  const [finalAmountError, setFinalAmountError] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  async function saveFinalAmount() {
    setIsSaving(true);

    const deviation = (amount / 100) * amountDeviation;
    const lowEnd = Number.parseFloat(amount) - deviation;
    const highEnd = Number.parseFloat(amount) + deviation;

    if (finalAmount > highEnd || finalAmount < lowEnd) {
      setFinalAmountError(`${t("dashboard.legalFinalAmount")} ${lowEnd}kg - ${highEnd}kg`);
      setIsSaving(false);
      return;
    }
    // reset error if any
    if (finalAmountError) setFinalAmountError("");

    try {
      await postFinalAmount(orderId, finalAmount, taskId);
      onCloseCB();
    } catch (error) {
      setErrorDialogOptions({ message: "Saving final amount failed", error });
      showErrorDialog(true);
    }
    setIsSaving(false);
  }

  return (
    <div className="finalamount-container">
      <div className="flex gap10 mbottom10">
        <SVGAsset name="final-amount" />
        <label>{t("commonOrders.finalAmount")}</label>
      </div>
      <div className="flex gap10 align">
        <TextField
          id="final-amount"
          type="number"
          value={finalAmount}
          onChange={(e) => setFinalAmount(e.target.value)}
        />
        <Button id="final-amount-btn" text={t("common.save")} onClick={() => saveFinalAmount()} />
        <Spinner loading={isSaving} inline />
      </div>
      {finalAmountError && (
        <p className="font-family-polysans" style={{ color: "red" }}>
          {finalAmountError}
        </p>
      )}
    </div>
  );
}
