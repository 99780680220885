import { useState } from "react";

export default function useRedirection() {
  const getRedirection = () => {
    const tokenString = sessionStorage.getItem("redirection");
    return JSON.parse(tokenString);
  };

  const [redirection, setRedirection] = useState(getRedirection());

  const saveRedirection = (redirection) => {
    if (redirection === null) sessionStorage.removeItem("redirection");
    sessionStorage.setItem("redirection", JSON.stringify(redirection));
    setRedirection(redirection);
  };

  return {
    redirection,
    setRedirection: saveRedirection,
  };
}
