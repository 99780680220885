import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  getCreateOrderValues,
  getValidConditions,
  getValidSizes,
  postUpdateOrder,
  postPromoteOrder,
  deleteOrder,
  getBoxtypeValues,
} from "../../services/Orders";
import { getCompanyNetworkFull } from "../../services/Company";
import { ErrorDialogContext } from "../../contexts/ErrorDialogContext";
import { getOrderTypeIndicatorWhite } from "../../utils/Orders";
import { getSalesItemById } from "../../services/Orders";
import { updateOrderSchemaSalesOrder, updateOrderSchemaBuyOrder } from "./UpdateOrderValidateSchema";
import getUpdateOrderStructure from "../../structures/UpdateOrderStructure";
import amountDeviationOptionValues from "../../constants/AmountDeviationValues";
import MessageBar from "../../components/MessageBar/MessageBar";

import SVGAsset from "../../components/SVGAsset/SVGAsset";
import Moment from "react-moment";
import BlueButton from "../../components/BlueButton/BlueButton";
import Grid from "../../components/Grid/Grid";
import TextField from "../../components/TextField/TextField";
import DatePicker from "../../components/DatePicker/DatePicker";
import Dropdown from "../../components/Dropdown/Dropdown";
import UserContext from "../../contexts/UserContext";
import moment from "moment-timezone";
import OrderTypeEnum from "../../constants/enums/OrderTypeEnum";
import ContextPanel from "../../components/ContextPanel/ContextPanel";
import ModalDialog from "../../containers/ModalDialog/ModalDialog";
import ModalDialogButtonsWrapper from "../ModalDialog/ModalDialogButtonsWrapper";
import Checkbox from "../../components/Checkbox/Checkbox";
import DropdownMulti from "../../components/DropdownMulti/DropdownMulti";

import "./ActiveOrderDetails.css";
import { validateFieldsHelper } from "../../utils/ValidationHelper";

export default function ActiveOrderDetails(props) {
  const { t } = useTranslation();
  const { order, handleActiveOrder, refreshActiveOrdersCB } = props;
  const userContext = useContext(UserContext);
  const { user } = userContext;
  const { currentCompany } = userContext;
  const errorDialogContext = useContext(ErrorDialogContext);
  const { showErrorDialog, setErrorDialogOptions } = errorDialogContext;

  const [updateOrderObject, setUpdateOrderObject] = useState(getUpdateOrderStructure(order, user));
  const [conditionOptions, setConditionOptions] = useState([]);
  const [selectedCondition, setSelectedCondition] = useState(null);
  const [sizeOptions, setSizeOptions] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);
  const [toolOptions, setToolOptions] = useState([]);
  const [selectedTool, setSelectedTool] = useState(null);
  const [treatmentOptions, setTreatmentOptions] = useState([]);
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  const [orderTypeIndicator] = useState(() => getOrderTypeIndicatorWhite(updateOrderObject.orderType));
  const [orderCurrencyOptions, setOrderCurrencyOptions] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState(null);
  const [boxtypeOptions, setBoxtypeOptions] = useState([]);
  const [selectedBoxtype, setSelectedBoxtype] = useState(null);
  const [noOfBoxes, setNoOfBoxes] = useState(updateOrderObject.packaging.numberOfBoxes);
  const [amountDeviationOptions] = useState(amountDeviationOptionValues);
  const [selectedAmountDeviation, setSelectedAmountDeviation] = useState(null);
  const [salesItemImg, setSalesItemImg] = useState("");
  const [isPromoted, setIsPromoted] = useState(order.promoted);
  const [validationErrors, setValidationErrors] = useState([]);
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const isDisabled = order.orderType.id === OrderTypeEnum.AUCTION;
  const bigDropdownStyle = {
    control: (base) => ({
      ...base,
      border: "0px",
      "font-size": "25px",
      "line-height": "30px",
      "font-family": "PolySans",
      "font-weight": "bold",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "var(--SFPdarkBlue);",
    }),
  };

  const [deleteOrderConfirmationConfig, setDeleteOrderConfirmationConfig] = useState({
    isOpen: false,
    text: "",
  });

  useEffect(() => {
    async function loadOptions() {
      async function asyncGetDropdownValues() {
        return await getCreateOrderValues();
      }
      async function asyncGetValidConditions() {
        return await getValidConditions(order.salesItem.id);
      }
      async function asyncGetValidSizes() {
        return await getValidSizes(order.salesItem.id, updateOrderObject.condition.id);
      }
      async function asyncGetAllBoxtypes() {
        return await getBoxtypeValues();
      }
      async function asyncGetAllNetworkCompanies() {
        return await getCompanyNetworkFull(currentCompany.companyId);
      }

      let dropdownValues = await asyncGetDropdownValues();

      async function asyncGetSalesItemImg() {
        const response = await getSalesItemById(updateOrderObject.salesItem.id);
        setSalesItemImg(require("../../assets/img" + response.data.imageUrl));
      }
      if (updateOrderObject.salesItem && updateOrderObject.salesItem.id > 0) asyncGetSalesItemImg();

      //Condition options
      let conditionValues = await asyncGetValidConditions();
      const _conditionOptions = conditionValues.data.map((condition) => ({
        value: condition.id,
        label: t("common.conditions." + condition.condition),
      }));
      setConditionOptions(_conditionOptions);

      //Size options
      let sizeValues = await asyncGetValidSizes();
      const _sizeOptions = sizeValues.data.map((size) => ({
        value: size.id,
        label: size.size,
      }));
      setSizeOptions(_sizeOptions);

      //Tool options
      const _toolOptions = dropdownValues.data.gears.map((gear) => ({
        value: gear.id,
        label: t("common.tools." + gear.value),
      }));
      setToolOptions(_toolOptions);

      //Treatment options
      const _treatmentOptions = dropdownValues.data.treatments.map((treatment) => ({
        value: treatment.id,
        label: t("common.treatments." + treatment.value),
      }));
      setTreatmentOptions(_treatmentOptions);

      //Currency options
      const _orderCurrency = dropdownValues.data.currencies.map((currency) => ({
        value: currency.id,
        label: currency.value.toUpperCase(),
      }));
      setOrderCurrencyOptions(_orderCurrency);

      //Companies options
      const networkCompanies = await asyncGetAllNetworkCompanies();
      const _networkCompanies = networkCompanies.data.companies.map((company) => ({
        value: company.id,
        label: company.companyName,
      }));
      setCompanyOptions(_networkCompanies);

      //Boxtype options
      const allBoxtypes = await asyncGetAllBoxtypes();
      const _allBoxtypes = allBoxtypes.data.boxtypes.map((boxtype) => ({
        value: boxtype.id,
        label: t("common.boxtypes." + boxtype.name),
        maxWeight: boxtype.maxWeight,
      }));
      setBoxtypeOptions(_allBoxtypes);
    }

    loadOptions();
  }, [
    t,
    updateOrderObject.salesItem,
    order.orderCurrency,
    updateOrderObject.condition.id,
    order.salesItem.id,
    currentCompany.companyId,
  ]);

  useEffect(() => {
    //Prefill selected condition
    const _selectedCondition = conditionOptions.find((condition) => condition.value === updateOrderObject.condition.id);
    setSelectedCondition(_selectedCondition);

    //Prefill selected size
    const _selectedSize = sizeOptions.find((size) => size.value === updateOrderObject.size.id);
    setSelectedSize(_selectedSize);

    //Prefill selected tool
    if (updateOrderObject.gear) {
      const _selectedTool = toolOptions.find((tool) => tool.value === updateOrderObject.gear.id);
      setSelectedTool(_selectedTool);
    }

    //Prefill selected treatment
    if (updateOrderObject.treatment) {
      const _selectedTreatment = treatmentOptions.find(
        (treatment) => treatment.value === updateOrderObject.treatment.id
      );
      setSelectedTreatment(_selectedTreatment);
    }

    //Prefill selected currency
    if (updateOrderObject.orderCurrency) {
      const _selectedOrderCurrency = orderCurrencyOptions.find(
        (currency) => currency.label === updateOrderObject.orderCurrency.value
      );
      setSelectedCurrency(_selectedOrderCurrency);
    }

    //Prefill selected target company
    if (updateOrderObject.companiesToTradeWith) {
      const _selectedCompanies = companyOptions.filter((company) =>
        updateOrderObject.companiesToTradeWith.includes(company.value)
      );
      setSelectedCompanies(_selectedCompanies);
    }

    //Prefill selected boxtype
    if (updateOrderObject.packaging.boxtype) {
      const _selectedBoxtype = boxtypeOptions.find((boxtype) => boxtype.value === updateOrderObject.packaging.boxtype);
      setSelectedBoxtype(_selectedBoxtype);
    }

    //Prefill amountDeviation
    const _amountDeviation = amountDeviationOptions.find(
      (deviation) => deviation.value === updateOrderObject.amountDeviation
    );
    setSelectedAmountDeviation(_amountDeviation);
    //HACKISH!: Backend automagically sets minamount = amount if minamount is empty (so minamount will never be empty), GUI wise only deviation OR minamount is relevant (backend uses deviation if it's set)
    //so we set minAmount to empty if deviation is set so prevent confusion for users.
    if (_amountDeviation) {
      updateOrderObject.minimumAmount = "";
    }
  }, [
    updateOrderObject,
    toolOptions,
    treatmentOptions,
    conditionOptions,
    sizeOptions,
    orderCurrencyOptions,
    companyOptions,
    boxtypeOptions,
    amountDeviationOptions,
  ]);

  async function asyncRefreshValidSizes() {
    const sizes = await getValidSizes(updateOrderObject.salesItem.id, updateOrderObject.condition.id);
    const _sizeOptions = sizes.data.map((size) => ({
      value: size.id,
      label: size.size,
    }));
    setSizeOptions(_sizeOptions);
  }

  function onChange(change) {
    setUpdateOrderObject((prevValue) => {
      return {
        ...prevValue,
        ...change,
      };
    });
  }

  function onChangeAmount(amount) {
    //(Create order in backend automagically sets minAmount). Settings amount lower than minAmount is not allowed so ->
    //If order is type purchase, changing Amount needs to update minAmount.
    if (order.orderType.id === OrderTypeEnum.BUY) onChange({ minimumAmount: amount });

    onChange({ amount: amount });

    // Amount needs to recalculate number of boxes
    if (order.orderType.id !== OrderTypeEnum.BUY) {
      const newNoOfBoxes = calculateNewNoOfBoxes(amount, null);
      onChangeNoBoxes(newNoOfBoxes);
    }
  }

  function onChangeCondition(e) {
    setSelectedCondition(e);
    // Size depends on Condition type (so reset this)
    setSelectedSize(null);
    asyncRefreshValidSizes();
    onChange({
      size: {
        id: 0,
        value: "",
      },
    });
    onChange({
      condition: {
        id: e.value,
        value: e.label,
      },
    });
  }

  function onChangeSize(e) {
    setSelectedSize(e);
    onChange({
      size: {
        id: e.value,
        value: e.label,
      },
    });
  }

  function onChangeTool(e) {
    setSelectedTool(e);

    if (e === null) {
      onChange({ gear: null });
    } else {
      onChange({
        gear: {
          id: e.value,
          value: e.label,
        },
      });
    }
  }

  function onChangeTreatment(e) {
    setSelectedTreatment(e);

    if (e === null) {
      onChange({ treatment: null });
    } else {
      onChange({
        treatment: {
          id: e.value,
          value: e.label,
        },
      });
    }
  }

  function onChangeCurrency(e) {
    setSelectedCurrency(e);
    onChange({
      orderCurrency: {
        id: e.value,
        value: e.label,
      },
    });
  }

  function onChangeSelectedCompanies(e) {
    setSelectedCompanies(e);
    if (e === null) {
      onChange({ companiesToTradeWith: [] });
    } else {
      const companies = e.map((company) => company.value);
      onChange({ companiesToTradeWith: companies });
    }
  }

  function calculateNewNoOfBoxes(amount, maxWeight) {
    // Calculate min amount of boxes required for chosen box type and amount of goods (to help user).
    const numberOfBoxesRaw =
      (amount ? amount : updateOrderObject.amount) / (maxWeight ? maxWeight : selectedBoxtype.maxWeight);
    const numberOfBoxesRounded = Math.ceil(numberOfBoxesRaw);

    return numberOfBoxesRounded;
  }

  function onChangeBoxtype(e) {
    const newNoOfBoxes = calculateNewNoOfBoxes(null, e.maxWeight);
    setSelectedBoxtype(e);
    setNoOfBoxes(newNoOfBoxes);

    onChange({
      packaging: {
        ...updateOrderObject.packaging,
        boxtype: e.value,
        numberOfBoxes: newNoOfBoxes,
      },
    });
  }

  function onChangeNoBoxes(e) {
    setNoOfBoxes(e);
    onChange({
      packaging: {
        ...updateOrderObject.packaging,
        numberOfBoxes: e,
      },
    });
  }

  function onChangeAmountDeviation(e) {
    setSelectedAmountDeviation(e);

    if (e === null) {
      onChange({ amountDeviation: 0 });
      return;
    }

    onChange({ amountDeviation: e.value });
  }

  // Order options context
  function orderOptions() {
    let optionsArray = [];
    optionsArray.push({
      label: (
        <div className="flex gap10">
          <SVGAsset name="trashcan" />
          <div className="font-family-polysans">{t("commonOrders.deleteOrder")}</div>
        </div>
      ),
      action: () => deleteOrderConfirmation(),
    });

    // Fjernet frem til auksjon blir implementert
    // if (order.orderType.id !== OrderTypeEnum.AUCTION) {
    //   optionsArray.unshift(
    //     {
    //       label: (
    //         <div className="flex gap10">
    //           <SVGAsset name="auction-gray" />
    //           <div className="font-family-polysans">{t('commonOrders.sendToAuction')}</div>
    //         </div>
    //       ),
    //       action: () => sendToAuction()
    //     },
    //   )
    // }

    return optionsArray;
  }

  // Fjernet frem til auksjon blir implementert
  // function sendToAuction() {
  //   console.log('send to autction handler');
  // }

  async function cancelOrder() {
    try {
      await deleteOrder(order.id);
      handleActiveOrder(null);
    } catch (error) {
      setErrorDialogOptions({ message: "Delete order failed", error });
      showErrorDialog(true);
    }
  }

  function deleteOrderConfirmation() {
    setDeleteOrderConfirmationConfig({
      isOpen: true,
      text: t("commonOrders.cancelTextConfirmation"),
    });
  }

  function closeCancelConfirmation() {
    setDeleteOrderConfirmationConfig({ isOpen: false, text: "" });
  }

  const updateOrderHandler = async () => {
    function validationSchemaType() {
      if (updateOrderObject.orderType === 1) {
        return updateOrderSchemaBuyOrder(t);
      } else if (updateOrderObject.orderType === 2) {
        return updateOrderSchemaSalesOrder(t);
      }
    }

    function validationOrderType() {
      if (updateOrderObject.orderType === 1) {
        return {
          price: updateOrderObject.price,
          maxPrice: updateOrderObject.maxPrice || 0,
          amount: parseFloat(updateOrderObject.amount),
          validFrom: updateOrderObject.validFrom,
          validTo: updateOrderObject.validTo,
        };
      } else if (updateOrderObject.orderType === 2) {
        return {
          price: updateOrderObject.price,
          minPrice: updateOrderObject.minPrice || 0,
          amount: parseFloat(updateOrderObject.amount),
          minimumAmount: parseFloat(updateOrderObject.minimumAmount) || 0,
          numberOfBoxes: updateOrderObject.packaging.numberOfBoxes,
          validFrom: updateOrderObject.validFrom,
          validTo: updateOrderObject.validTo,
        };
      }
    }

    const inputChecker = validateFieldsHelper(validationSchemaType(), validationOrderType());

    setValidationErrors(inputChecker);

    if (Object.keys(inputChecker).length === 0) {
      try {
        await postUpdateOrder(order.id, updateOrderObject);
        refreshActiveOrdersCB();
        setUpdateSuccess(true);
        setValidationErrors([]);
      } catch (error) {
        setUpdateSuccess(false);
        setErrorDialogOptions({ message: "Update order failed", error });
        showErrorDialog(true);
      }
    }
  };

  const promoteOrderHandler = async () => {
    try {
      await postPromoteOrder(order.id);
      setIsPromoted(true);
      setUpdateSuccess(true);
    } catch (error) {
      setErrorDialogOptions({ message: "Promoting order failed", error });
      showErrorDialog(true);
      setUpdateSuccess(false);
    }
  };

  return (
    <div className="active-order-details">
      <ModalDialog
        open={deleteOrderConfirmationConfig.isOpen}
        onClose={() => closeCancelConfirmation()}
        headerText={t("common.confirm")}
      >
        <p className="pad30">{deleteOrderConfirmationConfig.text}</p>
        <ModalDialogButtonsWrapper>
          <BlueButton
            id="delete-order-cancel"
            buttontype={BlueButton.buttontype.OnlyText}
            onClick={() => closeCancelConfirmation()}
            text={t("common.cancel")}
          />
          <BlueButton id="delete-order-confirm" onClick={() => cancelOrder()} text={t("common.accept")} />
        </ModalDialogButtonsWrapper>
      </ModalDialog>

      <div className="header">
        <div className="icon-type">
          <SVGAsset name={orderTypeIndicator} />
          <span className="title-text">
            <strong>{t("commonOrders.orderTypes." + order.orderType.type).toUpperCase()}</strong>
          </span>
        </div>
        <div className="valid-to-and-company">
          {updateOrderObject.orderType !== OrderTypeEnum.AUCTION && (
            <BlueButton
              id="active-order-details-promote"
              text={t("commonOrders.promoteOrder")}
              disabled={isPromoted}
              buttontype={BlueButton.buttontype.Primary}
              buttonsize={BlueButton.buttonsize.Small}
              onClick={() => promoteOrderHandler()}
            />
          )}
          {updateOrderObject.orderType !== OrderTypeEnum.AUCTION && (
            <BlueButton
              id="update-order-updateButton"
              text={t("commonOrders.updateOrder")}
              buttontype={BlueButton.buttontype.Primary}
              buttonsize={BlueButton.buttonsize.Small}
              onClick={updateOrderHandler}
            />
          )}
          <div className="inline-label-value">
            <span className="label">{t("commonOrders.standsTill")}</span>
            <div className="value">
              <Moment format="DD.MM.YYYY">{updateOrderObject.validTo}</Moment>
            </div>
          </div>
          <div>
            {/* None of the options are relevant for Auction orders */}
            {order.orderType.id !== OrderTypeEnum.AUCTION && (
              <ContextPanel contextType={ContextPanel.contextType.Secondary} options={orderOptions()} />
            )}
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="flex full col">
          <Grid>
            <Grid.Row>
              <Grid.Col span={12}>
                {updateSuccess && Object.keys(validationErrors).length === 0 ? (
                  <MessageBar
                    className="mtop10"
                    type={MessageBar.type.Positive}
                    message={`${t("commonOrders.orderUpdated")}`}
                  />
                ) : null}
                {Object.keys(validationErrors).length > 0 ? (
                  <MessageBar
                    className="mtop10"
                    type={MessageBar.type.Negative}
                    message={`${t("commonOrders.commonMissingFields")}`}
                  />
                ) : null}
              </Grid.Col>
            </Grid.Row>
          </Grid>
          <Grid className="border-bottom">
            <Grid.Row>
              <Grid.Col className="border-salesitem" span={4}>
                <div className="data-field font-family-polysans">
                  <div className="label">{t("commonOrders.type")}</div>
                  <div className="value">
                    <strong>{t("common.fish." + order.salesItem.salesItem)}</strong>
                  </div>
                </div>
              </Grid.Col>
              <Grid.Col className="pad10 border-condition" span={5}>
                <Dropdown
                  id="update-order-condition"
                  label={t("common.condition")}
                  isDisabled={isDisabled}
                  overrideStyle={bigDropdownStyle}
                  options={conditionOptions}
                  value={selectedCondition}
                  onChange={(e) => onChangeCondition(e)}
                />
              </Grid.Col>
              <Grid.Col className="border-size" span={3}>
                <Dropdown
                  id="update-order-size"
                  label={t("common.size")}
                  isDisabled={isDisabled}
                  overrideStyle={bigDropdownStyle}
                  options={sizeOptions}
                  value={selectedSize}
                  onChange={(e) => onChangeSize(e)}
                />
              </Grid.Col>
            </Grid.Row>
          </Grid>
          <Grid className="pad10">
            {updateOrderObject.orderType === OrderTypeEnum.AUCTION && (
              <Grid.Row>
                <Grid.Col span={12}>
                  <Checkbox
                    id="update-order-automaticAuction"
                    label={t("commonOrders.automaticSO")}
                    disabled={isDisabled}
                    checked={updateOrderObject.autoSalesOrder}
                    onChange={(e) => onChange({ autoSalesOrder: e.checked })}
                  />
                </Grid.Col>
              </Grid.Row>
            )}
          </Grid>
          <Grid span={12} className="mtop20 content">
            <Grid.Row>
              <Grid.Col span={3}>
                <TextField
                  id={"txtdropdown-update-order"}
                  label={t("common.price") + " (per kg)"}
                  type="number"
                  disabled={isDisabled}
                  value={updateOrderObject.price}
                  onChange={(e) => onChange({ price: e.target.value })}
                  errormessage={validationErrors.price}
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <Dropdown
                  id="currency"
                  label={t("common.currency")}
                  isDisabled={updateOrderObject.orderType === OrderTypeEnum.AUCTION}
                  options={orderCurrencyOptions}
                  value={selectedCurrency}
                  onChange={(e) => onChangeCurrency(e)}
                />
              </Grid.Col>
              <Grid.Col span={3}>
                {updateOrderObject.orderType === OrderTypeEnum.BUY && (
                  <TextField
                    id="update-order-maxprice"
                    label={t("commonOrders.maxPrice") + " (per kg)"}
                    type="number"
                    value={updateOrderObject.maxPrice}
                    disabled={updateOrderObject.orderType === OrderTypeEnum.AUCTION}
                    onChange={(e) => onChange({ maxPrice: e.target.value })}
                    errormessage={validationErrors.maxPrice}
                  />
                )}
                {(updateOrderObject.orderType === OrderTypeEnum.SELL ||
                  updateOrderObject.orderType === OrderTypeEnum.AUCTION) && (
                  <TextField
                    id="update-order-minprice"
                    label={t("commonOrders.minPrice") + " (per kg)"}
                    type="number"
                    value={updateOrderObject.minPrice}
                    disabled={updateOrderObject.orderType === OrderTypeEnum.AUCTION}
                    onChange={(e) => onChange({ minPrice: e.target.value })}
                    errormessage={validationErrors.minPrice}
                  />
                )}
              </Grid.Col>
              <Grid.Col span={3}>
                <Dropdown
                  id="update-order-boxtype"
                  label={t("common.boxtype")}
                  options={boxtypeOptions}
                  isDisabled={
                    updateOrderObject.orderType === OrderTypeEnum.BUY ||
                    updateOrderObject.orderType === OrderTypeEnum.AUCTION
                  }
                  value={updateOrderObject.orderType !== OrderTypeEnum.BUY ? selectedBoxtype : null}
                  onChange={(e) => onChangeBoxtype(e)}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col span={3}>
                <TextField
                  id="update-order-amount"
                  label={t("common.amount") + " (kg)"}
                  type="number"
                  disabled={isDisabled}
                  value={updateOrderObject.amount}
                  onChange={(e) => onChangeAmount(e.target.value)}
                  errormessage={validationErrors.amount}
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <Dropdown
                  id="deviation-amount"
                  label={"+/-"}
                  options={amountDeviationOptions}
                  value={selectedAmountDeviation}
                  onChange={(e) => onChangeAmountDeviation(e)}
                  isDisabled={updateOrderObject.orderType === OrderTypeEnum.AUCTION || updateOrderObject.minimumAmount}
                  IsClearable
                  ClearValue={() => onChangeAmountDeviation(null)}
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <TextField
                  id="update-order-minimumAmount"
                  label={t("dashboard.minimumAmount") + " (kg)"}
                  type="number"
                  disabled={
                    updateOrderObject.amountDeviation ||
                    updateOrderObject.orderType === OrderTypeEnum.BUY ||
                    updateOrderObject.orderType === OrderTypeEnum.AUCTION
                  }
                  value={updateOrderObject.orderType !== OrderTypeEnum.BUY ? updateOrderObject.minimumAmount : null}
                  onChange={(e) => onChange({ minimumAmount: e.target.value })}
                  errormessage={validationErrors.minimumAmount}
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <TextField
                  id="update-order-noboxes"
                  type="number"
                  label={t("common.noofboxes")}
                  value={updateOrderObject.orderType !== OrderTypeEnum.BUY ? noOfBoxes : null}
                  disabled={
                    updateOrderObject.orderType === OrderTypeEnum.AUCTION ||
                    updateOrderObject.orderType === OrderTypeEnum.BUY
                  }
                  onChange={(e) => onChangeNoBoxes(e.target.value)}
                  errormessage={validationErrors.numberOfBoxes}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col span={3}>
                <Dropdown
                  id="update-order-treatment"
                  label={t("common.treatment")}
                  isDisabled={isDisabled}
                  options={treatmentOptions}
                  value={selectedTreatment}
                  onChange={(e) => onChangeTreatment(e)}
                  isClearable
                  clearValue={() => onChangeTreatment(null)}
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <Dropdown
                  id="update-order-tool"
                  label={t("common.tool")}
                  isDisabled={isDisabled}
                  options={toolOptions}
                  value={selectedTool}
                  onChange={(e) => onChangeTool(e)}
                  isClearable
                  clearValue={() => onChangeTool(null)}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <DropdownMulti
                  id="target-company"
                  label={t("common.companyToTradeWith")}
                  disabled={isDisabled}
                  options={companyOptions}
                  menuPlacement="top"
                  value={selectedCompanies}
                  isDisabled={updateOrderObject.orderType === OrderTypeEnum.AUCTION}
                  onChange={(e) => onChangeSelectedCompanies(e)}
                  isClearable
                  clearValue={() => onChangeSelectedCompanies(null)}
                />
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </div>
        <div className="flex col image-background">
          <div>{t("common.fish." + order.salesItem.salesItem)}</div>
          <img className="image" src={salesItemImg} alt="" />
        </div>
      </div>

      <div className="header">
        <div className="icon-type">
          <SVGAsset name="calendar-white" />
          <span className="title-text">
            <strong>{t("common.times").toUpperCase()}</strong>
          </span>
        </div>
      </div>
      <Grid className="mtop10 content">
        <Grid.Row>
          <Grid.Col span={3}>
            <DatePicker
              id="update-order-validFrom"
              label={t("common.validFrom")}
              disabled={isDisabled}
              value={updateOrderObject.validFrom}
              onChange={(e) => onChange({ validFrom: e })}
              errormessage={validationErrors.validFrom}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <DatePicker
              id="update-order-validTo"
              label={t("common.validTo")}
              disabled={isDisabled}
              value={updateOrderObject.validTo}
              onChange={(e) => onChange({ validTo: e })}
              setmindate={moment(updateOrderObject.validFrom).toDate()}
              setmaxdate={moment(updateOrderObject.validFrom).add(14, "d").toDate()}
              errormessage={validationErrors.validTo}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <DatePicker
              id="update-order-deliveryStart"
              label={t("commonOrders.deliveryStart")}
              disabled={isDisabled}
              value={updateOrderObject.deliveryStart}
              onChange={(e) => onChange({ deliveryStart: e })}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <DatePicker
              id="update-order-deliveryEnd"
              label={t("commonOrders.deliveryEnd")}
              disabled={isDisabled}
              value={updateOrderObject.deliveryEnd}
              onChange={(e) => onChange({ deliveryEnd: e })}
            />
          </Grid.Col>
          {/* <DatePicker
              id="update-order-captureDate"
              label={t('commonOrders.captureDate')}
              disabled={isDisabled}
              value={updateOrderObject.catchDate}
              onChange={e => onChange({ catchDate: e })}
            />
            <DatePicker
              id="update-order-packingDate"
              label={t('commonOrders.packingDate')}
              disabled={isDisabled}
              value={updateOrderObject.packingDate}
              onChange={e => onChange({ packingDate: e })}
            />
            <DatePicker
              id="update-order-expirationDate"
              label={t('commonOrders.expirationDate')}
              disabled={isDisabled}
              value={updateOrderObject.expirationDate}
              onChange={e => onChange({ expirationDate: e })}
            /> */}
          {order.orderType.id === OrderTypeEnum.BUY && (
            <Grid.Col span={3}>
              <DatePicker
                id="update-order-lastDeliveryDate"
                label={t("commonOrders.lastDeliveryDate")}
                disabled={isDisabled}
                value={updateOrderObject.lastDeliveryDate}
                onChange={(e) => onChange({ lastDeliveryDate: e })}
              />
            </Grid.Col>
          )}
        </Grid.Row>
      </Grid>
    </div>
  );
}
