import React from "react";
import SVGAsset from "../SVGAsset/SVGAsset";

import "./HeadingWithInfoText.css";

function HeadingWithInfoText(props) {
  return (
    <div className={`${props.className} heading-with-info`}>
      <div className="info-heading">{props.header}</div>
      {props.showInfo && (
        <div className="info-tooltip">
          <SVGAsset name="circle-questionmark"></SVGAsset>
          <p className="text">{props.infoText}</p>
        </div>
      )}
    </div>
  );
}

export default HeadingWithInfoText;
