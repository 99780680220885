import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { postCreateFeedback } from "../../../services/Feedback";
import { ErrorDialogContext } from "../../../contexts/ErrorDialogContext";

import Feedback from "../../../components/Feedback/Feedback";
import Spinner from "../../../components/Spinner/Spinner";
import "./FeedbackTask.css";

export default function FeedbackTask(props) {
  const errorDialogContext = useContext(ErrorDialogContext);
  const { showErrorDialog, setErrorDialogOptions } = errorDialogContext;
  const { taskId, transactionId, orderId, senderUserId, onCloseCB } = props;

  const [loadingFeedback, setLoadingFeedback] = useState(false);

  async function submitFeedbackCB(stars, feedback) {
    setLoadingFeedback(true);
    try {
      await postCreateFeedback(taskId, transactionId, orderId, feedback, stars, senderUserId);
      setLoadingFeedback(false);
      onCloseCB();
    } catch (error) {
      setErrorDialogOptions({
        message: "Submitting feedback failer, try again later.",
        error,
      });
      showErrorDialog(true);
    }
  }

  return (
    <div className="feedback-task-container">
      {<Spinner loading={loadingFeedback} inline={true} align="middle" />}
      <Feedback submitFeedbackCB={submitFeedbackCB} />
    </div>
  );
}

FeedbackTask.propTypes = {
  taskId: PropTypes.number.isRequired,
  transactionId: PropTypes.number.isRequired,
  senderUserId: PropTypes.number.isRequired,
};
