import React, { useEffect, useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { postHandleTransport, postCalculateTransportPrices } from "../../../services/Orders";
import { getCompanyById } from "../../../services/Company";
import { getCountries } from "../../../services/RegisterCompany";
import { ErrorDialogContext } from "../../../contexts/ErrorDialogContext";
import { CSSTransition } from "react-transition-group";

import Dropdown from "../../../components/Dropdown/Dropdown";
import Button from "../../../components/BlueButton/BlueButton";
import SVGAsset from "../../../components/SVGAsset/SVGAsset";
import Spinner from "../../../components/Spinner/Spinner";
import Checkbox from "../../../components/Checkbox/Checkbox";
import Grid from "../../../components/Grid/Grid";
import Textfield from "../../../components/TextField/TextField";
import MessageBar from "../../../components/MessageBar/MessageBar";

import "./TransportTask.css";

export default function TrnasportTask(props) {
  const { t } = useTranslation();
  const errorDialogContext = useContext(ErrorDialogContext);
  const { showErrorDialog, setErrorDialogOptions } = errorDialogContext;

  const { orderId, taskId, currentCompany, onCloseCB } = props;

  const [transportCompanies, setTransportCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTransportCompany, setSelectedTransportCompany] = useState({});
  //Diff address
  const [isDiffAddressChecked, setIsDiffAddressChecked] = useState(false);
  const diffAddressNodeRef = useRef(null);
  const [address, setAddress] = useState("");
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [postCode, setPostCode] = useState("");
  const [place, setPlace] = useState("");
  const [isDeliveryErrorVisible, setIsDeliveryErrorVisible] = useState(false);

  // Init load transport companies
  useEffect(() => {
    async function loadTransportCompanies() {
      const companyResult = await getCompanyById(currentCompany.companyId);
      const currentCompanyFull = companyResult.data;
      const shippingAddress = currentCompanyFull.companyAddress.find((x) => x.addressType === "SHIPPING");

      const result = await postCalculateTransportPrices(
        orderId,
        shippingAddress.address,
        shippingAddress.postCode,
        shippingAddress.place,
        shippingAddress.country.iso3,
        shippingAddress.country.iso2,
        shippingAddress.country.isEu
      );

      const _transportOptions = result.data.map((transport) => ({
        value: transport.companyId,
        label: `${transport.companyName} - ${transport.price[currentCompany.companyCurrency].toFixed(2)} ${currentCompany.companyCurrency}, per kg - ${transport.delivery.transportNumberOfDays}
        ${t("commonOrders.days")}`,
      }));
      setTransportCompanies(_transportOptions);
      if (_transportOptions.length > 0) {
        setIsDeliveryErrorVisible(false);
      } else {
        setIsDeliveryErrorVisible(true);
      }
    }
    loadTransportCompanies();
  }, [currentCompany, t, orderId]);

  // Init load countries
  useEffect(() => {
    async function loadCountries() {
      const result = await getCountries();

      const _countryOptions = result.data.map((country) => ({
        value: country.iso2,
        iso3: country.iso3,
        iso2: country.iso2,
        isEu: country.isEu,
        label: country.name,
      }));
      setCountryOptions(_countryOptions);
    }

    loadCountries();
  }, []);

  async function submitHandler() {
    if (isDiffAddressChecked) {
      const valid = await validateDelivery();
      if (!valid) return;
    }

    setIsLoading(true);

    try {
      await postHandleTransport(orderId, [selectedTransportCompany.value], taskId);
      setIsLoading(false);
      onCloseCB();
    } catch (error) {
      setErrorDialogOptions({
        message: "Saving transport choice failed",
        error,
      });
      showErrorDialog(true);
    }
  }

  async function validateDelivery() {
    const result = await postCalculateTransportPrices(
      orderId,
      address,
      postCode,
      place,
      selectedCountry.iso3,
      selectedCountry.iso2,
      selectedCountry.isEu
    );

    const _transportOptions = result.data.map((transport) => ({
      value: transport.companyId,
      label: `${transport.companyName} - ${transport.price[currentCompany.companyCurrency].toFixed(2)} ${currentCompany.companyCurrency}, per kg - ${transport.delivery.transportNumberOfDays}
        ${t("commonOrders.days")}`,
    }));
    setTransportCompanies(_transportOptions);

    setSelectedTransportCompany({});

    if (_transportOptions.length > 0) {
      setIsDeliveryErrorVisible(false);
      return true;
    } else {
      setIsDeliveryErrorVisible(true);
      return false;
    }
  }

  return (
    <div className="transport-container">
      <div className="flex gap10 mbottom10">
        <SVGAsset name="transport" />
        <label style={{ alignSelf: "flex-end" }}>{t("tasks.transportSelect")}</label>
      </div>
      <div className="flex gap10 align mbottom20">
        <Dropdown
          id="transportDropdown"
          options={transportCompanies}
          onChange={(e) => setSelectedTransportCompany(e)}
        />
        <Button
          id="submit"
          text={t("tasks.submit")}
          disabled={Object.keys(selectedTransportCompany).length < 1}
          onClick={() => submitHandler()}
        />
        <Spinner loading={isLoading} inline={true} align="middle" />
      </div>
      {isDeliveryErrorVisible && (
        <MessageBar
          className="break-word mbottom20"
          type="Negative"
          noIcon
          message={t("commonOrders.deliveryNotPossible")}
        />
      )}
      <div>
        <Checkbox
          label={t("commonOrders.differentAddress")}
          checked={isDiffAddressChecked}
          onChange={(e) => setIsDiffAddressChecked(!isDiffAddressChecked)}
        />
        <CSSTransition
          in={isDiffAddressChecked}
          timeout={{
            enter: 1000,
            exit: 300,
          }}
          mountOnEnter
          unmountOnExit
          nodeRef={diffAddressNodeRef}
        >
          <div ref={diffAddressNodeRef}>
            <Grid>
              <Grid.Col span={6}>
                <Textfield
                  id="address-drawer"
                  label={t("commonOrders.orderAddress")}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <Dropdown
                  id="country-drawer"
                  label={t("common.country")}
                  options={countryOptions}
                  onChange={(e) => setSelectedCountry(e)}
                  value={selectedCountry}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <Textfield
                  id="postCode-drawer"
                  label={t("commonOrders.postCode")}
                  value={postCode}
                  onChange={(e) => setPostCode(e.target.value)}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <Textfield
                  id="place-drawer"
                  label={t("commonOrders.place")}
                  value={place}
                  onChange={(e) => setPlace(e.target.value)}
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <Button
                  id="checkAddress"
                  text={t("commonOrders.calculatePrice")}
                  disabled={!selectedCountry || !postCode || !place}
                  onClick={() => validateDelivery()}
                />
              </Grid.Col>
            </Grid>
          </div>
        </CSSTransition>
      </div>
    </div>
  );
}
