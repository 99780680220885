import React, { useEffect, useState } from "react";
import { VictoryChart, VictoryTheme, VictoryGroup, VictoryLine, VictoryAxis } from "victory";
import { getTotalSales } from "../../services/Graphs";
import { useTranslation } from "react-i18next";
import { abbreviateNumber } from "../../utils/Formatting";

// import moment from 'moment-timezone'

import "./Charts.css";

export default function AccumulatedSalesChart(props) {
  const { t } = useTranslation();

  const { fromDate, toDate } = props;
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    async function asyncGetTotalSales() {
      const resp = await getTotalSales(fromDate, toDate);
      setGraphData(resp.data);
    }

    asyncGetTotalSales();
  }, [fromDate, toDate]);

  return (
    <div className="charts-container">
      <h3>{t("charts.totalSales").toUpperCase()}</h3>

      <VictoryChart theme={VictoryTheme.grayscale} height={150} padding={{ top: 20, bottom: 40, left: 50, right: 50 }}>
        <VictoryAxis
          dependentAxis={true}
          style={{
            grid: { stroke: "grey", strokeWidth: 1 },
            tickLabels: { fontSize: 9, fontFamily: "inherit" },
            axis: { stroke: "transparent" },
          }}
          tickFormat={(x) => abbreviateNumber(x, 0)}
        />
        <VictoryAxis
          style={{
            grid: {
              stroke: "lightgrey",
              strokeDasharray: "8,3",
              strokeWidth: 0.5,
            },
            tickLabels: { fontSize: 9, fontFamily: "inherit" },
          }}
          // tickFormat={graphData.map(x => moment(x.time).format('DD.MM.YYYY'))}
          fixLabelOverlap={true}
        />
        <VictoryGroup
          style={{
            data: { strokeWidth: 2 },
          }}
        >
          <VictoryLine
            style={{
              data: { stroke: "var(--SFPgreen)" },
            }}
            interpolation="linear"
            data={graphData}
            y="value"
          />
        </VictoryGroup>
      </VictoryChart>
    </div>
  );
}

// Dummy data om det skulle være nødvendig
// const chartData = [
//   { time: 'Date A', value: 300, pv: 2400, amt: 2400 },
//   { time: 'Date B', value: 900, pv: 2222, amt: 500 },
//   { time: 'Date C', value: 250, pv: 2300, amt: 300 },
//   { time: 'Date D', value: 600, pv: 200, amt: 1100 },
//   { time: 'Date E', value: 300, pv: 700, amt: 1300 },
//   { time: 'Date F', value: 900, pv: 100, amt: 1500 },
//   { time: 'Date G', value: 350, pv: 2150, amt: 2100 },
//   { time: 'Date H', value: 1000, pv: 900, amt: 1100 },
//   { time: 'Date I', value: 100, pv: 950, amt: 1500 },
//   { time: 'Date J', value: 600, pv: 100, amt: 2100 },
//   { time: 'Date K', value: 250, pv: 0, amt: 2100 },
// ];
