import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const ErrorDialogContext = createContext({});

export const Provider = (props) => {
  const { isErrorDialogOpen: initialOpen, children } = props;

  const [isErrorDialogOpen, showErrorDialog] = useState(initialOpen);
  const [errorDialogOptions, setErrorDialogOptions] = useState({});

  const errorDialogContext = {
    isErrorDialogOpen,
    showErrorDialog,
    errorDialogOptions,
    setErrorDialogOptions,
  };

  return <ErrorDialogContext.Provider value={errorDialogContext}>{children}</ErrorDialogContext.Provider>;
};

export const { Consumer } = ErrorDialogContext;

Provider.propTypes = {
  isErrorDialogOpen: PropTypes.bool,
};

Provider.defaultProps = {
  isErrorDialogOpen: false,
};
