import React from "react";
import { Route, useRouteMatch } from "react-router";
import { Switch } from "react-router-dom";
import DoCheckout from "./DoCheckout";
import CheckoutSuccess from "./CheckoutSuccess";

export default function Checkout() {
  const { path } = useRouteMatch();

  return (
    <div>
      <div className="checkout-container">
        <Switch>
          <Route path={`${path}/success`} component={CheckoutSuccess} />
          <Route path={`${path}/`} component={DoCheckout} />
        </Switch>
      </div>
    </div>
  );
}
