import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getSalesItemById } from "../../../../services/Orders";

import Grid from "../../../../components/Grid/Grid";
import StepLabel from "../../../../components/Wizard/StepLabel/StepLabel";

import "./InformationStep.css";

export default function InformationStep(props) {
  const { t } = useTranslation();
  const { orderValues } = props;

  const [salesItemImg, setSalesItemImg] = useState("");

  useEffect(() => {
    //TODO: Optimizing, could potentially only do this for screens larger than phone (this comp not visible on small screens)
    async function asyncGetSalesItemById() {
      const res = await getSalesItemById(orderValues.salesItem.id);
      setSalesItemImg(require("../../../../assets/img" + res.data.imageUrl));
    }

    if (orderValues.salesItem && orderValues.salesItem.id > 0) {
      asyncGetSalesItemById();
    } else {
      setSalesItemImg("");
    }
  }, [orderValues.salesItem, orderValues.salesItem.id]);

  return (
    <div className="information-step-container">
      <div className="information-step-header">
        <StepLabel label={t("common.information").toUpperCase()} notStep={true} />
      </div>
      <Grid className="container">
        <Grid.Row>
          <Grid.Col span={12}>
            <img style={{ width: "100%", height: "auto" }} src={salesItemImg} alt="" />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={12} className="information-step-price">
            <p className="InformationStepInfo">{t("commonOrders.informationTip1")}</p>
            <p className="InformationStepInfo">{t("commonOrders.informationTip2")}</p>
            <p className="InformationStepInfo">{t("commonOrders.informationTip3")}</p>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </div>
  );
}
