import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";

import "../Dropdown/Dropdown.css";

const customStyles = {
  control: (base) => ({
    ...base,
    borderRadius: "5px",
    border: "2px solid var(--SFPlightBorderGray)",
    minHeight: "53px",
    ":hover": {
      borderColor: "#0000FF",
    },
    ":focus-within": {
      borderColor: "#0000FF",
      boxShadow: "none",
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "black",
  }),
  valueContainer: (base) => ({
    ...base,
    maxWidth: "fit-content",
    minWidth: "200px",
  }),
};

export default function DropdownMulti(props) {
  const { placeholder } = props;
  return (
    <div className="dropdown-area">
      <label>{props.label}</label>
      <Select
        styles={customStyles}
        placeholder={placeholder ? placeholder : false}
        noOptionsMessage={() => "..."}
        isMulti
        {...props}
      />
    </div>
  );
}

DropdownMulti.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};
