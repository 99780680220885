import Joi from "joi";

export function registerCompanyStep1Schema(translation) {
  const t = translation;

  return Joi.object({
    countryCodeIso3: Joi.string()
      .required()
      .messages({
        "string.empty": t("validation.countryRequired"),
        "any.required": t("validation.countryRequired"),
      }),
    name: Joi.string()
      .required()
      .max(100)
      .messages({
        "string.base": t("validation.companyNameRequired"),
        "string.empty": t("validation.companyNameRequired"),
        "any.required": t("validation.companyNameRequired"),
        "string.max": t("validation.companyNameMaxLength"),
      }),
    vatNumber: Joi.string()
      .alphanum()
      .min(8)
      .max(12)
      .required()
      .messages({
        "string.base": t("validation.vatNumberRequired"),
        "string.empty": t("validation.vatNumberRequired"),
        "any.required": t("validation.vatNumberRequired"),
        "string.min": t("validation.vatNumberLength"),
        "string.max": t("validation.vatNumberLength"),
        "string.alphanum": t("validation.vatNumberAlphanumeric"),
      }),
    leiNumber: Joi.string()
      .allow(null, "")
      .alphanum()
      .min(20)
      .max(20)
      .messages({
        "string.min": t("validation.leiNumberLength"),
        "string.max": t("validation.leiNumberLength"),
        "string.alphanum": t("validation.leiNumberAlphanumeric"),
      }),
    phone: Joi.number()
      .min(99999)
      .unsafe()
      .max(999999999999999999999999999999)
      .required()
      .messages({
        "number.base": t("validation.phoneNumberRequired"),
        "any.required": t("validation.phoneNumberRequired"),
        "number.max": t("validation.phoneNumberMaxLength"),
        "number.min": t("validation.phoneNumberMaxLength"),
        "number.unsafe": t("validation.phoneNumberMaxLength"),
      }),
    email: Joi.string()
      .max(100)
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        "string.base": t("validation.emailRequired"),
        "string.empty": t("validation.emailRequired"),
        "string.max": t("validation.emailMaxLength"),
        "string.email": t("validation.emailFormatError"),
        "any.required": t("validation.emailRequired"),
      }),
    creditLimit: Joi.number()
      .unsafe()
      .max(1000000000)
      .positive()
      .required()
      .messages({
        "number.base": t("validation.creditLimitRequired"),
        "any.required": t("validation.creditLimitRequired"),
        "number.max": t("validation.creditLimitMax"),
        "number.positive": t("validation.creditLimitRequired"),
        "number.unsafe": t("validation.creditLimitRequired"),
      }),
    invoiceDaysBeforeDueDate: Joi.number()
      .min(0)
      .max(999)
      .required()
      .messages({
        "number.empty": t("validation.invoiceDaysRequired"),
        "any.required": t("validation.invoiceDaysRequired"),
      }),
    settlementDays: Joi.number()
      .min(0)
      .max(999)
      .required()
      .messages({
        "number.empty": t("validation.settlementDaysRequired"),
        "any.required": t("validation.settlementDaysRequired"),
      }),
  }).unknown();
}

export function registerCompanyStep1SchemaAddress(translation) {
  const t = translation;

  return Joi.object({
    address: Joi.string()
      .max(100)
      .required()
      .messages({
        "string.base": t("validation.addressRequired"),
        "string.empty": t("validation.addressRequired"),
        "any.required": t("validation.addressRequired"),
        "string.max": t("validation.addressMaxLength"),
      }),
    addressType: Joi.alternatives(
      Joi.object({
        value: Joi.string(),
        label: Joi.string(),
      })
    )
      .required()
      .messages({
        "object.base": t("validation.addressTypeRequired"),
        "any.required": t("validation.addressTypeRequired"),
        "alternatives.match": t("validation.addressTypeRequired"),
      }),
    countryId: Joi.alternatives(
      Joi.object({
        value: Joi.number(),
        label: Joi.string(),
        iso3Code: Joi.string(),
      })
    )
      .required()
      .messages({
        "object.base": t("validation.countryRequired"),
        "any.required": t("validation.countryRequired"),
        "alternatives.match": t("validation.countryRequired"),
      }),
    place: Joi.string()
      .max(100)
      .required()
      .messages({
        "string.base": t("validation.placeRequired"),
        "string.empty": t("validation.placeRequired"),
        "any.required": t("validation.placeRequired"),
        "string.max": t("validation.placeMaxLength"),
      }),
    postCode: Joi.number()
      .unsafe()
      .max(9999999999)
      .required()
      .messages({
        "number.base": t("validation.postCodeRequired"),
        "any.required": t("validation.postCodeRequired"),
        "number.max": t("validation.postCodeMaxLength"),
        "number.unsafe": t("validation.postCodeMaxLength"),
      }),
  }).unknown();
}

export function registerCompanyStep3SchemaBank(translation) {
  const t = translation;

  return Joi.object({
    name: Joi.string()
      .max(255)
      .required()
      .messages({
        "string.base": t("validation.bankAccountNameRequired"),
        "string.empty": t("validation.bankAccountNameRequired"),
        "any.required": t("validation.bankAccountNameRequired"),
        "string.max": t("validation.bankAccountNameMaxLength"),
      }),
    iban: Joi.string()
      .max(255)
      .required()
      .messages({
        "string.base": t("validation.ibanRequired"),
        "string.empty": t("validation.ibanRequired"),
        "any.required": t("validation.ibanRequired"),
        "string.max": t("validation.ibanMaxLength"),
      }),
    accountNo: Joi.number()
      .unsafe()
      .allow(1, 2, 3, 4, 5, 6, 7, 8, 9, 0)
      .integer()
      .positive()
      .max(99999999999999999999)
      .required()
      .messages({
        "number.base": t("validation.bankAccountNumberRequired"),
        "any.required": t("validation.bankAccountNumberRequired"),
        "number.max": t("validation.bankAccountNumberMaxLength"),
        "number.integer": t("validation.bankAccountNumbersOnly"),
        "number.positive": t("validation.bankAccountNumbersOnly"),
        "number.unsafe": t("validation.bankAccountNumberMaxLength"),
      }),
  }).unknown();
}

export function registerCompanyStep3SchemaAccount(translation) {
  const t = translation;

  return Joi.object({
    languageId: Joi.number()
      .required()
      .messages({
        "number.base": t("validation.languageRequired"),
        "any.required": t("validation.languageRequired"),
        "number.required": t("validation.languageRequired"),
      }),
    timeZone: Joi.alternatives(
      Joi.object({
        value: Joi.string(),
        label: Joi.string(),
      })
    )
      .required()
      .messages({
        "object.base": t("validation.timeZoneRequired"),
        "any.required": t("validation.timeZoneRequired"),
        "alternatives.match": t("validation.timeZoneRequired"),
      }),
    companyProvidesTransport: Joi.bool()
      .required()
      .messages({
        "boolean.base": t("validation.companyProvidesTransport"),
        "any.required": t("validation.companyProvidesTransport"),
        "boolean.required": t("validation.companyProvidesTransport"),
      }),
    companyType: Joi.alternatives(
      Joi.object({
        value: Joi.string(),
        label: Joi.string(),
      })
    )
      .required()
      .messages({
        "object.base": t("validation.companyTypeRequired"),
        "any.required": t("validation.companyTypeRequired"),
        "alternatives.match": t("validation.companyTypeRequired"),
      }),
    membershipType: Joi.alternatives(
      Joi.object({
        value: Joi.string(),
        label: Joi.string(),
      })
    )
      .required()
      .messages({
        "object.base": t("validation.membershipTypeRequired"),
        "any.required": t("validation.membershipTypeRequired"),
        "alternatives.match": t("validation.membershipTypeRequired"),
      }),
  }).unknown();
}

export function registerCompanyStep4SchemaAddMember(translation) {
  const t = translation;

  return Joi.object({
    firstname: Joi.string()
      .required()
      .messages({
        "string.empty": t("validation.firstNameRequired"),
        "any.required": t("validation.firstNameRequired"),
      }),
    lastname: Joi.string()
      .required()
      .messages({
        "string.empty": t("validation.lastNameRequired"),
        "any.required": t("validation.lastNameRequired"),
      }),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        "string.empty": t("validation.emailRequired"),
        "string.email": t("validation.emailFormatError"),
        "any.required": t("validation.emailRequired"),
      }),
    roleType: Joi.string()
      .required()
      .messages({
        "string.empty": t("validation.roleTypeRequired"),
        "any.required": t("validation.roleTypeRequired"),
      }),
  }).unknown();
}

export function registerCompanyStep6SchemaTerms(translation) {
  const t = translation;

  return Joi.object({
    terms: Joi.boolean()
      .invalid(false)
      .required()
      .messages({
        "any.required": t("validation.termsRequired"),
        "any.invalid": t("validation.termsRequired"),
        "any.base": t("validation.termsRequired"),
      }),
  }).unknown();
}
