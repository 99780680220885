import React from "react";
import PropTypes from "prop-types";

const SVGAsset = (props) => {
  return (
    <img
      {...props}
      src={require(`../../assets/svg/${props.name}.svg`)}
      alt={props.caption}
      className={`svg ${props.className || ""}`}
      id={props.id}
      onClick={props.onClick}
    />
  );
};

SVGAsset.propTypes = {
  name: PropTypes.string,
  caption: PropTypes.string,
  className: PropTypes.string,
};

export default SVGAsset;
