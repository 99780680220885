import { axios, APIBASE_REVIVERUSERS } from "./ApiConfig";

export const startPartialCompany = async (userId) =>
  axios.post(`${APIBASE_REVIVERUSERS}api/company/registration/start`, {
    userId,
    partialCompany: {},
  });

export const getPartialCompany = async (userId) =>
  axios.get(`${APIBASE_REVIVERUSERS}api/company/registration/${userId}`);

export const updatePartialCompany = async (draft) =>
  axios.post(`${APIBASE_REVIVERUSERS}api/company/registration/update`, draft);

export const getCountries = async () => axios.get(`${APIBASE_REVIVERUSERS}api/company/get/countries`);

export const getCompanyTypes = async () => axios.get(`${APIBASE_REVIVERUSERS}api/company/get/types`);

export const getLanguages = async () => axios.get(`${APIBASE_REVIVERUSERS}api/language/all`);

export const getTimeZones = async () => axios.get(`${APIBASE_REVIVERUSERS}api/company/timezone/all`);

export const getAddressTypes = async () => axios.get(`${APIBASE_REVIVERUSERS}api/company/get/address/types`);

export const completePartialCompany = async (draft) =>
  axios.post(`${APIBASE_REVIVERUSERS}api/company/registration/submit`, draft);

export const getMembershipTypes = async () => axios.get(`${APIBASE_REVIVERUSERS}api/company/get/membership/types`);
