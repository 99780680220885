import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { getStatistics } from "../../services/Orders";
import { ErrorDialogContext } from "../../contexts/ErrorDialogContext";
import Spinner from "../../components/Spinner/Spinner";
import { formatNumber } from "../../utils/Formatting";
import { Helmet } from "react-helmet-async";

import "./Statistics.css";

export default function Statistics(props) {
  const { t } = useTranslation();
  const { showErrorDialog, setErrorDialogOptions } = useContext(ErrorDialogContext);

  const [statistics, setStatistics] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let ignore = false;

    async function asyncGetStatistics() {
      setLoading(true);
      try {
        const response = await getStatistics();
        if (!ignore) setStatistics(response.data);
      } catch (error) {
        setErrorDialogOptions({
          message: "Kunne ikke hente statistikk.",
          error,
        });
        showErrorDialog(true);
      }
      setLoading(false);
    }

    asyncGetStatistics();

    return () => (ignore = false);
  }, [setErrorDialogOptions, showErrorDialog]);

  return (
    <div className="admin-statistics">
      <Helmet>
        <title>Admin - Fished - {t("statistics.statistics")}</title>
        <meta name="description" content="Admin - Fished - {t('statistics.statistics')}" />
      </Helmet>
      <h3 className="mbottom20">{t("statistics.statistics")}</h3>
      <Spinner loading={loading} inline={true} align="center" />
      {!!statistics && (
        <>
          <div className="statistics">
            <div className="statistic">
              <div className="label">{t("statistics.noActiveOrders")}</div>
              <div className="value">{formatNumber(statistics.activeOrders)}</div>
            </div>
            <div className="statistic">
              <div className="label">{t("statistics.noTotalOrders")}</div>
              <div className="value">{formatNumber(statistics.orders)}</div>
            </div>
            <div className="statistic">
              <div className="label">{t("statistics.noSales")}</div>
              <div className="value">{formatNumber(statistics.transactions)}</div>
            </div>
            <div className="statistic">
              <div className="label">{t("statistics.sumFee")}</div>
              <div className="value">{formatNumber(statistics.fees)}</div>
            </div>
            <div className="statistic">
              <div className="label">{t("statistics.sumSales")}</div>
              <div className="value">{formatNumber(statistics.totalSumInNok)}</div>
            </div>
          </div>
          <table className="sales-items">
            <thead>
              <tr>
                <th scope="col">{t("statistics.type")}</th>
                <th scope="col">{t("statistics.sumSalesItem")}</th>
                <th scope="col">{t("statistics.amountSalesItem")}</th>
                <th scope="col">{t("statistics.feeSalesItem")}</th>
                <th scope="col">{t("statistics.ordersSalesItem")}</th>
              </tr>
            </thead>
            <tbody>
              {statistics.totalSalesPerSalesItem.map((item, index) => {
                return (
                  <tr id={`statistic-row-${index}`} key={`statistic-${index}`}>
                    <td data-label={"Type"}>{t("common.fish." + item.salesItem.salesItem)}</td>
                    <td data-label={"Sum salg pr. vare (NOK)"}>{formatNumber(item.totalSumInNOK)}</td>
                    <td data-label={"Antall kg solgt pr. vare"}>{formatNumber(item.totalAmountInKg)}</td>
                    <td data-label={"Sum gebyr pr. vare (NOK)"}>{formatNumber(item.totalFee)}</td>
                    <td data-label={"Antall ordre pr. vare"}>{formatNumber(item.totalNumberOfOrders)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}
