import React, { useEffect, useState } from "react";
import { VictoryChart, VictoryTheme, VictoryGroup, VictoryLine, VictoryAxis, VictoryLegend } from "victory";
import { getAverageCurrency } from "../../services/Graphs";
import { useTranslation } from "react-i18next";
import { ReactComponent as FlagEuro } from "../../assets/svg/flag-euro.svg";
import { ReactComponent as FlagUk } from "../../assets/svg/flag-uk.svg";
import { ReactComponent as FlagUsa } from "../../assets/svg/flag-usa.svg";

// import moment from 'moment-timezone'

import "./Charts.css";

export default function CurrencyAveragesChart(props) {
  const { t } = useTranslation();

  const { fromDate, toDate } = props;
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    async function asyncGetAverageCurrency() {
      const resp = await getAverageCurrency(fromDate, toDate);
      setGraphData(resp.data);
    }

    asyncGetAverageCurrency();
  }, [fromDate, toDate]);

  const LegendFlags = (props) => {
    const { x, y, datum } = props;

    let symbol;
    if (datum.name === "USD")
      symbol = (
        <>
          <FlagUsa x={x + 10} y={y - 5} />
          <rect x={x - 8} y={y - 5} width="16" height="11" fill="var(--SFPgreen)" />
        </>
      );
    else if (datum.name === "EUR")
      symbol = (
        <>
          <FlagEuro x={x + 10} y={y - 5} />
          <rect x={x - 8} y={y - 5} width="16" height="12" fill="var(--SFPblue)" />
        </>
      );
    else if (datum.name === "GBR")
      symbol = (
        <>
          <FlagUk x={x + 10} y={y - 5} />
          <rect x={x - 8} y={y - 5} width="16" height="12" fill="var(--SFPred)" />
        </>
      );
    return symbol;
  };

  return (
    <div className="charts-container">
      <h3>{t("charts.exchangeRate").toUpperCase()}</h3>

      <VictoryChart theme={VictoryTheme.grayscale} height={150} padding={{ top: 20, bottom: 70, left: 50, right: 50 }}>
        <VictoryLegend
          x={85}
          y={100}
          centerTitle
          orientation="horizontal"
          gutter={30}
          data={[{ name: "USD" }, { name: "EUR" }, { name: "GBR" }]}
          dataComponent={<LegendFlags />}
          symbolSpacer={30}
        />

        <VictoryAxis
          dependentAxis={true}
          style={{
            grid: { stroke: "grey", strokeWidth: 1 },
            tickLabels: { fontSize: 9, fontFamily: "inherit" },
            axis: { stroke: "transparent" },
          }}
        />
        <VictoryAxis
          style={{
            grid: {
              stroke: "lightgrey",
              strokeDasharray: "8,3",
              strokeWidth: 0.5,
            },
            tickLabels: { fontSize: 9, fontFamily: "inherit" },
          }}
          // tickFormat={graphData.map(x => moment(x.time).format('DD.MM.YYYY'))}
          fixLabelOverlap={true}
        />

        <VictoryGroup
          style={{
            data: { strokeWidth: 2 },
          }}
        >
          <VictoryLine
            style={{
              data: { stroke: "var(--SFPgreen)" },
            }}
            interpolation="linear"
            data={graphData}
            y="usd"
          />
          <VictoryLine
            style={{
              data: { stroke: "var(--SFPred)" },
            }}
            interpolation="linear"
            data={graphData}
            y="gbp"
          />
          <VictoryLine
            style={{
              data: { stroke: "var(--SFPblue)" },
            }}
            interpolation="linear"
            data={graphData}
            y="eur"
          />
        </VictoryGroup>
      </VictoryChart>
    </div>
  );
}

// Dummy data om det skulle være nødvendig
// const chartData = [
//   { time: 'Date A', usd: 300, gbp: 2400, eur: 2400 },
//   { time: 'Date B', usd: 900, gbp: 2222, eur: 500 },
//   { time: 'Date C', usd: 250, gbp: 2300, eur: 300 },
//   { time: 'Date D', usd: 600, gbp: 200, eur: 1100 },
//   { time: 'Date E', usd: 300, gbp: 700, eur: 1300 },
//   { time: 'Date F', usd: 900, gbp: 100, eur: 1500 },
//   { time: 'Date G', usd: 350, gbp: 2150, eur: 2100 },
//   { time: 'Date H', usd: 1000, gbp: 900, eur: 1100 },
//   { time: 'Date I', usd: 100, gbp: 950, eur: 1500 },
//   { time: 'Date J', usd: 600, gbp: 100, eur: 2100 },
//   { time: 'Date K', usd: 250, gbp: 0, eur: 2100 },
// ];
