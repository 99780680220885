import React, { useState, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import TextField from "../../components/TextField/TextField";
import BlueButton from "../../components/BlueButton/BlueButton";
import SVG from "../../components/SVGAsset/SVGAsset";
import { useHistory } from "react-router-dom";
import { login } from "../../services/Login";
import { Helmet } from "react-helmet-async";
import { ErrorDialogContext } from "../../contexts/ErrorDialogContext";
import Spinner from "../../components/Spinner/Spinner";

import "./Login.css";

export default function Login(props) {
  const { setToken, setUserContext, userContext, setLocaleOnLogin } = props;
  const errorDialogContext = useContext(ErrorDialogContext);
  const { showErrorDialog, setErrorDialogOptions } = errorDialogContext;

  const emailInput = useRef(null);
  const passwordInput = useRef(null);

  const history = useHistory();
  const [username, setUserName] = useState((userContext && userContext.email) || "");
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    sessionStorage.removeItem("redirection");
    if (username === "") {
      emailInput.current.focus();
    } else {
      passwordInput.current.focus();
    }
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let user;
    try {
      const loginResponse = await login(username, password);
      user = loginResponse.data;
      const headers = loginResponse.headers;
      const authHeader = headers.authorization;
      setToken(authHeader);
    } catch (error) {
      setErrorDialogOptions({ message: "Innlogging feilet.", error });
      showErrorDialog(true);
    }

    if (user) {
      setLocaleOnLogin(user);
      history.replace("/registerCompany");
      setUserContext({
        user,
      });
    } else setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>Reviver - Login</title>
        <meta name="description" content="Reviver - Login" />
      </Helmet>
      <Spinner loading={loading} />
      <div className="login-container">
        <SVG className="logo" name="fished-logo"></SVG>
        <div className="login-wrapper">
          <h1>Logg inn</h1>
          <form onSubmit={handleSubmit}>
            <TextField
              ref={emailInput}
              id="txt-loginUserName"
              label="Brukernavn"
              defaultValue={userContext.email || ""}
              onChange={(e) => setUserName(e.target.value)}
            ></TextField>
            <TextField
              ref={passwordInput}
              id="txt-loginPassword"
              label="Passord"
              onChange={(e) => setPassword(e.target.value)}
              type="password"
            ></TextField>
            {/*
          <div className="remember-me-forgot-password">
            <CheckBox id="cb-loginRememberMe" label="Husk meg"></CheckBox>
            <a href="https://google.com" target="_blank" rel="noreferrer">Glemt passord?</a>
          </div>
          */}
            <div className="submit">
              <BlueButton id="btn-loginSubmit" type="submit" className="submit" text="Logg inn"></BlueButton>
            </div>
            {/*
          <span>Har du ikke konto? <a href="https://google.com" target="_blank" rel="noreferrer">Registrer deg nå!</a></span>
          */}
          </form>
        </div>
      </div>
    </>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
