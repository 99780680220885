import { FC, PropsWithChildren } from "react";
import { Text as MantineText, TextProps as MantineTextProps } from "@mantine/core";
import styles from "./styles.module.css";
import PropTypes from "prop-types";

export type TextSize = "xl" | "lg" | "md" | "sm" | "xs" | "xxs";
export interface TextProps extends MantineTextProps {
  display?: "body" | "title";
  strong?: boolean;
  span?: boolean;
  size?: TextSize;
}

const Text: FC<PropsWithChildren<TextProps>> = ({ display = "body", strong = false, size, ...props }) => {
  const fontWeightVar = `var(--text-weight-${display === "body" ? "sans" : "mono"}-${strong ? "strong" : "default"})`;
  return (
    <MantineText
      {...props}
      className={styles.root}
      vars={() => ({
        root: {
          ...(size ? { "--text-fz": `var(--text-typography-body-${size})` } : {}),
          "--font-weight": fontWeightVar,
          "--font-family": `var(--text-font-${display === "body" ? "sans" : "mono"})`,
        },
      })}
    >
      {props.children}
    </MantineText>
  );
};

export default Text;

// proptypes still needed as additional runtime check for .jsx containers not yet converted to .tsx
Text.propTypes = {
  display: PropTypes.oneOf(["body", "title"]),
  strong: PropTypes.bool,
  span: PropTypes.bool,
  size: PropTypes.oneOf(["lg", "md", "sm", "xs", "xxs"]),
};
