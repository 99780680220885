import Joi from "joi";

export function registerUserSchema(translation) {
  const t = translation;

  return Joi.object({
    firstname: Joi.string()
      .max(100)
      .required()
      .messages({
        "string.empty": t("validation.firstNameRequired"),
        "any.required": t("validation.firstNameRequired"),
        "string.max": t("validation.firstNameMaxLength"),
      }),
    lastname: Joi.string()
      .max(100)
      .required()
      .messages({
        "string.empty": t("validation.lastNameRequired"),
        "any.required": t("validation.lastNameRequired"),
        "string.max": t("validation.lastNameMaxLength"),
      }),
    email: Joi.string()
      .max(100)
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        "string.empty": t("validation.emailRequired"),
        "string.max": t("validation.emailMaxLength"),
        "string.email": t("validation.emailFormatError"),
        "any.required": t("validation.emailRequired"),
      }),
    phone: Joi.number()
      .min(99999)
      .unsafe()
      .max(999999999999999999999999999999)
      .required()
      .messages({
        "number.base": t("validation.phoneNumberRequired"),
        "any.required": t("validation.phoneNumberRequired"),
        "number.max": t("validation.phoneNumberMaxLength"),
        "number.min": t("validation.phoneNumberMaxLength"),
        "number.unsafe": t("validation.phoneNumberMaxLength"),
      }),
  }).unknown();
}

export function createPasswordSchema(translation) {
  const t = translation;

  return Joi.object({
    password: Joi.string()
      .required()
      .messages({
        "string.empty": t("validation.passwordRequired"),
        "any.required": t("validation.passwordRequired"),
      }),
    rePassword: Joi.string()
      .required()
      .messages({
        "string.empty": t("validation.passwordRequired"),
        "any.required": t("validation.passwordRequired"),
      }),
  }).unknown();
}
