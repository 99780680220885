import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { get } from "lodash";

import Grid from "../../components/Grid/Grid";
import moment from "moment-timezone";
import OrderTypeEnum from "../../constants/enums/OrderTypeEnum";
import UserContext from "../../contexts/UserContext";
import { calculateDeviation } from "../../utils/Calculations";

import "../AllActiveOrders/OrderTabContent.css";

export default function DashboardOrderTabContent(props) {
  const order = props.order;
  const { t } = useTranslation();

  const userContext = useContext(UserContext);
  const { currentCompany } = userContext;

  return (
    <div>
      <Grid className="order-tab-section nogap">
        <Grid.Row>
          <Grid.Col span={12}>
            <p>{t("common.product")}</p>
          </Grid.Col>
          <Grid.Row>
            <Grid.Col span={4}>
              <label id="type-label">{t("common.type")}</label>
            </Grid.Col>
            <Grid.Col span={8}>
              <span id="type-span">{t("common.fish." + order.salesItem.salesItem)}</span>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={4}>
              <label id="amount-label">{t("common.amount")}</label>
            </Grid.Col>
            <Grid.Col span={8}>
              <span id="amount-span">
                {order.amountDeviation === 0
                  ? order.amount + " " + order.unit.unit
                  : calculateDeviation(order.amount, order.amountDeviation)}
              </span>
            </Grid.Col>
          </Grid.Row>
          {order.orderType.id === OrderTypeEnum.SELL && (
            <Grid.Row>
              <Grid.Col span={4}>
                <label id="remainingAmount-label">{t("common.remainingAmount")}</label>
              </Grid.Col>
              <Grid.Col span={8}>
                <span id={"remainingAmount-span"}>
                  {order.remainingAmount > 0 ? order.remainingAmount + " " + order.unit.unit : "-"}
                </span>
              </Grid.Col>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Col span={4}>
              <label id="finalAmount-label">{t("common.finalAmount")}</label>
            </Grid.Col>
            <Grid.Col span={8}>
              <span id={"finalAmount-span"}>
                {order.finalAmount === 0 || order.status === "AWAITING_FINAL_AMOUNT"
                  ? "-"
                  : order.finalAmount + " " + order.unit.unit}
              </span>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={4}>
              <label id="size-label">{t("common.size")}</label>
            </Grid.Col>
            <Grid.Col span={8}>
              <span id="size-span">{order.size.size}</span>
            </Grid.Col>
          </Grid.Row>
          {order.gear && (
            <Grid.Row>
              <Grid.Col span={4}>
                <label id="tool-label">{t("common.tool")}</label>
              </Grid.Col>
              <Grid.Col span={8}>
                <span id="tool-span">{t("common.tools." + get(order, "gear.gear", ""))}</span>
              </Grid.Col>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Col span={4}>
              <label id="condition-label">{t("common.condition")}</label>
            </Grid.Col>
            <Grid.Col span={8}>
              <span id="condition-span">{t("common.conditions." + order.condition.condition)}</span>
            </Grid.Col>
          </Grid.Row>
          {order.treatment && (
            <Grid.Row>
              <Grid.Col span={4}>
                <label id="treatment-label">{t("common.treatment")}</label>
              </Grid.Col>
              <Grid.Col span={8}>
                <span id="treatment-span">{t("common.treatments." + get(order, "treatment.treatment"), "")}</span>
              </Grid.Col>
            </Grid.Row>
          )}
        </Grid.Row>
      </Grid>

      <Grid className="order-tab-section nogap">
        <Grid.Row>
          <Grid.Col span={12}>
            <p>{t("commonOrders.captureAndDelivery")}</p>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={4}>
            <label id="validFrom-label">{t("common.validFrom")}</label>
          </Grid.Col>
          <Grid.Col span={8}>
            <span id="validFrom-span">{moment(order.validFrom).format("DD.MM.YYYY")}</span>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={4}>
            <label id="validTo-label">{t("common.validTo")}</label>
          </Grid.Col>
          <Grid.Col span={8}>
            <span id="validTo-span">{moment(order.validTo).format("DD.MM.YYYY")}</span>
          </Grid.Col>
        </Grid.Row>
        {order.catchDate && (
          <Grid.Row>
            <Grid.Col span={4}>
              <label id="captured-label">{t("common.captured")}</label>
            </Grid.Col>
            <Grid.Col span={8}>
              <span id="captured-span">
                {order.catchDate !== null ? moment(order.catchDate).format("DD.MM.YYYY") : ""}
              </span>
            </Grid.Col>
          </Grid.Row>
        )}
        {order.deliveryStart && (
          <Grid.Row>
            <Grid.Col span={4}>
              <label id="delivery-label">{t("common.delivery")}</label>
            </Grid.Col>
            <Grid.Col span={8}>
              <span id="delivery-span">
                {order.deliveryStart !== null ? moment(order.deliveryStart).format("DD.MM.YYYY -") : ""}
                {order.deliveryEnd !== null ? moment(order.deliveryEnd).format(" DD.MM.YYYY") : ""}
              </span>
            </Grid.Col>
          </Grid.Row>
        )}
        {order.packingDate && (
          <Grid.Row>
            <Grid.Col span={4}>
              <label id="packingDate-label">{t("commonOrders.packingDate")}</label>
            </Grid.Col>
            <Grid.Col span={8}>
              <span id="packingDate-span">
                {order.packingDate !== null ? moment(order.packingDate).format("DD.MM.YYYY") : ""}
              </span>
            </Grid.Col>
          </Grid.Row>
        )}
        {order.expirationDate && (
          <Grid.Row>
            <Grid.Col span={4}>
              <label id="expirationDate-label">{t("commonOrders.expirationDate")}</label>
            </Grid.Col>
            <Grid.Col span={8}>
              <span id="expirationDate-span">
                {order.expirationDate !== null ? moment(order.expirationDate).format("DD.MM.YYYY") : ""}
              </span>
            </Grid.Col>
          </Grid.Row>
        )}
        {order.lastDeliveryDate && (
          <Grid.Row>
            <Grid.Col span={4}>
              <label id="lastDeliveryDate-label">{t("commonOrders.lastDeliveryDate")}</label>
            </Grid.Col>
            <Grid.Col span={8}>
              <span id="lastDeliveryDate-span">{moment(order.lastDeliveryDate).format("DD.MM.YYYY")}</span>
            </Grid.Col>
          </Grid.Row>
        )}
        {order.transportFrom && (
          <Grid.Row>
            <Grid.Col span={4}>
              <label id="fishCurrentLocation-label">{t("commonOrders.fishCurrentLocation")}</label>
            </Grid.Col>
            <Grid.Col span={8}>
              <span id="fishCurrentLocation-span">{order.transportFrom}</span>
            </Grid.Col>
          </Grid.Row>
        )}
        {order.description && (
          <Grid.Row>
            <Grid.Col span={4}>
              <label id="description-label">{t("common.description")}</label>
            </Grid.Col>
            <Grid.Col span={8}>
              <span id="description-span">{order.description}</span>
            </Grid.Col>
          </Grid.Row>
        )}
      </Grid>

      {(order.status === "ACTIVE" || order.status === "EXPIRED") && (
        <Grid className="order-tab-section nogap">
          <Grid.Row>
            <Grid.Col span={12}>
              <p>{t("common.price")}</p>
            </Grid.Col>
          </Grid.Row>

          {order.orderType.id === OrderTypeEnum.BUY && (
            <Grid.Row>
              <Grid.Col span={4}>
                <label id="totalPrice-label">{t("commonOrders.priceInclFee")}</label>
              </Grid.Col>
              <Grid.Col span={8}>
                <span id="totalPrice-span">
                  {order.price[currentCompany.companyCurrency].toFixed(2)} {currentCompany.companyCurrency}
                </span>
              </Grid.Col>
            </Grid.Row>
          )}
          {order.orderType.id === OrderTypeEnum.SELL && (
            <Grid.Row>
              <Grid.Col span={4}>
                <label id="totalPriceInclFee-label">{t("commonOrders.priceInclFee")}</label>
              </Grid.Col>
              <Grid.Col span={8}>
                <span id="totalPriceInclFee-span">
                  {order.price[currentCompany.companyCurrency].toFixed(2)} {currentCompany.companyCurrency}
                </span>
              </Grid.Col>
            </Grid.Row>
          )}
        </Grid>
      )}
    </div>
  );
}
