import React from "react";
import PropTypes from "prop-types";

import RingLoader from "react-spinners/RingLoader";

import "./Spinner.css";

const override = `
  display: block;
`;

function Spinner(props) {
  const { loading, inline } = props;
  const _inline = inline ? "inline" : "";

  if (!loading) return null;

  return (
    <div className={`spinner ${_inline}`}>
      <RingLoader loading={loading} color={"#5060DB"} size={inline ? 35 : 150} css={override} />
    </div>
  );
}

export default Spinner;

Spinner.propTypes = {
  loading: PropTypes.bool.isRequired,
};
