import PropTypes from "prop-types";

function Grid(props) {
  const possiblePad1 = props.pad1 ? `pad1` : "";
  const possiblePad2 = props.pad2 ? `pad2` : "";
  const possibleVPad1 = props.vpad1 ? `vpad1` : "";
  const possibleVPad2 = props.vpad2 ? `vpad2` : "";
  return (
    <div
      {...props}
      className={`grid ${possiblePad1} ${possiblePad2} ${possibleVPad1} ${possibleVPad2} ${props.className || ""}`}
    >
      {props.children}
    </div>
  );
}

const childrenCommonPropTypes = PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(Array), PropTypes.string]);

Grid.propTypes = {
  children: childrenCommonPropTypes,
  pad1: PropTypes.string,
  pad2: PropTypes.string,
  vpad1: PropTypes.string,
  vpad2: PropTypes.string,
};

Grid.Row = function (props) {
  return (
    <div {...props} className={`row ${props.className || ""}`}>
      {props.children}
    </div>
  );
};

Grid.Row.propTypes = {
  children: childrenCommonPropTypes,
  className: PropTypes.string,
};

Grid.Col = function (props) {
  const optionalPush = props.push ? `at${props.push}` : "";
  return (
    <div {...props} className={`col${props.span} ${optionalPush} ${props.className || ""}`}>
      {props.children}
    </div>
  );
};

function commonBetween(props, propName, componentName, from, to) {
  if (props[propName]) {
    const value = props[propName];
    if (typeof value === "number") {
      return value >= from && value <= to
        ? null
        : new Error(`${propName} in ${componentName} is not between ${from} to ${to}`);
    }
  }
}

function oneBetweenTwelve(props, propName, componentName) {
  commonBetween(props, propName, componentName, 1, 12);
}

function twoBetweenEleven(props, propName, componentName) {
  commonBetween(props, propName, componentName, 2, 11);
}

Grid.Col.propTypes = {
  children: PropTypes.any,
  span: oneBetweenTwelve,
  push: twoBetweenEleven,
  className: PropTypes.string,
};

export default Grid;
