import React, { useEffect, useState } from "react";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { registerCompanyStep1Schema, registerCompanyStep1SchemaAddress } from "../RegisterCompanyValidationSchema";
import { validateFieldsHelper } from "../../../utils/ValidationHelper";
import HeadingWithInfoText from "../../../components/HeadingWithInfoText/HeadingWithInfoText";
import Grid from "../../../components/Grid/Grid";
import BlueButton from "../../../components/BlueButton/BlueButton";
import TextField from "../../../components/TextField/TextField";
import Dropdown from "../../../components/Dropdown/Dropdown";
import Spinner from "../../../components/Spinner/Spinner";
import PhoneField from "../../../components/PhoneInput/PhoneInput";

export default function Step1(props) {
  const { t } = useTranslation();

  const { draft, onChange, clickNextHandler, countryOptions, addressTypesOptions, countries, addressShape } = props;

  const [selectedAddress, setSelectedAddress] = useState(get(draft, "partialCompany.address[0].address", null));
  const [selectedPlace, setSelectedPlace] = useState(get(draft, "partialCompany.address[0].place", null));
  const [selectedPostCode, setSelectedPostCode] = useState(get(draft, "partialCompany.address[0].postCode", null));

  const [selectedCountryCode, setSelectedCountryCode] = useState(null);
  const [selectedAddressCountry, setSelectedAddressCountry] = useState({});
  const [selectedAddressType, setSelectedAddressType] = useState({});

  const [isSavingDraft, setSavingDraft] = useState(false);
  const [invoiceDaysOptions] = useState([
    { value: 1, label: "1" },
    { value: 10, label: "10" },
    { value: 30, label: "30" },
  ]);
  const [selectedInvoicDays, setSelectedInvoiceDays] = useState(null);
  const [settlementDaysOptions] = useState([
    { value: 15, label: "15" },
    { value: 30, label: "30" },
  ]);
  const [selectedSettlementDays, setSelectedSettlementDays] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);
  const [validationErrorsAddress, setValidationErrorsAddress] = useState([]);

  useEffect(() => {
    const selectedCountryCode = countryOptions.find(
      (option) => option.iso3Code === draft.partialCompany.countryCodeIso3
    );
    if (selectedCountryCode) {
      setSelectedCountryCode(selectedCountryCode);
    } else if (countryOptions && countryOptions.length > 0) {
      let default_country = countryOptions.find((option) => option.iso3Code === "nor");
      setSelectedCountryCode(default_country);
      onChange({
        countryCodeIso3: default_country.iso3Code,
      });
    }

    const selectedAddressCountry = countryOptions.find(
      (option) => option.value === get(draft, "partialCompany.address[0].countryId")
    );
    setSelectedAddressCountry(selectedAddressCountry);

    const _selectedAddressType = addressTypesOptions.find(
      (option) => option.value === get(draft, "partialCompany.address[0].addressType")
    );
    setSelectedAddressType(_selectedAddressType);

    const _selectedInvoiceDays = invoiceDaysOptions.find(
      (option) => option.value === get(draft, "partialCompany.invoiceDaysBeforeDueDate")
    );
    if (!_selectedInvoiceDays) {
      const e = invoiceDaysOptions[1];
      setSelectedInvoiceDays(e);
      onChange({ invoiceDaysBeforeDueDate: e.value });
    } else {
      setSelectedInvoiceDays(_selectedInvoiceDays);
    }

    const _selectedSettlementDays = settlementDaysOptions.find(
      (option) => option.value === get(draft, "partialCompany.settlementDays")
    );
    if (!_selectedSettlementDays) {
      const e = settlementDaysOptions[0];
      setSelectedSettlementDays(e);
      onChange({ settlementDays: e.value });
    } else {
      setSelectedSettlementDays(_selectedSettlementDays);
    }
  }, [
    countryOptions,
    draft,
    invoiceDaysOptions,
    settlementDaysOptions,
    onChange,
    selectedSettlementDays,
    addressTypesOptions,
  ]);

  function onChangeCountry(e) {
    setSelectedCountryCode(e);
    const country = countries.find((country) => country.id === e.value);
    onChange({
      countryCodeIso3: country.iso3,
    });
  }
  function onChangeAddressType(e) {
    setSelectedAddressType(e);
    const addressType = addressTypesOptions.find((addressType) => addressType.value === e.value);
    const existing = get(draft, "partialCompany.address[0]", {});
    onChange({
      address: [
        {
          ...addressShape,
          ...existing,
          addressType: addressType.value,
        },
      ],
    });
  }
  function onChangeAddress(e) {
    setSelectedAddress(e.target.value);
    const existing = get(draft, "partialCompany.address[0]", {});
    onChange({
      address: [
        {
          ...addressShape,
          ...existing,
          address: e.target.value,
        },
      ],
    });
  }
  function onChangePostCode(e) {
    setSelectedPostCode(e.target.value);
    const existing = get(draft, "partialCompany.address[0]", {});
    onChange({
      address: [
        {
          ...addressShape,
          ...existing,
          postCode: e.target.value,
        },
      ],
    });
  }
  function onChangePlace(e) {
    setSelectedPlace(e.target.value);
    const existing = get(draft, "partialCompany.address[0]", {});
    onChange({
      address: [
        {
          ...addressShape,
          ...existing,
          place: e.target.value,
        },
      ],
    });
  }
  function onChangeAddressCountry(e) {
    setSelectedAddressCountry(e);
    const existing = get(draft, "partialCompany.address[0]", {});
    const country = countries.find((country) => country.id === e.value);
    onChange({
      address: [
        {
          ...addressShape,
          ...existing,
          countryId: country.id,
        },
      ],
    });
  }
  function onChangeInvoiceDays(e) {
    setSelectedInvoiceDays(e);
    onChange({ invoiceDaysBeforeDueDate: e.value });
  }

  function onChangeSettlementDays(e) {
    setSelectedSettlementDays(e);
    onChange({ settlementDays: e.value });
  }

  async function nextHandler() {
    const inputChecker = validateFieldsHelper(registerCompanyStep1Schema(t), draft.partialCompany);
    const inputCheckerAddress = validateFieldsHelper(registerCompanyStep1SchemaAddress(t), {
      address: selectedAddress,
      addressType: selectedAddressType,
      countryId: selectedAddressCountry,
      place: selectedPlace,
      postCode: selectedPostCode,
    });

    setValidationErrors(inputChecker);
    setValidationErrorsAddress(inputCheckerAddress);

    if (Object.keys(inputChecker).length === 0 && Object.keys(inputCheckerAddress).length === 0) {
      setSavingDraft(true);
      await clickNextHandler();
    } else {
      console.log("Noe feilet");
      console.log(draft.partialCompany);
    }
  }

  return (
    <>
      <Grid className="wizard-container-border-top nogap">
        <Grid.Row>
          <Grid.Col span={4} className="wizard-container-info-area">
            <HeadingWithInfoText header={t("step1.infoHeader")} showInfo={true} infoText={t("step1.infoText")} />
          </Grid.Col>
          <Grid.Col span={8} className="content-container">
            <div className="content-row content-container-dropdown">
              <Dropdown
                id="dd-company-country"
                label={t("step1.country")}
                options={countryOptions}
                onChange={onChangeCountry}
                value={selectedCountryCode}
                errormessage={validationErrors.countryCodeIso3}
              />
            </div>
          </Grid.Col>
        </Grid.Row>
      </Grid>
      <Grid className="wizard-container-border-top nogap">
        <Grid.Row>
          <Grid.Col span={4} className="wizard-container-info-area">
            <HeadingWithInfoText header={t("step1.company")} showInfo={true} infoText={t("step1.companyInfo")} />
          </Grid.Col>
          <Grid.Col span={8} className="content-container">
            <div className="content-row">
              <TextField
                id="txt-name"
                label="Navn"
                onChange={(e) =>
                  onChange({
                    name: e.target.value,
                  })
                }
                value={draft.partialCompany.name || ""}
                errormessage={validationErrors.name}
              />
            </div>
            <div className="content-row">
              <TextField
                id="txt-orgnr"
                label={t("step1.companyvatNr")}
                onChange={(e) =>
                  onChange({
                    vatNumber: e.target.value,
                  })
                }
                value={draft.partialCompany.vatNumber || ""}
                errormessage={validationErrors.vatNumber}
              />
              <TextField
                id="txt-leinr"
                label={t("step1.companyLeiNr")}
                onChange={(e) =>
                  onChange({
                    leiNumber: e.target.value,
                  })
                }
                value={draft.partialCompany.leiNumber || ""}
                errormessage={validationErrors.leiNumber}
              />
            </div>
          </Grid.Col>
        </Grid.Row>
      </Grid>
      <Grid className="wizard-container-border-top nogap">
        <Grid.Row>
          <Grid.Col span={4} className="wizard-container-info-area">
            <HeadingWithInfoText header={t("step1.contact")} />
          </Grid.Col>
          <Grid.Col span={8} className="content-container-email-phone">
            <div className="content-row">
              <TextField
                id="txt-email"
                label={t("step1.email")}
                onChange={(e) =>
                  onChange({
                    email: e.target.value,
                  })
                }
                value={draft.partialCompany.email || ""}
                errormessage={validationErrors.email}
              />
            </div>
            <div className="mtop20">
              <PhoneField
                id="txt-phone"
                placeholder={t("step1.telephone")}
                value={draft.partialCompany.phone || ""}
                onChange={(e) => onChange({ phone: e })}
                errormessage={validationErrors.phone}
              />
            </div>
          </Grid.Col>
        </Grid.Row>
      </Grid>
      <Grid className="wizard-container-border-top nogap">
        <Grid.Row>
          <Grid.Col span={4} className="wizard-container-info-area">
            <HeadingWithInfoText header={t("step1.addressHeader")} showInfo={true} infoText={t("step1.addressText")} />
          </Grid.Col>
          <Grid.Col span={8} className="content-container">
            <div className="content-row content-container-dropdown">
              <Dropdown
                id="dd-addresstype"
                label={t("step1.addressType")}
                options={addressTypesOptions}
                onChange={(e) => onChangeAddressType(e)}
                value={selectedAddressType}
                errormessage={validationErrorsAddress.addressType}
              />
            </div>
            <div className="content-row">
              <TextField
                id="txt-address"
                label={t("step1.addressHeader")}
                onChange={(e) => onChangeAddress(e)}
                value={selectedAddress}
                errormessage={validationErrorsAddress.address}
              />
            </div>
            <div className="content-row postCode-place">
              <TextField
                id="txt-postCode"
                label={t("step1.addressLabel1")}
                onChange={(e) => onChangePostCode(e)}
                value={selectedPostCode}
                errormessage={validationErrorsAddress.postCode}
              />
              <TextField
                id="txt-place"
                label={t("step1.addressLabel2")}
                onChange={(e) => onChangePlace(e)}
                value={selectedPlace}
                errormessage={validationErrorsAddress.place}
              />
            </div>
            <div className="content-row content-container-dropdown">
              <Dropdown
                id="dd-addresscountry"
                label={t("step1.addressLabel3")}
                options={countryOptions}
                onChange={(e) => onChangeAddressCountry(e)}
                value={selectedAddressCountry}
                errormessage={validationErrorsAddress.countryId}
              />
            </div>
          </Grid.Col>
        </Grid.Row>
      </Grid>
      <Grid className="wizard-container-border-top nogap">
        <Grid.Row>
          <Grid.Col span={4} className="wizard-container-info-area">
            <HeadingWithInfoText header={t("step1.paymentPref")} showInfo={false} />
          </Grid.Col>
          <Grid.Col span={8} className="content-container">
            <TextField
              id="credit-limit"
              label={t("step1.creditLimit")}
              value={get(draft, "partialCompany.creditLimit", "") || ""}
              onChange={(e) => onChange({ creditLimit: e.target.value })}
              errormessage={validationErrors.creditLimit}
            />
            <div className="mtop20">
              <Dropdown
                id="invoice-dute-date"
                label={t("step1.invoiceDays")}
                options={invoiceDaysOptions}
                onChange={(e) => onChangeInvoiceDays(e)}
                value={selectedInvoicDays}
                errormessage={validationErrors.invoiceDaysBeforeDueDate}
              />
            </div>
            <div className="mtop20">
              <Dropdown
                id="days-settlement"
                label={t("step1.settlementDays")}
                options={settlementDaysOptions}
                onChange={(e) => onChangeSettlementDays(e)}
                value={selectedSettlementDays}
                errormessage={validationErrors.settlementDays}
              />
            </div>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col push={5} span={8} className="action-buttons">
            <Spinner loading={isSavingDraft} inline={true} />
            <BlueButton id="wizard-step1-next" text={t("registerCompany.next")} onClick={nextHandler} />
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </>
  );
}
