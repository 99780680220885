import { FC } from "react";
import styles from "./styles.module.css";

interface Props {}

const ActiveOrders: FC<Props> = (props) => {
  return <div className={styles.root} {...props} />;
};

export default ActiveOrders;
